<template>
  <form class="flex flex-col text-gray-700 dark:text-white font-thin" @submit.prevent="saveProductLine">
    <base-input
      :label="$t('product-lines.slug')"
      v-model="slug"
      required="true"
      class="mb-2"
    />
    <div for="translation-input" class="text-sm md:text-base">
      {{ $t("product-lines.line") }}
    </div>
    <translation-input
      name="translation-input"
      :label="$t('product-lines.line')"
      v-model="translations.line.en"
      lang="en"
      @blur="updateLine"
      required="true"
      label-style="bg-violet-300"
    />
    <translation-input
      :label="$t('product-lines.line')"
      v-model="translations.line.it"
      lang="it"
      required="true"
      label-style="bg-violet-300"
    />
    <base-button extra-class="border-violet-400 text-violet-400 hover:bg-violet-300 self-end mt-3">
      {{ $t('product-lines.save') }}
    </base-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

import BaseInput from '../base/BaseInput.vue';
import TranslationInput from '../base/TranslationInput.vue';
import BaseButton from '../base/BaseButton.vue';

export default {
  components: {
    BaseInput,
    TranslationInput,
    BaseButton,
  },

  data() {
    return {
      slug: '',
      line: '',
      translations: {
        line: {
          en: '',
          it: '',
        },
      },
    };
  },

  methods: {
    ...mapActions('productLines', ['postProductLine']),
    async saveProductLine() {
      await this.postProductLine({ ...this.$data });
      this.$emit('productLineHasBeenAdded');
    },
    updateLine() {
      this.line = this.translations.line.en;
    },
  },
};
</script>
