<template>
  <div class="text-sm isolate inline-flex rounded-md place-self-end" ref="ButtonGroup" id="ButtonGroup">
    <button v-for="(button, index) in buttons" :key="index" type="button"
      class="relative inline-flex items-center px-3 py-2 text-sm font-semibold"
      :class="[borderClasses(index), extraClasses, isActiveClass(index)]" :style="isButtonActive(index)"
      @click="$emit('action', index)"
    >
      {{ button }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    buttonIndex: {
      type: Number,
      default: 0,
    },
    baseClasses: {
      type: String,
      default: 'bg-violet-200  border-violet-500 fill-white',
    },
    extraClasses: {
      type: String,
      default: 'dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600 focus:bg-transparent focus:fill-violet-400 focus:text-violet-500 hover:bg-transparent hover:fill-violet-400 hover:text-violet-500',
    },
    activeClasses: {
      type: String,
      default: 'bg-transparent fill-violet-400 text-violet-500',
    },
    buttonActiveStyle: {
      type: String,
      default: 'background-color: rgb(236 253 245);',
    },
  },
  methods: {
    isActiveClass(index) {
      return this.buttonIndex === index ? this.activeClasses : 'text-currentColor'; // eslint-disable-line
    },
    borderClasses(index) {
      return index === 0 ? `${this.baseClasses} rounded-l-md border` : index === this.buttons.length - 1 ? `${this.baseClasses} rounded-r-md border` : `${this.baseClasses} border-t border-b`; // eslint-disable-line
    },
    isButtonActive(index) {
      return this.buttonIndex === index ? this.buttonActiveStyle : '';
    },
  },
};
</script>
