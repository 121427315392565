<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="relative flex items-center place-self-start">
    <select v-model="$i18n.locale" id="language" @change="changeLanguage()"
    class="border border-gray-600 text-gray-600 z-0 rounded px-1 w-11 appearance-none cursor-pointer uppercase bg-transparent text-sm
    dark:text-gray-100 dark:border-gray-100">
      <option v-for="locale in locales" :key="locale" :value="locale">
        {{ locale }}
      </option>
    </select>
    <div class="absolute right-0 pointer-events-none text-gray-600 dark:text-gray-100">
      <dropdown-arrow class="w-3 md:w-5" />
    </div>
  </div>
</template>

<script>
import Locales from '@/config/locales';
import DropdownArrow from '../graphics/DropdownArrow.vue';

export default {
  components: { DropdownArrow },
  data() {
    return {
      locales: Locales,
    };
  },
  mounted() {
    this.preferredLanguage();
  },
  methods: {
    preferredLanguage() {
      if (localStorage.getItem('locale')) {
        this.$i18n.locale = localStorage.getItem('locale');
        return;
      }
      localStorage.setItem('locale', this.$i18n.locale);
    },
    changeLanguage() {
      localStorage.setItem('locale', this.$i18n.locale);
      window.location.reload();
    },
  },
};
</script>
