<template>
    <div class="w-10">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 215.5 69.76"><defs><linearGradient id="Blue_Gradient_01" y1="34.88" x2="215.5" y2="34.88" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#231f5c"/><stop offset="1" stop-color="#034ea2"/></linearGradient></defs><g id="Layer_2" data-name="Layer 2"><g id="Visa_Logo" data-name="Visa Logo"><path class="cls-9" d="M81.44,1.51l-28.08,67H35L21.22,15c-.84-3.29-1.57-4.5-4.12-5.88C12.93,6.89,6.05,4.77,0,3.45L.41,1.51H29.9a8.07,8.07,0,0,1,8,6.83l7.3,38.77,18-45.6ZM154,46.67c.08-17.8-24.62-18.78-24.45-26.74.06-2.42,2.36-5,7.41-5.65a32.85,32.85,0,0,1,17.2,3L157.24,3a47,47,0,0,0-16.33-3c-17.25,0-29.39,9.17-29.49,22.3-.12,9.72,8.66,15.13,15.28,18.37,6.8,3.3,9.08,5.42,9,8.38-.05,4.53-5.42,6.52-10.44,6.6-8.78.14-13.87-2.37-17.93-4.26l-3.16,14.78c4.07,1.88,11.6,3.51,19.41,3.59C142,69.76,154,60.7,154,46.67m45.45,21.84h16l-14-67H186.71a7.89,7.89,0,0,0-7.38,4.91l-26,62.09h18.21l3.61-10h22.22Zm-19.36-24,9.18-25.33,5.29,25.33Zm-73.55-43-14.33,67H74.89l14.33-67Z"/></g></g></svg>
    </div>
</template>

<style scoped>
.cls-9 {
  fill: #231f5c;
}

</style>
