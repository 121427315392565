<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <label for="inputRef" :class="labelStyle">
    <span>{{ label }} <span v-if="this.$attrs.required" class="text-red-400">*</span></span>
    <div class="relative flex items-center">
      <input
        autocomplete="off"
        id="inputRef"
        type="text"
        ref="inputRef"
        name="inputRef"
        v-bind="{
          ...$attrs,
          onBlur: ($event) => {
            $emit('blur');
          },
          onChange: ($event) => {
            $emit('change');
          },
        }"
        class="rounded-md p-2 w-full bg-transparent"
        :class="[inputStyles, darkText]"
      />
      <div class="absolute right-0 cursor-pointer pr-3">
        <save-icon v-if="icon && isEdit" @click="isEdit = !isEdit; $emit('save-event')" class="w-4" />
        <edit-icon v-if="icon && !isEdit"  @click="isEdit = !isEdit; $emit('edit-event')" class="w-4" />
      </div>
    </div>
  </label>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input';
import { ref, watch } from 'vue';
import EditIcon from '../graphics/EditIcon.vue';
import SaveIcon from '../graphics/SaveIcon.vue';

export default {
  components: {
    SaveIcon,
    EditIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    labelStyle: {
      type: String,
      default: 'grid text-sm md:text-base',
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    options: {
      type: Object,
      default: () => (
        {
          currency: 'EUR',
          autoDecimalDigits: true,
          valueScaling: 'precision',
        }
      ),
    },
    disabledClasses: {
      type: String,
      default: 'cursor-not-allowed bg-slate-200 dark:bg-slate-500 border border-transparent',
    },
    backgroundClass: {
      type: String,
      default: 'bg-transparent border border-gray-400 dark:border-gray-100',
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { inputRef, setValue } = useCurrencyInput(props.options);
    const isEdit = ref(false);

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      },
    );

    return { inputRef, isEdit };
  },
  computed: {
    inputStyles() {
      return `${this.$attrs.disabled ? this.disabledClasses : this.backgroundClass}`;
    },
    darkText() {
      if (localStorage.getItem('theme') === 'dark') {
        return 'testo-safari-dark';
      }
      return 'testo-safari';
    },
  },
};
</script>

<style>
.testo-safari {
  -webkit-text-fill-color: black;
  opacity: 1;
}

.testo-safari-dark {
  -webkit-text-fill-color: white;
  opacity: 1
}
</style>
