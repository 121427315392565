import axios from 'axios';

export default {

  namespaced: true,

  state: {
    goods: [],
    good: {},
  },

  getters: {
    goods: (state) => state.goods,
    notRentedGoods: (state) => state.goods.filter((good) => !good.is_rented_now && good.state !== 'sold'),
    good: (state) => state.good,
    goodById: (state) => (id) => state.goods.find((good) => good.id === id),
  },

  mutations: {
    setGoods(state, goods) {
      state.goods = goods;
    },
    setGood(state, good) {
      state.good = good;
    },
  },

  actions: {
    async getGoods({ commit }) {
      let { data } = await axios.get('/api/goods');

      data = data.sort((a, b) => {
        if (a.serial_no < b.serial_no) return -1;
        if (a.serial_no > b.serial_no) return 1;
        return 0;
      });
      commit('setGoods', data);
    },
    async postGood({ dispatch }, good) {
      await axios.post('/api/goods', good);
      await dispatch('getGoods');
    },
    async putGood({ dispatch }, good) {
      const { id, ...payload } = good;
      await axios.put(`/api/goods/${id}`, payload);
      await dispatch('getGoods');
    },
    // eslint-disable-next-line no-unused-vars
    async getDecodedGood({ dispatch }, harp) {
      const { data } = await axios.post('/api/goods/transcoder', harp);
      return data;
    },
    setGood({ commit }, good) {
      commit('setGood', good);
    },
  },
};
