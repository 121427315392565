<template>
  <label for="select-state" class="text-sm md:text-base relative inline-block">
    <span>{{ $t("customers.state") }} <span v-if="this.$attrs.required" class="text-red-400">*</span></span>
    <div class="flex flex-col items-center rounded-md absolute z-50 w-full">
      <input
        autocomplete="off"
        class="rounded-md p-2 w-full border border-gray-400 dark:border-gray-100 appearance-none bg-transparent"
        v-model="filterString"
        @focus="filterString = ''"
        @click.stop.prevent="showDropDown = !showDropDown"
        @keydown="manageKeys"
        @keydown.enter.stop.prevent="select(selectedIndex)"
        @keydown.down.stop.prevent="selectNext()"
        @keydown.up.stop.prevent="selectPrev()"
        @keyup.delete.stop.prevent="handleBackspace()"
      />
      <ul
        v-if="showDropDown"
        class="rounded-md p-1 mt-1 w-full shadow-select border border-gray-300
        dark:border-slate-800 appearance-none bg-gray-200 dark:bg-gray-700 max-h-60 overflow-y-scroll"
        id="select-state"
        :value="modelValue"
        :class="inputStyles"
      >
        <li v-if="filteredStates.length === 0"
          :value="null"
          disabled
          class="italic py-1 px-3"
        >
          {{ $t("customers.nothingFound") }}
        </li>
        <li
          v-for="(state, index) in filteredStates"
          :key="state.code"
          :value="state.code.toUpperCase()"
          class="dark:text-white py-1 px-2 rounded font-normal text-sm"
          :class="{'bg-blue-500 text-white': index == selectedIndex}"
          @click.stop.prevent="select(index)"
          @keydown.enter.stop.prevent="select(index)"
        >
          {{ state.name }} - {{ state.code }}
      </li>
    </ul>
      <div class="absolute right-0 pointer-events-none pr-3 my-3">
        <dropdown-arrow class="w-3 md:w-5" />
      </div>
    </div>
  </label>
</template>

<script>
import statesList from '../../config/states-list';
import DropdownArrow from '../graphics/DropdownArrow.vue';

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabledClasses: {
      type: String,
      default: 'cursor-not-allowed bg-slate-200 dark:bg-slate-500 border-none',
    },
    country: {
      type: String,
      default: 'it',
    },
    state: {
      type: [String, null],
      default: null,
    },
  },

  components: {
    DropdownArrow,
  },

  created() {
    if (this.state) {
      const index = this.states.findIndex((state) => state.code === this.state.toUpperCase());
      if (index === -1) return;
      this.select(index);
    }
  },
  watch: {
    country: {
      handler(newValue) {
        if (newValue) {
          this.states = statesList[this.country];
        }
      },
    },
  },

  data() {
    return {
      states: statesList[this.country],
      filterString: '',
      showDropDown: false,
      selectedIndex: 0,
    };
  },
  computed: {
    inputStyles() {
      return `${this.$attrs.disabled ? this.disabledClasses : ''}`;
    },
    filteredStates() {
      return this.states && this.states.length > 0
        ? this.states.filter((state) => state.name.toLowerCase().includes(this.filterString.toLowerCase()))
        : this.states;
    },
  },
  methods: {
    handleBackspace() {
      this.showDropDown = true;
    },
    select(index) {
      const selected = this.filteredStates[index];
      this.filterString = `${selected.name} - ${selected.code}`;
      this.$emit('update:modelValue', selected.code);
      this.showDropDown = false;
    },
    selectNext() {
      this.selectedIndex = !this.showDropDown // eslint-disable-line
        ? true
        : (this.selectedIndex < this.filteredStates.length - 1)
          ? this.selectedIndex += 1
          : this.selectedIndex = 0;
    },
    selectPrev() {
      this.selectedIndex = (this.selectedIndex > 0)
        ? this.selectedIndex -= 1
        : this.selectedIndex = this.filteredStates.length - 1;
    },
    manageKeys(ev) {
      if (ev.key === 'Tab') {
        this.showDropDown = false;
        return;
      }
      this.showDropDown = true;
    },
  },
};
</script>

<style>
 .shadow-select {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1) ;
 }
</style>
