<template>
  <color-banner bgStyle="bg-goods"></color-banner>
  <custom-modal
    ref="importHarpColors"
    extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    container-class="w-1/2"
  >
    <template v-slot:header>
      {{ $t("harp-colors.importTitle") }}
    </template>
    <template v-slot:body>
      <excel-importer
        buttonStyle="button-goods"
        importer="HarpColors"
        @harp-colors-has-been-imported="onHarpColorsHasBeenImported"
      />
    </template>
  </custom-modal>
  <base-container>
    <template v-slot:buttons>
      <base-button @click="$refs.importHarpColors.openModal()" :title="$t('buttons.importExcel')"
        extra-class="fill-white bg-violet-200 hover:bg-transparent hover:fill-violet-400 border-violet-500
        dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600">
        <import-excel />
      </base-button>
    </template>
    <template v-slot:title>
        {{ $t("harp-colors.harp-colors") }}
    </template>
    <template v-slot:body>
      <harp-color-row v-for="color in harpColors" :key="color.id" :color="color" class="mb-4"/>
    </template>
  </base-container>
</template>

<script>
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';

import ImportExcel from '@/components/graphics/ImportExcel.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import CustomModal from '../components/base/CustomModal.vue';
import ExcelImporter from '../components/base/ExcelImporter.vue';
import HarpColorRow from '../components/harpcolors/HarpColorRow.vue';
import BaseContainer from '../components/base/BaseContainer.vue';
import ColorBanner from '../components/graphics/ColorBanner.vue';

export default {

  components: {
    CustomModal,
    HarpColorRow,
    ExcelImporter,
    BaseContainer,
    ColorBanner,
    ImportExcel,
    BaseButton,
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  async created() {
    await this.getHarpColors();
  },

  computed: {
    ...mapGetters('harpColors', ['harpColors']),
  },

  methods: {
    ...mapActions('harpColors', ['getHarpColors']),
    async onHarpColorsHasBeenImported() {
      await this.getHarpColors();
      this.toast.success(this.$t('harp-colors.imported'));
      this.$refs.importHarpColors.closeModal();
    },
  },
};
</script>
