<template>
  <div class="w-full" ref="topList">
    <color-banner bgStyle="bg-emerald-300"></color-banner>
    <custom-modal v-if="showModalUpload" @close="!showModalUpload" />
    <custom-modal
      ref="contractDetails"
      component-to-open="ContractDetails"
      extra-class="bg-emerald-400 text-white/90 font-medium fill-white/90"
    >
      <template v-slot:header>
        {{ $t("contracts.contract") }}
      </template>
      <template v-slot:body>
        <contract-details @close="closeContractDetails()" :contract="contract"/>
      </template>
    </custom-modal>

    <base-container :buttons-slot="false">
      <template v-slot:title>
        {{ $t("contracts.contracts") }}
      </template>
      <template v-slot:body>
        <ais-instant-search
          :search-client="searchClient"
          index-name="contracts"
          :future="{ preserveSharedStateOnUnmount: true}"
          :class-names="{
            'ais-InstantSearch': 'flex flex-col space-y-5 mt-5',
          }"
        >
          <ais-configure
            :hits-per-page.camel="hitsPerPage"
            :filters="statusFilter()"
          />
          <div class="flex space-x-4 w-full justify-between">
            <ais-search-box
              :class-names="{
                'ais-SearchBox': 'w-1/2',
              }"
            >
              <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                <label for="srch">
                  <div class="relative flex items-center text-black dark:text-white">
                  <input
                    name="srch"
                    type="search"
                    :value="currentRefinement"
                    @input="refine($event.currentTarget.value)"
                    class="rounded-md p-2 w-full bg-transparent border border-gray-400 dark:border-gray-100 col-span-1"
                  />
                  <div class="absolute right-0 cursor-pointer pr-3">
                    <search-icon v-if="!currentRefinement" @click="refine($event.currentTarget.value)" class="w-4" />
                  </div>
                </div>
                </label>
                <span :hidden="!isSearchStalled">Loading...</span>
              </template>
            </ais-search-box>
            <ais-menu-select
              attribute="workflow_status"
              :transform-items="transformItems"
              :class-names="{
                'ais-MenuSelect': 'w-1/2',
              }"
            >
            <template v-slot="{ items, canRefine }">
              <div class="relative flex items-center w-full text-black dark:text-white">
                <label for="workflow-status" class="w-full">
                  <select
                    name="workflow-status"
                    class="border z-0 rounded-md p-2 md:px-4 w-full appearance-none cursor-pointer capitalize bg-transparent border-gray-400 dark:border-gray-100"
                    :disabled="!canRefine"
                    v-model="selectedWorkflowStatus"
                    @change="applyWorkflowStatus($event.currentTarget.value)"
                  >
                    <option value="">{{$t('contracts.workflowStates.all')}}</option>
                    <option
                      v-for="item in items"
                      :key="item.value"
                      :value="item.value"
                      :selected="item.isRefined"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </label>
                <div class="absolute right-0 pointer-events-none pr-3">
                  <dropdown-arrow class="w-3 md:w-5" />
                </div>
              </div>
            </template>
            </ais-menu-select>
          </div>
          <ais-hits>
            <template v-slot:item="{ item }">
              <contract-row
                :key="item.id"
                :contract="contractById(item.id)"
                @open-contract="openContractModal(contractById(item.id))"
                class="flex justify-between h-full mb-5"
              />
            </template>
          </ais-hits>
          <ais-pagination
            :class-names="{
              'ais-Pagination-list': 'flex justify-center my-5 text-emerald-500',
              'ais-Pagination-item--selected': 'text-white dark:text-slate-700 bg-emerald-200',
              'ais-Pagination-item': 'text-lg border border-emerald-200 hover:text-white hover:bg-emerald-200 pagination-item',
              'ais-Pagination-item--firstPage': 'rounded-l-sm',
              'ais-Pagination-item--lastPage': 'rounded-r-sm',
              'ais-Pagination-link': 'px-2 py-1 pagination-link',
              'ais-Pagination-item--disabled': 'item-disabled cursor-not-allowed',
            }"
            @click.prevent="scrollToTop()"
          />
        </ais-instant-search>
      </template>
    </base-container>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { mapGetters, mapActions } from 'vuex';
import SearchIcon from '@/components/graphics/SearchIcon.vue';
import BaseContainer from '@/components/base/BaseContainer.vue';
import DropdownArrow from '@/components/graphics/DropdownArrow.vue';
import ContractRow from '../components/contracts/ContractRow.vue';
import CustomModal from '../components/base/CustomModal.vue';
import ColorBanner from '../components/graphics/ColorBanner.vue';
import ContractDetails from '../components/contracts/ContractDetails.vue';

export default {
  components: {
    ContractRow,
    BaseContainer,
    DropdownArrow,
    CustomModal,
    ColorBanner,
    ContractDetails,
    SearchIcon,
  },

  data() {
    return {
      showModalUpload: false,
      hitsPerPage: 20,
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APP_ID,
        process.env.VUE_APP_ALGOLIA_SECRET,
      ),
      workflowStates: {
        all: this.$t('contracts.workflowStates.all'),
        draft: this.$t('contracts.workflowStates.draft'),
        agreement_created_waiting_to_be_signed: this.$t('contracts.workflowStates.agreement_created_waiting_to_be_signed'),
        agreement_email_viewed: this.$t('contracts.workflowStates.agreement_email_viewed'),
        agreement_signed: this.$t('contracts.workflowStates.agreement_signed'),
        ready_to_be_shipped: this.$t('contracts.workflowStates.ready_to_be_shipped'),
        shipped: this.$t('contracts.workflowStates.shipped'),
        buy_good_requested: this.$t('contracts.workflowStates.buyGoodRequested'),
        stop_rent_requested: this.$t('contracts.workflowStates.stopRentRequested'),
        waiting_for_return: this.$t('contracts.workflowStates.waiting_for_return'),
        good_returned: this.$t('contracts.workflowStates.good_returned'),
        returned_good_check: this.$t('contracts.workflowStates.returned_good_check'),
        closed: this.$t('contracts.workflowStates.closed'),
        cancelled: this.$t('contracts.workflowStates.cancelled'),
      },
      selectedWorkflowStatus: '',
    };
  },
  async created() {
    await this.getContracts();
    this.selectedWorkflowStatus = this.$route.query && this.$route.query.status ? this.$route.query.status : '';
  },

  computed: {
    ...mapGetters('contracts', ['contracts', 'contract', 'contractById']),
  },

  watch: {
    selectedWorkflowStatus: {
      handler(newValue) {
        if (newValue) {
          this.statusFilter();
        }
      },
    },
  },

  methods: {
    ...mapActions('contracts', ['getContracts', 'setContract']),
    openContractModal(contract) {
      this.setContract(contract);
      this.$refs.contractDetails.openModal();
    },
    scrollToTop() {
      this.$refs.topList.scrollIntoView({ behavior: 'smooth' });
    },
    async filterByModel(ev, refine, items) {
      refine(items[ev].value);
    },
    transformItems(items) {
      return items.map((item) => ({
        ...item,
        label: this.workflowStates[item.label],
      }));
    },
    closeContractDetails() {
      this.$refs.contractDetails.closeModal();
    },
    applyWorkflowStatus(value) {
      this.selectedWorkflowStatus = value;
    },
    statusFilter() {
      return this.selectedWorkflowStatus ? `workflow_status:${this.selectedWorkflowStatus}` : '';
    },
  },
};
</script>

<style>
.item-disabled .pagination-link {
  @apply bg-gradient-to-l from-gray-100 to-gray-100 dark:from-slate-400 dark:to-slate-400 text-gray-400 dark:text-slate-500
}

.item-disabled .pagination-link:hover {
  @apply text-gray-400 dark:text-slate-500
}

.item-disabled.pagination-item {
  @apply border border-gray-300 dark:border-slate-500
}
</style>
