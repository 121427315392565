<template>
  <form class="flex flex-col text-gray-700 dark:text-white font-thin" @submit.prevent="saveGood">
    <div class="grid gap-x-4 gap-y-1">
      <h4 class="text-xl xl:text-2xl font-medium">{{ $t('goods.harpDecoder') }}</h4>
      <div class="grid grid-cols-3 xl:grid-cols-6 gap-4 items-end mb-4">
        <base-input
          :label="$t('goods.harp_model_code')"
          v-model="decoder.sku"
          borderStyle="border-goods"
          class="col-span-2"
        />
        <base-input
          :label="$t('goods.configuration')"
          v-model="decoder.config"
          borderStyle="border-goods"
        />
        <base-input
          :label="$t('goods.serial')"
          v-model="decoder.serial"
          borderStyle="border-goods"
        />
        <base-object-select
          :options="goodStates"
          :label="$t('goods.state')"
          v-model="decoder.state"
          valueField="id"
          descriptionField="description"
        />
        <base-object-select
          label="Location"
          v-model="decoder.good_location"
          :options="goodLocations"
          valueField="id"
          descriptionField="description"
        />
        <base-button extra-class="border-violet-400 text-violet-400 hover:bg-violet-300 self-end mt-3"
        @click.prevent="decodeGood">
          {{ $t('goods.decode') }}
        </base-button>
      </div>
    </div>
    <h4 class="text-xl xl:text-2xl font-medium">{{ $t('goods.insertGood') }}</h4>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-1">
      <base-object-select
        :label="$t('goods.harp_model')"
        v-model="good.harp_sku"
        required="true"
        :options="harpsOptions"
        valueField="sku"
        descriptionField="description"
      />
      <base-input
        :label="$t('goods.serial')"
        v-model="good.serial_no"
        required="true"
      />
      <base-object-select
        :label="$t('goods.stringsLayoutSlug')"
        :options="stringsLayoutsOptions"
        v-model="good.strings_layout_slug"
        :required="true"
        valueField="strings_layout_slug"
        descriptionField="description"
      />
      <base-object-select
        :label="$t('goods.color')"
        v-model="good.color_slug"
        :required="true"
        :options="harpColorsOptions"
        valueField="slug"
        descriptionField="color"
      />
      <base-object-select
        :label="$t('goods.state')"
        :options="goodStates"
        v-model="good.state"
        :required="true"
        valueField="id"
        descriptionField="description"
      />
      <base-object-select
        label="Location"
        v-model="good.good_location"
        :options="goodLocations"
        valueField="id"
        descriptionField="description"
        :required="true"
      />
      <label for="note" class="flex flex-col col-span-2 mt-2">
        {{ $t("goods.notes") }}
        <textarea
          id="note"
          v-model="good.note"
          class="bg-transparent rounded-md p-2 border border-gray-400 dark:border-gray-100"
        ></textarea>
      </label>
    </div>
    <base-button extra-class="border-violet-400 text-violet-400 hover:bg-violet-300 self-end mt-3
    disabled:border-gray-300 disabled:text-gray-300 disabled:bg-gray-100 dark:disabled:bg-slate-500"
    :disabled="saveDisabled">
      {{ $t("harps.save") }}
    </base-button>
  </form>
</template>

<script>
/* eslint-disable camelcase, object-curly-newline */
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import currencyCode from '@/config/currency-code';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseObjectSelect from '@/components/base/BaseObjectSelect.vue';

export default {
  components: {
    BaseInput,
    BaseButton,
    BaseObjectSelect,
  },

  data() {
    return {
      good: {
        harp_sku: '',
        serial_no: '',
        strings_layout_slug: '',
        color_slug: '',
        state: '',
        good_location: '',
        note: '',
      },
      decoder: {
        sku: '',
        config: '',
        serial: '',
        state: 'new',
        good_location: 'piasco',
      },

      currency: currencyCode.currency,
      goodStates: [
        { id: 'new', description: this.$t('goods.new') },
        { id: 'used', description: this.$t('goods.used') },
      ],
      selectedState: '',
      goodLocations: [
        {
          id: 'piasco',
          description: 'Piasco',
        },
        {
          id: 'milano',
          description: 'Milano',
        },
      ],
    };
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  async created() {
    await this.getHarps();
    await this.getStringsLayouts();
    await this.getHarpColors();
  },

  computed: {
    ...mapGetters('harps', ['harps']),
    ...mapGetters('stringsLayouts', ['stringsLayouts']),
    ...mapGetters('harpColors', ['harpColors']),

    harpsOptions() {
      return this.harps.map((harp) => ({ sku: harp.sku, description: harp.description }));
    },

    stringsLayoutsOptions() {
      return this.stringsLayouts.map((layout) => ({
        strings_layout_slug: layout.slug,
        description: layout.description,
      }));
    },

    harpColorsOptions() {
      return this.harpColors.map((color) => ({
        slug: color.slug,
        color: color.color,
      }));
    },

    saveDisabled() {
      return !this.good.harp_sku || !this.good.serial_no || !this.good.strings_layout_slug || !this.good.color_slug || !this.good.state;
    },

  },

  methods: {
    ...mapActions('harps', ['getHarps']),
    ...mapActions('goods', ['postGood', 'getDecodedGood']),
    ...mapActions('stringsLayouts', ['getStringsLayouts']),
    ...mapActions('harpColors', ['getHarpColors']),

    async saveGood() {
      this.good.serial_no = this.good.serial_no.toUpperCase();
      await this.postGood(this.good);
      this.toast.success(this.$t('goods.created'));
      this.$emit('close');
    },
    async decodeGood() {
      const decodedGood = await this.getDecodedGood(this.decoder);
      this.good.harp_sku = decodedGood.harp_sku;
      this.good.serial_no = decodedGood.serial_no;
      this.good.strings_layout_slug = decodedGood.strings_layout_slug;
      this.good.color_slug = decodedGood.color_slug;
      this.good.state = decodedGood.state;
      this.good.good_location = decodedGood.good_location;
    },
  },
};
</script>
