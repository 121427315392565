<template>
  <div class="flex flex-col font-thin bg-transparent">
    <div class="flex font-semibold space-x-1">
      <button
        :title="$t('buttons.contractInfo')"
        @click="$emit('openContract', contract)"
        class="text-2xl px-3 rounded-t-md border border-b-0 border-emerald-500 hover:bg-white text-emerald-500 bg-emerald-200 hover:bg-transparent hover:text-emerald-300
       dark:text-emerald-300 dark:hover:bg-emerald-200 dark:hover:text-slate-600 dark:bg-transparent">
        &#9432;
      </button>
      <p class="py-1.5 px-3 w-fit rounded-t-md bg-emerald-200 border border-emerald-500 border-b-0 text-emerald-500 dark:text-emerald-300 dark:bg-transparent">
        {{ contract ? contract.company : '' }}
      </p>
    </div>
    <div class="grid grid-cols-6 p-4 rounded-b-md rounded-tr-md border border-emerald-500 text-sm xl:text-base dark:text-gray-100">
      <div class="col-span-3 text-emerald-500 font-semibold">
        <p>
           {{ contract ? contract.contract_no : '' }}
        </p>
        <p>{{ contract ? workflowState : '' }}</p>
      </div>
      <div class="col-span-2">
        <p>{{ contract ? contract.good_description : '' }}</p>
        <p>{{ contract ? contract.good_serial_no : '' }}</p>
      </div>
      <div class="col-span-1">
        <p>{{ contract ? $d(contract.created_at, 'short') : '' }}</p>
        <p>{{ contract ? contract.payment_method : '' }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    contract: {
      type: Object,
    },
  },
  computed: {
    workflowState() {
      const workflowStates = {
        draft: this.$t('contracts.workflowStates.draft'),
        agreement_created_waiting_to_be_signed: this.$t('contracts.workflowStates.agreement_created_waiting_to_be_signed'),
        agreement_email_viewed: this.$t('contracts.workflowStates.agreement_email_viewed'),
        agreement_signed: this.$t('contracts.workflowStates.agreement_signed'),
        ready_to_be_shipped: this.$t('contracts.workflowStates.ready_to_be_shipped'),
        buy_good_requested: this.$t('contracts.workflowStates.buyGoodRequested'),
        stop_rent_requested: this.$t('contracts.workflowStates.stopRentRequested'),
        shipped: this.$t('contracts.workflowStates.shipped'),
        waiting_for_return: this.$t('contracts.workflowStates.waiting_for_return'),
        good_returned: this.$t('contracts.workflowStates.good_returned'),
        returned_good_check: this.$t('contracts.workflowStates.returned_good_check'),
        closed: this.$t('contracts.workflowStates.closed'),
        cancelled: this.$t('contracts.workflowStates.cancelled'),
      };
      return workflowStates[this.contract.workflow_status];
    },
  },

};
</script>
