<template>
  <button ref="algoliaRefresh" @click="refresh" hidden>refresh</button>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';

const connectRefresh = (renderFn, unmountFn) => (widgetParams = {}) => ({ // eslint-disable-line
  init() {
    renderFn({ refresh() { } }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);

    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  },
});

export default {
  name: 'AisStateResults',
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  methods: {
    refresh() {
      setTimeout(() => {
        this.state.refresh();
      }, 3000);
    },
  },
};
</script>
