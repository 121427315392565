<template>
  <div class="w-screen md:w-2/5 xl:w-1/4 2xl:w-1/5 h-screen grid grid-rows-3 dark:bg-gray-800 dark:text-white z-50">
    <img src="@/assets/logo-sonar.png" alt="Logo Sonar" class="dark:hidden flex w-2/3 place-self-center">
    <img src="@/assets/logo-sonar-white.png" alt="Logo Sonar" class="hidden dark:flex w-2/3 place-self-center">
    <form @submit.prevent="login" class="flex flex-col items-center space-y-6 font-thin w-4/5
    self-end justify-self-center row-start-2">
      <h2 class="text-3xl font-medium text-left w-full mb-4 text-light-blue-rental dark:text-light-blue-rental-200">{{ $t('login.login') }}</h2>
      <label for="email" class="flex flex-col w-full">
        {{ $t('login.email') }}
        <input
          type="email"
          name="email"
          class="border rounded-lg px-3 py-2 border-gray-300 bg-transparent dark:border-light-blue-rental-200"
          v-model="credentials.email"
          />
      </label>
      <div class="flex flex-col items-start w-full">
        <label for="password" class="flex flex-col w-full">
            {{ $t('login.password') }}
          <input
            type="password"
            name="password"
            class="border rounded-lg px-3 py-2 border-gray-300 bg-transparent dark:border-light-blue-rental-200"
            v-model="credentials.password"
          />
        </label>
        <router-link
          to="/forgot-password"
          class="text-xs text-light-blue-rental dark:text-light-blue-rental-200 mt-1"
        >{{ $t('login.forgot_password') }}</router-link>
      </div>
      <button
        type="submit"
        class="border border-light-blue-rental dark:border-light-blue-rental-200 px-3 py-1 rounded-md uppercase dark:hover:text-gray-800
        text-base text-light-blue-rental dark:text-light-blue-rental-200 font-medium hover:bg-light-blue-rental hover:text-white self-end"
      >
      {{ $t('login.login') }}
      </button>
    </form>
    <p class="text-xs text-center font-thin text-light-blue-rental dark:text-white
    self-end justify-self-center w-4/5 pb-5 row-start-3">
      {{ $t('rights') }} {{currentYear}}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      credentials: {
        email: '',
        password: '',
      },
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    async login() {
      await this.signIn(this.credentials);
      if (this.user.roles.length === 1 && this.user.roles[0].name === 'customer') {
        this.$router.push({ name: 'customer-private-dashboard' });
        return;
      }
      this.$router.push('/dashboard');
    },
  },
};
</script>

<style>
 input:focus {
  @apply outline outline-light-blue-rental-200/50 border-light-blue-rental-200 dark:border-white
 }
</style>
