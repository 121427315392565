<template>
  <custom-modal
    ref="calcPantheraCode"
    extra-class="bg-blue-400 text-white/90 font-medium fill-white/90"
  >
    <template v-slot:header>
      {{ $t('customers.panthera_customers')}}
    </template>
    <template v-slot:body>
      <panthera-code-calculator
        @close="$refs.calcPantheraCode.closeModal()"
        :customer="customer"
        :billing-address="billingAddress"
        @panthera_customer_has_been_selected="selectedPantheraCustomerId"
        @panthera_customer_has_been_created="createdPantheraCustomerId"
      />
    </template>
  </custom-modal>

  <div class="rounded-b-lg font-thin">
    <form class="grid 2xl:grid-cols-7 text-gray-700 dark:text-gray-100" @submit.prevent="saveCustomer">
      <div class="2xl:col-span-1 flex 2xl:flex-col space-x-4 2xl:space-x-0 pb-3">
        <label for="type">
          <input type="radio" name="type" value="F" id="F" v-model="customer.type"
          class="appearance-none w-4 h-4 mr-1 border-2 border-blue-400 checked:ring-2 rounded-sm
          ring-inset ring-white checked:bg-blue-400 dark:ring-slate-600 checked:outline-none" />
          {{ $t("customers.individual") }}
        </label>
        <label for="type">
          <input type="radio" name="type" value="G" id="G" v-model="customer.type"
          class="appearance-none w-4 h-4 mr-1 border-2 border-blue-400 checked:ring-2 rounded-sm
          ring-inset ring-white checked:bg-blue-400 dark:ring-slate-600 checked:outline-none"/>
          {{ $t("customers.legal") }}
        </label>
      </div>
      <div class="2xl:col-span-6 border-t 2xl:border-l 2xl:border-t-0 border-blue-400 pt-3 2xl:pl-8 space-y-5">
        <div class="grid grid-cols-2 gap-2 xl:gap-4">
          <base-input
            :label="$t('customers.company')"
            v-model="customer.company"
            :required="isCustomerLegal"
            :disabled="isCustomerIndividual"
          />
          <base-input
            :label="$t('customers.sdi_code')"
            v-model="customer.sdi_code"
            :required="isCustomerLegal"
            :disabled="isCustomerIndividual"
            maxLength="7"
          />
          <base-input
            :label="$t('customers.firstname')"
            v-model="customer.firstname"
            :required="isCustomerIndividual"
            @blur="companyName"
            :disabled="isCustomerLegal"
            class="col-start-1 capitalize"
          />
          <base-input
            :label="$t('customers.lastname')"
            v-model="customer.lastname"
            :required="isCustomerIndividual"
            @blur="companyName"
            :disabled="isCustomerLegal"
            class="capitalize"
          />
        </div>
        <div class="grid grid-cols-2 gap-2 xl:gap-4 justify-start items-start">
          <base-input
            :label="$t('customers.taxNumber')"
            v-model.trim.uppercase="customer.tax_number"
            :required="isCustomerIndividual"
            v-if="isTaxNumberRequested"
            v-show="isCustomerIndividual"
            @blur="checkCodiceFiscale"
            label-style="capitalize grid text-sm md:text-base items-start"
          />
          <base-input
            :label="$t('customers.vatId')"
            v-model.trim="customer.vat_id"
            :required="isCustomerLegal"
            v-if="isCustomerLegal"
            @blur="checkVatNumber"
          />
          <base-input
            :label="$t('customers.email')"
            v-model.trim.lowercase="customer.email"
            required="true"
            :rules="[(val) => !!val && this.emailRule.test(val)]"
            :error-message="$t('customers.invalid_email')"
          />
        </div>
        <div class="grid grid-cols-2 gap-2 xl:gap-4">
          <select-country
            v-model="billingAddress.country"
            required="true"
          />
          <base-input :label="$t('customers.address')" v-model="billingAddress.address" required="true" />
          <base-input
            :label="$t('customers.postcode')"
            v-model="billingAddress.postcode"
            required="true"
            :disabled="!billingAddress.country.length"
            :maxLength="italianLimitPostcode()"
          />
          <base-input :label="$t('customers.city')" v-model="billingAddress.city" required="true" class="capitalize"/>
          <select-state
            v-if="billingAddress.country.toLowerCase() === 'it'"
            v-model="billingAddress.state"
            required="true"
            :disabled="!billingAddress.country.length"
            :country="billingAddress.country.toLowerCase()"
          />
          <base-input :label="$t('customers.state')"
            v-else
            v-model="billingAddress.state" required="true"
            :disabled="!billingAddress.country.length"
            :maxLength="italianLimitState()"
            class="normal-case"
            label-style="capitalize grid text-sm md:text-base"
          />
          <base-input :label="$t('customers.phone')"
            v-model="billingAddress.phone"
            required="true"
            @keypress="checkNumericValue($event)"
          />
          <span v-show="showMessage" class="italic text-red-rental-200 text-sm pt-1">
            {{ $t("customers.fillField") }}
          </span>
          <base-input
            :label="$t('customers.pec')"
            v-model="customer.pec"
          />
          <base-select
            :label="$t('customers.preferred_language')"
            :options="locales"
            v-model="customer.lang"
          />
        </div>
        <div class="grid grid-cols-2 gap-2 xl:gap-4">
          <base-input :label="$t('customers.panthera')"
            v-model="customer.panthera_customer_id"
            disabled
            required="true"
          />
          <base-button
            type="button"
            extra-class="h-fit self-end border-blue-400 text-blue-400 hover:bg-blue-300 dark:disabled:hover:text-gray-300
            disabled:border-gray-300 disabled:text-gray-300 disabled:bg-gray-100 dark:disabled:bg-slate-500"
            @click.prevent="$refs.calcPantheraCode.openModal()" :disabled="isButtonDisabled"
          >
            {{ $t('customers.get_panthera_code') }}
          </base-button>
        </div>
        <div class="w-full flex justify-end space-x-5">
          <base-checkbox
            class="w-fit"
            :label="$t('contracts.createContract')"
            v-model="createContractAfter"
          />
          <base-button
          extra-class="border-blue-400 text-blue-400 hover:bg-blue-300 dark:disabled:hover:text-gray-300
          disabled:border-gray-300 disabled:text-gray-300 disabled:bg-gray-100 dark:disabled:bg-slate-500"
          :disabled="isSaveDisabled">
            {{ $t("customers.save") }}
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { useToast } from 'vue-toastification';

import Locales from '@/config/locales';
import BaseInput from '../base/BaseInput.vue';
import SelectCountry from '../base/SelectCountry.vue';
import BaseButton from '../base/BaseButton.vue';
import CustomModal from '../base/CustomModal.vue';
import BaseSelect from '../base/BaseSelect.vue';
import PantheraCodeCalculator from './PantheraCodeCalculator.vue';
import SelectState from '../base/SelectState.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';

export default {
  emits: ['close', 'createContract'],
  components: {
    BaseInput,
    SelectCountry,
    BaseButton,
    BaseSelect,
    CustomModal,
    PantheraCodeCalculator,
    SelectState,
    BaseCheckbox,
  },

  data() {
    return {
      customer: {
        type: 'F',
        company: '',
        firstname: '',
        lastname: '',
        sdi_code: '',
        email: '',
        currency_id: '',
        shop_id: '',
        panthera_customer_id: '',
        tax_number: '',
        vat_id: '',
        lang: this.$i18n.locale,
      },
      billingAddress: {
        type: 'B',
        address: '',
        city: '',
        postcode: '',
        state: '',
        country: '',
        phone: '',
        defaultAddress: 1,
      },
      showMessage: false,
      uninterceptedAxiosInstance: axios.create(),
      locales: Locales,
      saveButtonClicked: false,
      emailRule: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      createContractAfter: false,
    };
  },

  setup() {
    const toast = useToast();

    return { toast };
  },
  created() {
    this.customer.currency_id = this.shop.currency_id;
    this.customer.shop_id = this.shop.id;
  },
  computed: {
    ...mapGetters('shop', ['shop']),
    ...mapGetters('customers', ['customers']),

    countryNotSelected() {
      return this.billingAddress.country === '';
    },
    isTaxNumberRequested() {
      return this.shop.slug === 'samit';
    },
    isCustomerIndividual() {
      return this.customer.type === 'F';
    },
    isCustomerLegal() {
      return this.customer.type === 'G';
    },
    isButtonDisabled() {
      let customerFiltered;
      if (this.customer.type === 'F') {
        customerFiltered = (({
          // eslint-disable-next-line camelcase
          type, firstname, lastname, email, tax_number,
        }) => ({
          type, firstname, lastname, email, tax_number,
        }))(this.customer);
      }
      if (this.customer.type === 'G') {
        customerFiltered = (({
          // eslint-disable-next-line camelcase
          type, company, email, vat_id,
        }) => ({
          type, company, email, vat_id,
        }))(this.customer);
      }
      const billingAddressFiltered = (({
        address, city, postcode, state, country, phone,
      }) => ({
        address, city, postcode, state, country, phone,
      }))(this.billingAddress);
      return Object.values(customerFiltered).some((item) => item === '' || item === null) || Object.values(billingAddressFiltered).some((item) => item === '' || item === null) || this.customer.panthera_customer_id !== '';
    },
    isSaveDisabled() {
      return this.customer.panthera_customer_id === '' || this.saveButtonClicked;
    },
  },

  methods: {
    ...mapActions('customers', ['postCustomer', 'postAddress', 'getCustomers']),
    checkNumericValue(event) {
      if (!/[0-9\s+]/.test(event.key)) {
        event.preventDefault();
      }
    },
    italianLimitPostcode() {
      return this.billingAddress.country === 'IT' ? 5 : 10;
    },
    italianLimitState() {
      return this.billingAddress.country === 'IT' ? 2 : 100;
    },
    companyName() {
      if (this.isCustomerIndividual) {
        this.customer.company = `${this.customer.lastname} ${this.customer.firstname}`;
      }
    },
    async checkVatNumber() {
      try {
        await this.uninterceptedAxiosInstance.post('/api/check-vat', {
          vat_number: this.customer.vat_id?.replace(/\s/g, ''),
        });
        this.toast.success(this.$t('customers.validVatNumber'));
      } catch (error) {
        this.toast.error(this.$t('validation.vat_number'));
      }
    },
    async checkCodiceFiscale() {
      try {
        await this.uninterceptedAxiosInstance.post('/api/check-codicefiscale', {
          codice_fiscale: this.customer.tax_number?.replace(/\s/g, ''),
        });
        this.toast.success(this.$t('customers.validTaxNumber'));
      } catch (error) {
        this.toast.warning(this.$t('validation.codice_fiscale'));
      }
    },
    selectedPantheraCustomerId(selectedCustomer) {
      this.customer.panthera_customer_id = selectedCustomer.idCliente;
      this.$refs.calcPantheraCode.closeModal();
    },
    createdPantheraCustomerId(newPantheraCustomerId) {
      this.customer.panthera_customer_id = newPantheraCustomerId;
      this.$refs.calcPantheraCode.closeModal();
    },
    async saveCustomer() {
      if (this.countryNotSelected) {
        this.showMessage = true;
        return;
      }

      this.saveButtonClicked = true;

      this.customer.sdi_code = this.setSdiCode();

      let data = null;
      try {
        data = await this.postCustomer(this.customer);

        await this.postAddress({ id: data.id, payload: this.billingAddress });
        await this.getCustomers();

        this.toast.success(this.$t('customers.customer_added'));
        if (this.createContractAfter) {
          this.createContract(this.customer);
        }
        this.$emit('close');
      } catch (err) {
        const responseMessage = err.response.data.message || null;
        if (responseMessage && responseMessage.toLowerCase().includes('duplicate entry')) {
          this.toast.error(this.$t('customers.duplicated_entry'));
          throw new Error(err);
        }
        throw new Error(err);
      }
    },
    setSdiCode() {
      if (this.customer.type === 'G') return this.customer.sdi_code;
      return this.billingAddress.country === 'IT' ? '0000000' : 'XXXXXXX';
    },
    createContract(data) {
      const customer = this.customers.find((item) => item.panthera_customer_id === data.panthera_customer_id);
      this.$emit('createContract', customer);
    },
  },
};
</script>
