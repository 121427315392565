<template>
  <color-banner bgStyle="bg-goods"></color-banner>
  <custom-modal
    ref="importAccessories"
    extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    container-class="w-1/2"
  >
    <template v-slot:header>
      {{ $t("accessories.importTitle") }}
    </template>
    <template v-slot:body>
      <excel-importer importer="Accessories" @accessories-has-been-imported="onAccessoriesHasBeenImported" buttonStyle="button-goods" />
    </template>
  </custom-modal>
  <custom-modal
    ref="addAccessory"
    extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    container-class="w-3/4"
  >
    <template v-slot:header>
      {{ $t('accessories.addTitle') }}
    </template>
    <template v-slot:body>
      <add-accessory @close="$refs.addAccessory.closeModal()"/>
    </template>
  </custom-modal>
  <custom-modal
    ref="editAccessory"
    extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    container-class="w-3/4"
  >
    <template v-slot:header>
      <h1 class="text-xl">
        {{ $t('accessories.editTitle') }}
      </h1>
    </template>
    <template v-slot:body>
      <edit-accessory @close="$refs.editAccessory.closeModal()"/>
    </template>
  </custom-modal>

  <base-container>
    <template v-slot:buttons>
      <base-button @click="$refs.addAccessory.openModal()" :title="$t('buttons.addNew')"
        extra-class="fill-white bg-violet-200 hover:bg-transparent hover:fill-violet-400 border-violet-500
        dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600 mr-2">
        <add-icon />
      </base-button>
      <base-button @click="$refs.importAccessories.openModal()" :title="$t('buttons.importExcel')"
        extra-class="fill-white bg-violet-200 hover:bg-transparent hover:fill-violet-400 border-violet-500
        dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600">
        <import-excel />
      </base-button>
    </template>

    <template v-slot:title>
      {{ $t("accessories.accessoriesList") }}
    </template>
    <template v-slot:body>
      <accessory-row
        v-for="accessory in accessories"
        :key="accessory.id"
        :accessory="accessory"
        @edit="openEditModal(accessory)"
        class="flex justify-between h-full mb-5"
      />
    </template>
  </base-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useToast } from 'vue-toastification';

import ImportExcel from '@/components/graphics/ImportExcel.vue';
import AddIcon from '@/components/graphics/AddIcon.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import AddAccessory from '../components/accessories/AddAccessory.vue';
import EditAccessory from '../components/accessories/EditAccessory.vue';
import BaseContainer from '../components/base/BaseContainer.vue';
import AccessoryRow from '../components/accessories/AccessoryRow.vue';
import ColorBanner from '../components/graphics/ColorBanner.vue';
import ExcelImporter from '../components/base/ExcelImporter.vue';
import CustomModal from '../components/base/CustomModal.vue';

export default {
  components: {
    CustomModal,
    ColorBanner,
    AddAccessory,
    EditAccessory,
    BaseContainer,
    AccessoryRow,
    ExcelImporter,
    ImportExcel,
    BaseButton,
    AddIcon,
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  data() {
    return {
      showModalUpload: false,
    };
  },

  async created() {
    await this.getAccessories();
  },

  computed: {
    ...mapGetters('accessories', ['accessories']),
  },

  methods: {
    ...mapActions('accessories', ['getAccessories', 'setAccessory']),
    openEditModal(accessory) {
      this.setAccessory(accessory);
      this.$refs.editAccessory.openModal();
    },
    async onAccessoriesHasBeenImported() {
      await this.getAccessories();
      this.toast.success(this.$t('accessories.imported'));
      this.$refs.importAccessories.closeModal();
    },
  },
};
</script>
