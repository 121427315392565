<template>
  <div class="flex space-x-4 py-4 px-10 text-gray-400 dark:text-gray-100" :class="hasBackofficeRole ? 'justify-end' : 'justify-center'">
    <language-select/>
    <!-- <button class="group flex flex-col items-center" v-show="!hasBackofficeRole" @click="$refs.newPassword.openModal()">
      <key-icon class="w-6 h-6 text-sky-700 dark:text-sky-400 border-sky-700 bg-sky-500/20 dark:border-sky-400 dark:bg-sky-200/10 p-1 border rounded-full hover:scale-125 duration-200"/>
    </button> -->
    <button class="dark-mode" @click="toggleDarkMode()">
    </button>
    <router-link to="/user" v-show="hasBackofficeRole">
      <user-icon class="w-6 h-6 hover:scale-125 duration-200"/>
    </router-link>
    <!-- <settings-icon class="w-6 h-6 hover:scale-125 duration-200"/> -->
    <form @submit.prevent="logout">
      <button>
        <logout-icon class="w-6 h-6 text-red-600 hover:scale-125 duration-200"/>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserIcon from '../graphics/UserIcon.vue';
// import SettingsIcon from '../graphics/SettingsIcon.vue';
import LogoutIcon from '../graphics/LogoutIcon.vue';
import LanguageSelect from './LanguageSelect.vue';

export default {
  components: {
    UserIcon,
    // SettingsIcon,
    LogoutIcon,
    LanguageSelect,
  },
  created() {
    this.startColorMode();
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    async logout() {
      await this.signOut();
      this.$router.push('/');
    },
    startColorMode() {
      const lsTheme = localStorage.getItem('theme');
      this.setColorMode(lsTheme);
    },
    toggleDarkMode() {
      const lsTheme = localStorage.getItem('theme') === 'dark' ? 'light' : 'dark';
      this.setColorMode(lsTheme);
    },
    setColorMode(lsTheme) {
      const element = document.body;
      element.removeAttribute('class');
      element.classList.add(lsTheme);
      localStorage.setItem('theme', lsTheme);
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
    hasBackofficeRole() {
      return this.user ? this.user.roles.some((item) => item.name === 'backoffice') || this.user.is_super_admin : false;
    },
  },
};
</script>

<style scoped>
  .dark-mode {
    @apply w-6 h-6 p-1 rounded-full cursor-pointer flex items-center justify-center
    border border-sky-700 bg-sky-500/20 dark:border-sky-400 dark:bg-sky-200/10
    active:transition-transform active:ease-in-out active:scale-125 duration-1000
    before:bg-[url('@/assets/moon-icon.png')] before:bg-contain
    dark:before:bg-[url('@/assets/sun-icon.png')] before:w-full before:h-full
  }
  .router-link-active {
    @apply text-sky-400
  }
</style>
