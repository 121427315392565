<!-- eslint-disable max-len -->
<template>
  <div>
    <label for="partNumber" :class="classLbl">
      {{ lblText }}
      <input
        type="text"
        :class="classInput"
        name="partNumber"
        :value="modelValue"
        @input="onInput"
        @blur="onBlur"
        autocomplete="off"
        :disabled="selectNotAvailable"
      />
      <ul v-if="open"
        class="text-left text-xs lg:text-base rounded-md w-full
            mt-1 border border-zinc-200 dark:border-transparent
            grid z-40 absolute text-gray-600 max-h-52 overflow-y-scroll">
        <li
          v-for="item in matches" :key="item"
          class="py-2 px-3 font-bold border-b last:border-none border-zinc-200 dark:border-b-2
          dark:border-slate-600 bg-white dark:bg-slate-700 dark:text-white
          hover:dark:bg-emerald-400 hover:bg-emerald-400 hover:text-white"
          @mousedown="suggestionClick(item)"
          @keypress.enter="suggestionClick(item);"
        >
          {{ item.text }}
        </li>
        <p v-if="!matches"
          class="py-2 px-3 italic bg-white dark:bg-slate-700 dark:text-white pointer-events-none"
        >
          {{ $t('noMatchFound') }}
        </p>
      </ul>
    </label>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  props: {
    selectionList: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
      required: true,
    },
    lblText: {
      type: String,
      default: 'Select item',
    },
    classLbl: {
      type: String,
      default: 'w-full text-left text-sm lg:text-base mb-2',
    },
    classInput: {
      type: String,
      default: 'w-full rounded-md mt-1 font-thin py-2 px-2 bg-transparent border border-gray-400 dark:border-gray-100',
    },
    selectNotAvailable: {
      type: Boolean,
    },
  },
  data() {
    return {
      selection: '',
      current: 0,
      open: false,
    };
  },
  computed: {
    matches() {
      const selectValue = this.selectionList.filter((str) => str.text.toUpperCase().indexOf(this.selection.toUpperCase()) >= 0);
      return selectValue.length > 0 ? selectValue : false;
    },
    openSuggestion() {
      return this.selection !== ''
        && this.matches.length !== 0
        && this.open === true;
    },
  },
  methods: {
    ...mapActions('productionDocuments', ['updateItemCode']),
    onInput(e) {
      this.selection = e.target.value;
      this.open = !!this.selection;
      this.$emit('update:modelValue', e.target.value);
    },
    onBlur() {
      this.selection = '';
      this.$emit('update:modelValue', '');
      this.open = false;
    },
    suggestionClick(selection) {
      this.selection = '';
      this.open = false;
      this.$emit('update:modelValue', '');
      this.$emit('selected', selection);
    },
  },
};

</script>
