<template>
  <color-banner bgStyle="bg-goods"></color-banner>
  <custom-modal
    ref="importHarps"
    extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    container-class="w-1/2"
  >
    <template v-slot:header>
      {{ $t("harps.importTitle") }}
    </template>
    <template v-slot:body>
      <excel-importer importer="Harps" @harps-has-been-imported="onHarpsHasBeenImported" buttonStyle="button-goods" />
    </template>
  </custom-modal>

  <custom-modal
    ref="editHarp"
    extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    container-class="w-1/2">
    <template v-slot:header>
      {{ $t('harps.editTitle') }}
    </template>
    <template v-slot:body>
      <edit-harp @close="$refs.editHarp.closeModal()"/>
    </template>
  </custom-modal>

  <base-container>
    <template v-slot:buttons>
      <base-button @click="$refs.importHarps.openModal()" :title="$t('buttons.importExcel')"
          extra-class="fill-white bg-violet-200 hover:bg-transparent hover:fill-violet-400 border-violet-500
          dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600">
          <import-excel />
        </base-button>
    </template>
    <template v-slot:title>
      {{ $t("harps.harpsList") }}
    </template>
    <template v-slot:body>
      <harp-row v-for="harp in harps" :key="harp.id" :harp="harp" @edit="openEditModal(harp)" class="mb-4" />
    </template>
  </base-container>
</template>

<script>
// import { defineComponent, inject, PropType } from "vue";
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';

import ImportExcel from '@/components/graphics/ImportExcel.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import HarpRow from '../components/harps/HarpRow.vue';
import CustomModal from '../components/base/CustomModal.vue';
import BaseContainer from '../components/base/BaseContainer.vue';
import ExcelImporter from '../components/base/ExcelImporter.vue';
import ColorBanner from '../components/graphics/ColorBanner.vue';
import EditHarp from '../components/harps/EditHarp.vue';

export default {
  setup() {
    const toast = useToast();

    return { toast };
  },

  components: {
    CustomModal,
    BaseContainer,
    ExcelImporter,
    HarpRow,
    ColorBanner,
    EditHarp,
    ImportExcel,
    BaseButton,
  },

  async created() {
    await this.getHarps();
  },

  computed: {
    ...mapGetters('harps', ['harps']),
  },
  methods: {
    ...mapActions('harps', ['getHarps', 'setHarp']),
    async onHarpsHasBeenImported() {
      await this.getHarps();
      this.toast.success(this.$t('harps.uploaded'));
      this.$refs.importHarps.closeModal();
    },
    openEditModal(harp) {
      this.setHarp(harp);
      this.$refs.editHarp.openModal();
    },
  },
};
</script>
