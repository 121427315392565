<template>
  <div class="grid grid-cols-2 gap-x-3 overflow-hidden rounded-md border border-violet-500 pr-2
  text-center items-center font-thin dark:text-white">
    <p class="bg-violet-200 dark:bg-transparent p-3 text-violet-500 dark:text-violet-300 font-semibold
      border-r border-violet-500">
      {{ stringsLayout.slug }}
    </p>
    <p>
      {{ stringsLayoutTranslated }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    stringsLayout: {
      type: Object,
      required: true,
    },
  },
  computed: {
    stringsLayoutTranslated() {
      return this.stringsLayout.translations.find((item) => item.lang === this.$i18n.locale).text;
    },
  },
};
</script>
