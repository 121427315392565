<template>
  <p
    class="border rounded px-2 hover:border-emerald-500 hover:text-emerald-500 whitespace-pre-line"
    @click="selectAddress(addresses), currentAddress(addresses)"
    @keypress.enter="selectAddress(addresses), currentAddress(addresses)"
    :class="[extraClass, cardSelected]">
      {{ addresses.text.replaceAll('-', '\n') }}
  </p>
</template>

<script>
export default {
  props: {
    addresses: {
      type: Object,
    },
    extraClass: {
      type: String,
    },
    defaultClass: {
      type: String,
      default: 'border-gray-400',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    selectedClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    cardSelected() {
      return this.isSelected ? this.selectedClass : this.defaultClass;
    },
  },
  created(addresses) {
    this.currentAddress(addresses);
  },
  emits: ['selected', 'current'],
  methods: {
    selectAddress(addresses) {
      this.$emit('selected', addresses);
    },
    currentAddress(addresses) {
      this.$emit('current', addresses);
    },
  },
};
</script>
