<template>

<custom-modal
  ref="contractDetails"
  component-to-open="ContractDetails"
  extra-class="bg-emerald-400 text-white/90 font-medium fill-white/90"
>
  <template v-slot:header>
    {{ $t("contracts.contract") }}
  </template>
  <template v-slot:body>
    <contract-details @close="$refs.contractDetails.closeModal()" :contract="selectedContract"/>
  </template>
</custom-modal>

  <div class="flex flex-col font-thin w-full text-lg space-y-6 mt-3">
    <contract-row
      v-for="contract in contracts" :key="contract.id"
      :contract="contract"
      @open-contract="openContractModal(contract)"
    ></contract-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomModal from '../base/CustomModal.vue';
import ContractRow from './ContractRow.vue';
import ContractDetails from './ContractDetails.vue';

export default {
  emits: ['openContract', 'close'],
  components: {
    CustomModal,
    ContractRow,
    ContractDetails,
  },
  data() {
    return {
      selectedContract: {},
    };
  },
  props: {
    contracts: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    ...mapActions('contracts', ['setContract']),
    openContractModal(contract) {
      this.setContract(contract);
      this.selectedContract = contract;
      this.$refs.contractDetails.openModal();
    },
  },
};
</script>
