<template>
  <div class="flex flex-col rounded-2xl">
    <custom-modal
      ref="contractAddresses"
      component-to-open="ContractAddresses"
      extra-class="bg-blue-400 text-white/90 font-medium fill-white/90"
    >
      <template v-slot:header>
        <h1 class="text-xl" v-if="addingShippingAddress">
          {{ $t('customers.addAddress') }}
        </h1>
        <h1 class="text-xl" v-else>
          {{ $t('customers.editAddress') }}
        </h1>
      </template>
      <template v-slot:body>
        <customer-shipping-address :customer-type="editedCustomer.type" :address="addressToEdit" @save-address="saveShippingAddress" @close="$refs.contractAddresses.closeModal()" />
      </template>
    </custom-modal>

    <custom-modal
      ref="warningOnDelete"
      extra-class="bg-red-600 text-white/90 font-medium fill-white/90"
      container-class="w-1/2"
    >
      <template v-slot:header>
        <h1 class="text-xl">
          {{ $t('warn_title') }}
        </h1>
      </template>
      <template v-slot:body>
        <div class="text-gray-600 dark:text-white text-center xl:text-left space-y-3 text-lg">
          <p>{{ $t('warn_message') }}</p>
          <p>{{ $t('warn_question') }}</p>
          <div class="flex justify-around xl:justify-end space-x-2">
            <base-button @click="deleteShippingAddress"
            extra-class="hover:bg-red-500 dark:bg-opacity-80 hover:border-transparent">
              {{ $t('yes')}}
            </base-button>
            <base-button @click="$refs.warningOnDelete.closeModal"
            extra-class="hover:bg-sky-500 dark:bg-opacity-80 hover:border-transparent">
              {{ $t('no')}}
            </base-button>
          </div>
        </div>
      </template>
    </custom-modal>

    <form class="flex flex-col w-full text-gray-700 space-y-5 text-sm xl:test-base dark:text-white"
          @submit.prevent="updateCustomer">
      <div class="space-y-1">
        <div class="grid grid-cols-2 2xl:grid-cols-3 gap-x-2 xl:gap-x-4 justify-start items-start">
          <h3 class="text-xl font-medium col-span-2 2xl:col-span-3 capitalize">{{ $t('customers.biodata') }}</h3>
          <base-input
            v-if="customer.type === 'G'"
            :label="$t('customers.company')"
            v-model="editedCustomer.company"
            :required="true"
          />
          <base-input
            v-if="customer.type === 'G'"
            :label="$t('customers.sdi_code')"
            v-model="editedCustomer.sdi_code"
            :required="true"
            maxLength="7"
          />
          <base-input
            v-if="customer.type === 'F'"
            :label="$t('customers.firstname')"
            v-model="editedCustomer.firstname"
            :required="true"
            class="col-span-1 capitalize"
          />
          <base-input
            v-if="customer.type === 'F'"
            :label="$t('customers.lastname')"
            v-model="editedCustomer.lastname"
            :required="true"
            class="col-span-1 capitalize"
          />
          <base-input
            :label="$t('customers.email')"
            v-model.trim.lowercase="editedCustomer.email"
            :required="true"
            :rules="[(val) => !!val && this.emailRule.test(val)]"
            :error-message="$t('customers.invalid_email')"
            class="col-span-1"
          />
          <base-input
            :label="$t('customers.panthera')"
            v-model="editedCustomer.panthera_customer_id"
            disabled="true"
          />
          <base-input
            :label="$t('customers.taxNumber')"
            v-model.trim.uppercase="editedCustomer.tax_number"
            v-show="isCustomerIndividual"
            @blur="checkCodiceFiscale"
            :required="isCustomerIndividual"
            label-style="capitalize grid text-sm md:text-base"
          />
          <base-input
            :label="$t('customers.vatId')"
            v-model.trim="editedCustomer.vat_id"
            v-show="isCustomerLegal"
            @blur="checkVatNumber"
            :required="isCustomerLegal"
          />
          <base-input
            :label="$t('customers.pec')"
            v-model="editedCustomer.pec"
            class="col-span-1"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-y-1 gap-x-3 xl:gap-x-4">
        <h3 class="text-xl font-medium col-span-2 capitalize">{{ $t('customers.billingAddress') }}</h3>
        <select-country
          v-model="editedCustomer.billing_address.country"
          :show-message="showMessage"
          required="true"
        />
        <base-input
          :label="$t('customers.address')"
          v-model="editedCustomer.billing_address.address"
          :required="true"
        />
        <base-input
          :label="$t('customers.co')"
          v-model="editedCustomer.billing_address.co"
        />
        <base-input
          :label="$t('customers.postcode')"
          v-model="editedCustomer.billing_address.postcode"
          :required="true"
          :disabled="!editedCustomer.billing_address.country.length"
          :maxLength="italianLimitPostcode()"
        />
        <base-input
          :label="$t('customers.city')"
          v-model="editedCustomer.billing_address.city"
          :required="true"
          class="capitalize"
        />
        <select-state
          v-if="editedCustomer.billing_address.country.toLowerCase() === 'it'"
          v-model="editedCustomer.billing_address.state"
          required="true"
          :disabled="!editedCustomer.billing_address.country.length"
          :country="editedCustomer.billing_address.country.toLowerCase()"
          :state="editedCustomer.billing_address.state"
        />
        <base-input
          v-else
          :label="$t('customers.state')"
          v-model="editedCustomer.billing_address.state"
          :required="true"
          :disabled="!editedCustomer.billing_address.country.length"
          :maxLength="italianLimitState()"
          :class="editedCustomer.billing_address.country === 'IT' ? 'uppercase' : 'normal-case'"
          label-style="capitalize grid text-sm md:text-base"
        />
        <base-input
          :label="$t('customers.phone')"
          v-model="editedCustomer.billing_address.phone"
          @keypress="checkNumericValue($event)"
          required="true"
        />
        <base-select
          :label="$t('customers.preferred_language')"
          :options="locales"
          v-model="editedCustomer.lang"
        />
      </div>
      <base-table
        :visible-fields="addressFields"
        :items="baseTableAddress"
        :table-title="$t('customers.shippingAddresses')"
        @edit-row="editShippingAddress"
        @add-row="addShippingAddress"
        @delete-row="showWarnDialog"
        header-cell-style="bg-blue-400"
      ></base-table>
      <div class="flex w-full justify-end">
        <base-button extra-class="border-blue-400 text-blue-400 hover:bg-blue-300">
          {{ $t("customers.save") }}
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable camelcase, no-useless-rename */
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import axios from 'axios';
import Locales from '@/config/locales';

import BaseSelect from '../base/BaseSelect.vue';
import BaseInput from '../base/BaseInput.vue';
import SelectCountry from '../base/SelectCountry.vue';
import BaseTable from '../base/BaseTable.vue';
import CustomerShippingAddress from './CustomerShippingAddress.vue';
import CustomModal from '../base/CustomModal.vue';
import BaseButton from '../base/BaseButton.vue';
import SelectState from '../base/SelectState.vue';

export default {
  name: 'EditCustomer.vue',

  components: {
    BaseInput,
    SelectCountry,
    BaseTable,
    CustomerShippingAddress,
    CustomModal,
    BaseButton,
    BaseSelect,
    SelectState,
  },

  data() {
    return {
      addressFields: [
        {
          field: 'defaultAddress',
          label: this.$t('customers.defaultAddress'),
          type: 'boolean',
        },
        {
          field: 'company',
          label: this.$t('customers.fullname'),
        },
        {
          field: 'address',
          label: this.$t('customers.address'),
        },
        {
          field: 'postcode',
          label: this.$t('customers.postcode'),
        },
        {
          field: 'city',
          label: this.$t('customers.city'),
        },
        {
          field: 'state',
          label: this.$t('customers.state'),
        },
        {
          field: 'country',
          label: this.$t('customers.country'),
        },
        {
          field: 'phone',
          label: this.$t('customers.phone'),
        },
        {
          field: 'note',
          label: this.$t('customers.note'),
        },
      ],
      editedCustomer: {},
      showMessage: false,
      addingShippingAddress: false,
      uninterceptedAxiosInstance: axios.create(),
      locales: Locales,
      emailRule: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    };
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('customers', ['customer']),
    baseTableAddress() {
      return this.editedCustomer.shipping_addresses;
    },
    isCustomerIndividual() {
      return this.customer.type === 'F';
    },
    isCustomerLegal() {
      return this.customer.type === 'G';
    },
  },
  watch: {
    customer: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.init(newValue);
        }
      },
    },
  },
  methods: {
    ...mapActions('customers', [
      'putCustomer',
      'putCustomerAddress',
      'postCustomerAddress',
      'deleteCustomerAddress',
      'setCustomer',
      'getCustomers',
    ]),
    init() {
      this.editedCustomer = JSON.parse(JSON.stringify(this.customer)); // clone object;
    },
    checkNumericValue(event) {
      if (!/[0-9\s+]/.test(event.key)) {
        event.preventDefault();
      }
    },
    async checkVatNumber() {
      try {
        await this.uninterceptedAxiosInstance.post('/api/check-vat', {
          vat_number: this.editedCustomer.vat_id?.replace(/\s/g, ''),
        });
        this.toast.success(this.$t('customers.validVatNumber'));
      } catch (error) {
        this.toast.error(this.$t('validation.vat_number'));
      }
    },
    async checkCodiceFiscale() {
      try {
        await this.uninterceptedAxiosInstance.post('/api/check-codicefiscale', {
          codice_fiscale: this.editedCustomer.tax_number?.replace(/\s/g, ''),
        });
        this.toast.success(this.$t('customers.validTaxNumber'));
      } catch (error) {
        this.toast.warning(this.$t('validation.codice_fiscale'));
      }
    },
    async updateCustomer() {
      if (!this.editedCustomer.billing_address.country) {
        this.showMessage = true;
        return;
      }
      this.editedCustomer.company = this.editedCustomer.type === 'G' ? this.editedCustomer.company : `${this.editedCustomer.lastname} ${this.editedCustomer.firstname}`;
      this.editedCustomer.sdi_code = this.setSdiCode();

      const {
        id,
        company,
        firstname,
        lastname,
        email,
        panthera_customer_id,
        type,
        tax_number,
        vat_id,
        billing_address,
        pec,
        sdi_code,
        lang,
      } = this.editedCustomer; // clone object

      await this.updateBillingAddress({company, firstname, lastname, ...billing_address }); // eslint-disable-line

      const updatedCustomer = await this.putCustomer({
        id,
        payload: {
          company,
          firstname,
          lastname,
          email,
          panthera_customer_id,
          type,
          vat_id,
          tax_number,
          pec,
          sdi_code,
          lang,
        },
      });

      await this.setCustomer(updatedCustomer);
      this.toast.success(this.$t('customers.updated'));
      this.$emit('close');
    },
    async updateBillingAddress(address) {
      const { id, customer_id, company, firstname, lastname, created_at: _1, updated_at: _2, ...remaining } = address; // eslint-disable-line
      await this.putCustomerAddress({
        customerId: customer_id,
        addressId: id,
        payload: {
          company,
          firstname,
          lastname,
          ...remaining,
        },
      });
    },
    addShippingAddress() {
      this.addingShippingAddress = true;
      this.addressToEdit = (({ company, firstname, lastname, email }) => ({ company, firstname, lastname, email }))(JSON.parse(JSON.stringify(this.editedCustomer))); // eslint-disable-line
      this.addressToEdit.address = '';
      this.addressToEdit.postcode = '';
      this.addressToEdit.co = '';
      this.addressToEdit.state = '';
      this.addressToEdit.city = '';
      this.addressToEdit.country = '';
      this.addressToEdit.defaultAddress = false;
      this.addressToEdit.note = '';
      this.addressToEdit.phone = '';
      this.$refs.contractAddresses.openModal();
    },
    editShippingAddress(address) {
      this.addingShippingAddress = false;
      this.addressToEdit = address;
      this.$refs.contractAddresses.openModal();
    },
    async saveShippingAddress(address) {
      const shippingAddress = {
        customerId: this.customer.id,
        payload: {
          type: 'S',
          firstname: address.firstname,
          lastname: address.lastname,
          company: address.company || `${address.firstname} ${address.lastname}`,
          ...address,
        },
      };

      if (this.addingShippingAddress) {
        this.insertShippingAddress(shippingAddress);
      }

      if (!this.addingShippingAddress) {
        this.updateShippingAddress(shippingAddress);
      }

      this.addingShippingAddress = false;
      this.$refs.contractAddresses.closeModal();
    },

    normalizeDefaultAddress(address) {
      if (address.payload.defaultAddress) {
        this.unsetDefaultAddress();
      }
      return address;
    },
    async insertShippingAddress(shippingAddress) {
      const address = this.normalizeDefaultAddress({ ...shippingAddress });
      const resp = await this.postCustomerAddress(address);
      this.editedCustomer.shipping_addresses.push(resp);
      this.setCustomer(this.editedCustomer);
    },

    async updateShippingAddress(address) {
      const shippingAddress = this.normalizeDefaultAddress({ ...address });
      shippingAddress.addressId = address.payload.id;
      // remove unwanted property
      delete shippingAddress.payload.created_at; // eslint-disable-line
      delete shippingAddress.payload.updated_at; // eslint-disable-line

      await this.putCustomerAddress(shippingAddress);
      this.replaceShippingAddress(shippingAddress.addressId, shippingAddress.payload);
      this.getCustomers();
    },
    replaceShippingAddress(id, replacement) {
      const addressIndex = this.editedCustomer.shipping_addresses.findIndex((item) => item.id === id);
      if (addressIndex === -1) {
        return;
      }
      if (replacement) {
        this.editedCustomer.shipping_addresses.splice(addressIndex, 1, replacement);
        return;
      }
      this.editedCustomer.shipping_addresses.splice(addressIndex, 1);
    },
    showWarnDialog(address) {
      this.addressIdToDelete = address.id;
      this.$refs.warningOnDelete.openModal();
    },
    async deleteShippingAddress() {
      await this.deleteCustomerAddress({ customerId: this.customer.id, addressId: this.addressIdToDelete });
      this.replaceShippingAddress(this.addressIdToDelete);
      this.addressIdToDelete = null;
      this.$refs.warningOnDelete.closeModal();
    },
    async unsetDefaultAddress() {
      const promises = [];
      for (const address of this.editedCustomer.shipping_addresses) { // eslint-disable-line
        address.defaultAddress = false;
        const { id, customer_id, company, firstname, lastname, phone, created_at: _1, updated_at: _2, ...remaining } = address; // eslint-disable-line
        // eslint-disable-next-line
        promises.push(this.putCustomerAddress({
          customerId: customer_id,
          addressId: id,
          payload: {
            company,
            firstname,
            lastname,
            phone,
            ...remaining,
          },
        }));
      }
      await Promise.all(promises);
    },
    setSdiCode() {
      if (this.editedCustomer.type === 'G') return this.editedCustomer.sdi_code;
      return this.editedCustomer.billing_address.country === 'IT' ? '0000000' : 'XXXXXXX';
    },
    italianLimitPostcode() {
      return this.editedCustomer.billing_address.country === 'IT' ? 5 : 10;
    },
    italianLimitState() {
      return this.editedCustomer.billing_address.country === 'IT' ? 2 : 100;
    },
  },
};
</script>
