<template>
  <div class="w-full" ref="topList">
    <color-banner bgStyle="bg-goods"></color-banner>
    <custom-modal
      ref="importGoods"
      extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
      container-class="w-1/2"
    >
      <template v-slot:header>
        {{ $t("goods.importTitle") }}
      </template>
      <template v-slot:body>
        <excel-importer importer="Goods" @goods-has-been-imported="onGoodsHasBeenImported" buttonStyle="button-goods" />
      </template>
    </custom-modal>
    <custom-modal
      ref="editGood"
      extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
      container-class="w-5/6 xl:w-3/4"
    >
      <template v-slot:header>
        {{ $t('goods.editTitle') }}
      </template>
      <template v-slot:body>
        <edit-good @close="$refs.editGood.closeModal()"/>
      </template>
    </custom-modal>

    <custom-modal
      ref="addContract"
      component-to-open="AddContract"
      container-class="w-5/6 xl:w-3/4"
      extra-class="bg-emerald-400 text-white/90 font-medium fill-white/90"
      @close="clearWizard()"
    >
      <template v-slot:header>
        <h1 class="text-xl">
          {{ $t('contracts.addTitle') }}
        </h1>
      </template>
      <template v-slot:body>
        <add-contract @close="saveAndShowContract()"/>
      </template>
    </custom-modal>
    <custom-modal
      ref="contractDetails"
      component-to-open="ContractDetails"
      container-class="w-5/6 xl:w-3/4"
      extra-class="bg-emerald-400 text-white/90 font-medium fill-white/90"
      @close="clearWizard()"
    >
      <template v-slot:header>
        <h1 class="text-xl">
          {{ $t('contracts.contract') }}
        </h1>
      </template>
      <template v-slot:body>
        <contract-details @close="closeContractDetails()" :good="good"/>
      </template>
    </custom-modal>
    <custom-modal
      ref="addGood"
      component-to-open="AddGood"
      container-class="w-5/6 xl:w-3/4"
      extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    >
      <template v-slot:header>
        <h1 class="text-xl">
          {{ $t('goods.addGood') }}
        </h1>
      </template>
      <template v-slot:body>
        <add-good @close="closeAddGoodModal()"/>
      </template>
    </custom-modal>

    <base-container :buttons-slot="false">
      <template v-slot:title>
        {{ $t('goods.goods') }}
      </template>
      <template v-slot:body>
        <ais-instant-search
            :search-client="searchClient"
            index-name="goods"
            :future="{ preserveSharedStateOnUnmount: true }"
            :class-names="{
              'ais-InstantSearch': 'flex flex-col space-y-5',
            }"
          >
            <ais-configure
              :hits-per-page.camel="hitsPerPage"
              :filters="stateFilter"
            />
            <div class="flex flex-col xl:flex-row w-full justify-between mt-5 items-center">
              <div class="flex w-full xl:w-2/5 2xl:w-1/2 space-x-1 justify-between mb-2 xl:mb-0 xl:mr-3">
                <div class="flex space-x-2">
                  <base-button @click="$refs.addGood.openModal()" :title="$t('goods.addGood')"
                    extra-class="fill-white bg-violet-200 hover:bg-transparent hover:fill-violet-400 border-violet-500
                    dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600">
                    <add-icon />
                  </base-button>
                  <base-button @click="$refs.importGoods.openModal()" :title="$t('buttons.importExcel')"
                    extra-class="fill-white bg-violet-200 hover:bg-transparent hover:fill-violet-400 border-violet-500
                    dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600">
                    <import-excel />
                  </base-button>
                </div>
                <ais-search-box
                  :class-names="{
                    'ais-SearchBox': 'w-1/2 xl:w-3/4',
                  }"
                >
                  <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                    <label for="srch">
                      <div class="relative flex items-center text-black dark:text-white">
                      <input
                        name="srch"
                        type="search"
                        :value="currentRefinement"
                        @input="refine($event.currentTarget.value)"
                        class="rounded-md p-2 w-full bg-transparent border border-gray-400 dark:border-gray-100 col-span-1"
                      />
                      <div class="absolute right-0 cursor-pointer pr-3">
                        <search-icon v-if="!currentRefinement" @click="refine($event.currentTarget.value)" class="w-4" />
                      </div>
                    </div>
                    </label>
                    <span :hidden="!isSearchStalled">Loading...</span>
                  </template>
                </ais-search-box>
              </div>
              <ais-menu
                attribute="is_rented_now"
                :class-names="{
                  'ais-Menu': 'w-full xl:w-3/5 2xl:w-1/2 grid place-self-end self-center',
                }"
              >
                <template
                  v-slot="{
                    items,
                    refine,
                  }"
                >
                  <span class="text-sm isolate inline-flex rounded-md border-violet-500 place-self-end" id="ButtonGroup">
                    <button ref="allGoods" type="button"
                    class="relative -ml-px rounded-l-md inline-flex items-center px-3 py-2 text-sm font-semibold border border-violet-500 hover:bg-violet-200 hover:text-violet-500
                    dark:hover:bg-violet-400 dark:hover:text-slate-600"
                    @click.prevent="filterGoods(0, refine, items)" :class="activeClass(0)">{{ $t('all') }}</button>
                    <button type="button"
                    class="relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold border border-violet-500 hover:bg-violet-200 hover:text-violet-500
                    dark:hover:bg-violet-400 dark:hover:text-slate-600"
                    @click.prevent="filterGoods(1, refine, items)" :class="activeClass(1)">{{ $t('available') }}</button>
                    <button type="button"
                    class="relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold border border-violet-500 hover:bg-violet-200 hover:text-violet-500
                    dark:hover:bg-violet-400 dark:hover:text-slate-600"
                    @click.prevent="filterGoods(2, refine, items)" :class="activeClass(2)">{{ $t('unavailable') }}</button>
                    <button type="button"
                    class="relative -ml-px rounded-r-md inline-flex items-center px-3 py-2 text-sm font-semibold border border-violet-500 hover:bg-violet-200 hover:text-violet-500
                    dark:hover:bg-violet-400 dark:hover:text-slate-600"
                    @click.prevent="filterGoods(3, refine, items)" :class="activeClass(3)">{{ $t('sold') }}</button>
                  </span>
                </template>
              </ais-menu>
            </div>
            <div class="mb-10 grid grid-cols-4 gap-3 w-full justify-center text-black dark:text-white">
              <algolia-menu-select attribute="harp_description" :initial-label="$t('harps.allModels')"></algolia-menu-select>
              <algolia-menu-select attribute="color_slug" :initial-label="$t('harp-colors.allColors')"></algolia-menu-select>
              <algolia-menu-select attribute="strings_layout_slug" :initial-label="$t('strings-layouts.allStrings')"></algolia-menu-select>
              <algolia-menu-select attribute="good_location" :initial-label="$t('goods.allLocations')"></algolia-menu-select>
            </div>
            <algolia-refresh ref="algoliaRefresh"/>
            <ais-state-results>
              <template v-slot="{ results: { hits } }">
                <ais-hits v-if="hits.length > 0"  >
                  <template v-slot:item="{ item }">
                    <good-row
                      :key="item.id"
                      :good="goodById(item.id)"
                      @edit="openEditModal(goodById(item.id))"
                      @create-contract="openAddContractModal(goodById(item.id))"
                      @open-contract="openContractModal(goodById(item.id))"
                      class="mb-5"
                    />
                  </template>
                </ais-hits>
                <div v-else>
                  <p class="w-full text-center font-thin text-2xl dark:text-white">{{ $t('noMatchFound') }}</p>
                </div>
              </template>
            </ais-state-results>
            <ais-pagination
              :class-names="{
                'ais-Pagination-list': 'flex justify-center my-5 text-violet-500',
                'ais-Pagination-item--selected': 'text-white dark:text-slate-700 bg-violet-200',
                'ais-Pagination-item': 'text-lg border border-violet-200 hover:text-white hover:bg-violet-200 pagination-item',
                'ais-Pagination-item--firstPage': 'rounded-l-sm',
                'ais-Pagination-item--lastPage': 'rounded-r-sm',
                'ais-Pagination-link': 'px-2 py-1 pagination-link',
                'ais-Pagination-item--disabled': 'item-disabled cursor-not-allowed',
              }"
              @click.prevent="scrollToTop()"
            />
        </ais-instant-search>
      </template>
    </base-container>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

import ImportExcel from '@/components/graphics/ImportExcel.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import AddIcon from '@/components/graphics/AddIcon.vue';
import AddGood from '@/components/goods/AddGood.vue';
import SearchIcon from '@/components/graphics/SearchIcon.vue';
import AlgoliaMenuSelect from '@/components/base/AlgoliaMenuSelect.vue';
import AlgoliaRefresh from '@/components/base/AlgoliaRefresh.vue';
import GoodRow from '../components/goods/GoodRow.vue';
import CustomModal from '../components/base/CustomModal.vue';
import ExcelImporter from '../components/base/ExcelImporter.vue';
import BaseContainer from '../components/base/BaseContainer.vue';
import EditGood from '../components/goods/EditGood.vue';
import AddContract from '../components/contracts/AddContract.vue';
import ColorBanner from '../components/graphics/ColorBanner.vue';
import ContractDetails from '../components/contracts/ContractDetails.vue';

export default {

  setup() {
    const toast = useToast();

    return { toast };
  },
  components: {
    CustomModal,
    GoodRow,
    ExcelImporter,
    BaseContainer,
    AddGood,
    EditGood,
    AddContract,
    ColorBanner,
    ContractDetails,
    BaseButton,
    AddIcon,
    ImportExcel,
    SearchIcon,
    AlgoliaMenuSelect,
    AlgoliaRefresh,
  },

  data() {
    return {
      hitsPerPage: 20,
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APP_ID,
        process.env.VUE_APP_ALGOLIA_SECRET,
      ),
      buttonIndex: 0,
      uninterceptedAxiosInstance: axios.create(),
      stateFilter: 'state:sold OR NOT state:sold',
      refinements: [
        {
          index: 0,
          refinement: {
            state: 'state:sold OR NOT state:sold',
            refine: null,
          },
        },
        {
          index: 1,
          refinement: {
            state: 'NOT state:sold',
            refine: 'false',
          },
        },
        {
          index: 2,
          refinement: {
            state: 'NOT state:sold',
            refine: 'true',
          },
        },
        {
          index: 3,
          refinement: {
            state: 'state:sold',
            refine: null,
          },
        },
      ],
    };
  },

  async created() {
    await this.getGoods();
    await this.getContracts();
    await this.getHarps();
  },

  watch: {
    good: {
      deep: true,
      async handler(newValue) {
        if (newValue) {
          await this.getGoods();
        }
      },
    },
    contract: {
      deep: true,
      async handler(newValue) {
        if (newValue) {
          await this.getGoods();
        }
      },
    },
  },

  computed: {
    ...mapGetters('goods', ['goods', 'good', 'goodById']),
    ...mapGetters('contracts', ['contracts', 'contract']),
    ...mapGetters('harps', ['harps', 'harp']),
    activeClass() {
      return (index) => this.buttonIndex === index ? 'bg-violet-200 text-violet-500 dark:bg-violet-400 dark:text-slate-600' : 'text-violet-400 bg-transparent dark:text-violet-300 dark:bg-transparent'; // eslint-disable-line
    },
  },

  methods: {
    ...mapActions('goods', ['getGoods', 'setGood']),
    ...mapActions('contractTemplates', ['setContractTemplate']),
    ...mapActions('contracts', ['getContracts']),
    ...mapActions('harps', ['getHarps', 'setHarp']),
    ...mapActions('customers', ['getCustomers', 'setCustomer', 'setShippingAddress']),
    async onGoodsHasBeenImported() {
      this.$refs.algoliaRefresh.refresh();
      await this.getGoods();
      this.toast.success('Goods Imported!');
      this.$refs.importGoods.closeModal();
    },
    saveAndShowContract() {
      this.$refs.addContract.closeModal();
      this.$refs.contractDetails.openModal();
    },
    openEditModal(good) {
      this.setGood(good);
      this.$refs.editGood.openModal();
    },
    async openAddContractModal(good) {
      this.setGood(good);
      try {
        await this.uninterceptedAxiosInstance.get(`/api/goods/check-good/${good.serial_no}`);
        this.toast.success(this.$t('goods.free'));
      } catch (error) {
        this.toast.error(this.$t('goods.inUse'));
      }
      this.$refs.addContract.openModal();
    },
    openContractModal(good) {
      this.setGood(good);
      this.$refs.contractDetails.openModal();
    },
    clearWizard() {
      this.setContractTemplate({});
      this.setCustomer({});
      this.setGood({});
      this.setShippingAddress({});
    },
    async filterGoods(index, refine) {
      this.buttonIndex = index;
      this.stateFilter = this.refinements[index].refinement.state;
      refine(this.refinements[index].refinement.refine);
    },
    scrollToTop() {
      this.$refs.topList.scrollIntoView({ behavior: 'smooth' });
    },
    closeAddGoodModal() {
      this.$refs.algoliaRefresh.refresh();
      this.$refs.addGood.closeModal();
    },
    closeContractDetails() {
      this.$refs.algoliaRefresh.refresh();
      this.$refs.contractDetails.closeModal();
    },
  },
};
</script>

<style>
.item-disabled .pagination-link {
  @apply bg-gradient-to-l from-gray-100 to-gray-100 dark:from-slate-400 dark:to-slate-400 text-gray-400 dark:text-slate-500
}

.item-disabled .pagination-link:hover {
  @apply text-gray-400 dark:text-slate-500
}

.item-disabled.pagination-item {
  @apply border border-gray-300 dark:border-slate-500
}
</style>
