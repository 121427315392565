<template>
  <div class="bgImage hidden md:flex md:w-3/5 xl:w-3/4 2xl:w-4/5">
    <div class="flex flex-col justify-center items-center bg-white dark:bg-gray-800 dark:bg-opacity-70 bg-opacity-75 h-screen">
      <div class="lg:w-3/4 relative">
        <img src="@/assets/RentalHomepage.jpg" alt="Rental Homepage" class="rounded h-screen object-cover xl:h-auto" />
        <h1 class="text-white dark:text-gray-700 text-5xl leading-none absolute top-12 left-16 right-16
        font-light tracking-wide font-sans-rental animate-translate-from-left uppercase whitespace-pre-line"
        >
          {{ $t('login.claim') }}
        </h1>
      </div>
    </div>
  </div>
</template>
