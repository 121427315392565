<template>
  <div class="w-screen flex dark:bg-slate-600">
    <menu-rental v-if="authenticated && hasBackofficeRole"/>
    <div class="h-screen overflow-y-scroll overflow-x-hidden"
    :class="authenticated && hasBackofficeRole ? 'w-screen lg:w-3/4 xl:w-4/5 2xl:w-5/6' : 'w-screen'">
      <top-navbar v-if="authenticated && hasBackofficeRole"/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MenuRental from './components/base/MenuRental.vue';
import TopNavbar from './components/base/TopNavbar.vue';

export default {
  components: {
    MenuRental,
    TopNavbar,
  },
  async created() {
    await this.getFirstShop();
    if (!localStorage.getItem('theme')) {
      const soTheme = window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light';
      localStorage.setItem('theme', soTheme);
    }
  },
  mounted() {
    const lsTheme = localStorage.getItem('theme');
    const element = document.body;
    element.removeAttribute('class');
    element.classList.add(lsTheme);
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'user']),
    hasBackofficeRole() {
      return this.user.roles.some((item) => item.name === 'backoffice') || this.user.is_super_admin;
    },
  },
  methods: {
    ...mapActions('shop', ['getFirstShop']),
  },
};
</script>
