/* eslint-disable */
export function doValidations(rules, val, error, errorMessage) {
  const internalError = { ...error };
  if (rules.length === 0) return internalError;
  internalError.status = false;

  for (const rule in rules) {
    internalError.status = !rules[rule](val);
    if (internalError.status) {
      internalError.message = errorMessage;
      return internalError;
    }
  }
  return internalError;
}
