import { createStore } from 'vuex';
import auth from './modules/auth';
import customers from './modules/customers';
import shop from './modules/shop';
import harps from './modules/harps';
import goods from './modules/goods';
import productLines from './modules/productlines';
import stringsLayouts from './modules/stringslayouts';
import harpColors from './modules/harpcolors';
import contractTemplates from './modules/contractTemplates';
import contracts from './modules/contracts';
import accessories from './modules/accessories';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    customers,
    shop,
    harps,
    goods,
    productLines,
    stringsLayouts,
    harpColors,
    contractTemplates,
    contracts,
    accessories,
  },
});
