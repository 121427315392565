<template>
  <color-banner bgStyle="bg-goods"></color-banner>
  <custom-modal
    ref="addProductLine"
    extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    container-class="w-1/2"
  >
    <template v-slot:header>
      {{ $t("product-lines.addProductLines") }}
    </template>
    <template v-slot:body>
      <add-product-line @product-line-has-been-added="onProductLineHasBeenAdded"/>
    </template>
  </custom-modal>
  <custom-modal
    ref="importProductLines"
    extra-class="bg-violet-300 text-white/90 font-medium fill-white/90"
    container-class="w-1/2"
  >
    <template v-slot:header>
      {{ $t("product-lines.importTitle") }}
    </template>
    <template v-slot:body>
      <excel-importer buttonStyle="button-goods"
        importer="ProductLines"
        @product-lines-has-been-imported="onProductLinesHasBeenImported"
      />
    </template>
  </custom-modal>
  <base-container>
    <template v-slot:buttons>
      <base-button @click="$refs.addProductLine.openModal()" :title="$t('buttons.addNew')"
        extra-class="fill-white bg-violet-200 hover:bg-transparent hover:fill-violet-400 border-violet-500
        dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600 mr-2">
        <add-icon />
      </base-button>
      <base-button @click="$refs.importProductLines.openModal()" :title="$t('buttons.importExcel')"
        extra-class="fill-white bg-violet-200 hover:bg-transparent hover:fill-violet-400 border-violet-500
        dark:bg-transparent dark:fill-violet-300 dark:hover:bg-violet-300 dark:hover:fill-slate-600">
        <import-excel />
      </base-button>
    </template>
    <template v-slot:title>
        {{ $t("product-lines.product-lines") }}
    </template>
    <template v-slot:body>
      <product-line-row v-for="line in productLines" :key="line.id" :line="line" class="mb-4"/>
    </template>
  </base-container>
</template>

<script>
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';

import ImportExcel from '@/components/graphics/ImportExcel.vue';
import AddIcon from '@/components/graphics/AddIcon.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import ProductLineRow from '../components/productlines/ProductLineRow.vue';
import CustomModal from '../components/base/CustomModal.vue';
import AddProductLine from '../components/productlines/AddProductLine.vue';
import ExcelImporter from '../components/base/ExcelImporter.vue';
import BaseContainer from '../components/base/BaseContainer.vue';
import ColorBanner from '../components/graphics/ColorBanner.vue';

export default {

  setup() {
    const toast = useToast();

    return { toast };
  },

  components: {
    CustomModal,
    ProductLineRow,
    AddProductLine,
    ExcelImporter,
    BaseContainer,
    ColorBanner,
    ImportExcel,
    BaseButton,
    AddIcon,
  },

  async created() {
    await this.getProductLines();
  },
  computed: {
    ...mapGetters('productLines', ['productLines']),
  },
  methods: {
    ...mapActions('productLines', ['getProductLines']),
    onProductLineHasBeenAdded() {
      this.toast.success(this.$t('product-lines.added'));
      this.$refs.addProductLine.closeModal();
    },
    async onProductLinesHasBeenImported() {
      await this.getProductLines();
      this.toast.success(this.$t('product-lines.imported'));
      this.$refs.importProductLines.closeModal();
    },
  },
};
</script>
