import axios from 'axios';

export default {

  namespaced: true,

  state: {
    shop: {},
  },

  getters: {
    shop: (state) => state.shop,
  },

  mutations: {
    setShop(state, shop) {
      state.shop = shop;
    },
  },

  actions: {
    async getFirstShop({ commit }) {
      const { data } = await axios.get('/api/shop/first');

      commit('setShop', data);
    },
  },
};
