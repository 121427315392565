<template>
    <div class="flex flex-col items-start justify-between py-3 px-6 h-48
    font-thin rounded-lg border w-full dark:bg-slate-900/20" :class="extraClass">
      <div class="text-lg">
        <slot name="title"></slot>
      </div>
      <div class="text-5xl font-bold">
        <slot name="number"></slot>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    extraClass: {
      type: String,
    },
  },
};
</script>
