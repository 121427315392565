<template>
  <div class="grid grid-cols-5 gap-x-3 overflow-hidden rounded-md border border-violet-500 pr-2 items-center
  font-thin dark:text-white">
    <p class="bg-violet-200 dark:bg-transparent p-3 text-violet-500 dark:text-violet-300 font-semibold
      md:w-3/4 text-center border-r border-violet-500 col-span-2">
    {{ color.slug }}
    </p>
    <p class="col-span-2">
      {{ color.color }}
    </p>
    <p class="col-span-1">
      {{ color.code_digit }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: Object,
      required: true,
    },
  },
};
</script>
