<template>
  <!-- eslint-disable -->
  <form class="flex flex-col text-gray-700 dark:text-white font-thin" @submit.prevent="saveHarp">
    <base-input
      :label="$t('harps.model')"
      v-model="editedHarp.sku"
      :disabled="true"
      class="mb-2"
    />
    <div v-for="translation in editedHarp.translations" :key="translation.id">
      <translation-input
        v-if="translation.lang === 'en'"
        name="translation-input"
        :label="$t('harps.description')"
        v-model="translation.text"
        :lang="translation.lang"
        @blur="updateLine(translation.text)"
        required="true"
        label-style="bg-violet-300"
      />
      <translation-input
        v-else
        name="translation-input"
        :label="$t('harps.description')"
        v-model="translation.text"
        :lang="translation.lang"
        label-style="bg-violet-300"
      />
    </div>
    <base-button extra-class="border-violet-400 text-violet-400 hover:bg-violet-300 self-end mt-3">
      {{ $t("harps.save") }}
    </base-button>
  </form>
</template>

<script>
import { useToast } from 'vue-toastification';
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '../base/BaseButton.vue';
import TranslationInput from '../base/TranslationInput.vue';
import BaseInput from '../base/BaseInput.vue';

export default {
  components: {
    BaseInput,
    BaseButton,
    TranslationInput,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  created() {
    this.editedHarp = (({ id, sku, translations }) => ({ id, sku, translations }))({ ...this.harp });
  },

  data() {
    return {
      editedHarp: {},
    };
  },

  computed: {
    ...mapGetters('harps', ['harp']),
  },

  methods: {
    ...mapActions('harps', ['putHarp']),

    async saveHarp() {
      this.editedHarp.translations = this.editedHarp.translations.reduce((acc, item) => { // eslint-disable-line
        (acc[item.field] = acc[item.field] || {})[item.lang] = item.text;
        return acc;
      }, {});

      await this.putHarp(this.editedHarp);
      this.toast.success(this.$t('harps.updated'));
      this.$emit('close');
    },
    updateLine(value) {
      this.editedHarp.description = value;
    },
  },
};
</script>
