<template>
  <p class="w-full text-3xl mb-4 text-center">{{$t('contracts.orderFor')}} {{ customer.firstname }} {{ customer.lastname }}</p>
  <base-wizard
    :end-button="$t('contracts.save')"
    :data-tabs="tabs"
    @close="closeWizard"
  />
</template>

<script>
import { markRaw } from 'vue';
import { mapGetters } from 'vuex';
import BaseWizard from '../base/BaseWizard.vue';
import CustomerTab from './CustomerTab.vue';
import GoodTab from './GoodTab.vue';
import TemplateTab from './TemplateTab.vue';
import SummaryTab from './SummaryTab.vue';

export default {
  components: { BaseWizard },
  emits: ['close'],
  data() {
    return {
      tabs: [
        {
          label: this.$t('contracts.customer'),
          component: markRaw(CustomerTab),
        },
        {
          label: this.$t('contracts.good'),
          component: markRaw(GoodTab),
        },
        {
          label: this.$t('contract_templates.contract_templates'),
          component: markRaw(TemplateTab),
        },
        {
          label: this.$t('contracts.summary'),
          component: markRaw(SummaryTab),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('customers', ['customer']),
  },
  methods: {
    closeWizard() {
      this.$emit('close');
    },
  },
};
</script>
