<template>
    <form
      class="flex flex-col w-full space-y-4"
      @submit.prevent="saveContractTemplate"
    >
      <div class="grid grid-cols-1 gap-2">
        <div for="translation-input" class="text-sm md:text-base">
            {{ $t("contract_templates.description") }}
          <div v-for="translation in contractTemplate.translations" :key="translation.id">
            <translation-input
              v-if="translation.lang === 'en'"
              name="translation-input"
              :label="$t('contract_templates.description')"
              v-model="translation.text"
              :lang="translation.lang"
              @blur="updateLine(translation.text)"
              required="true"
              label-style="bg-violet-300"
            />
            <translation-input
              v-else
              name="translation-input"
              :label="$t('contract_templates.description')"
              v-model="translation.text"
              :lang="translation.lang"
              label-style="bg-violet-300"
            />
          </div>
        </div>
      </div>
      <div class="grid gap-2" >
        <div class="grid grid-cols-3 items-center gap-2">
          <base-input
            :label="$t('contract_templates.rental_item_id')"
            v-model="contractTemplate.rental_item_id"
            required="true"
          />
          <base-currency-input
            :label="$t('contract_templates.fee')"
            v-model="contractTemplate.fee"
            required="true"
          />
          <base-object-select
            :label="$t('contract_templates.fee_basis')"
            :options="feeBasisOptions"
            valueField="id"
            descriptionField="description"
            v-model="contractTemplate.fee_basis"
            required="true"
          />
          <base-checkbox
            class="col-span-2"
            :label="$t('contract_templates.variable_fee')"
            v-model="contractTemplate.variable_fee"
          />
          <base-input
            v-if="contractTemplate.variable_fee"
            :label="$t('contract_templates.vary_after')"
            v-model="contractTemplate.fee_vary_after"
            :required="contractTemplate.variable_fee"
          />
          <base-currency-input
            v-if="contractTemplate.variable_fee"
            :label="$t('contract_templates.next_fee')"
            v-model="contractTemplate.next_fee"
            :required="contractTemplate.variable_fee"
          />
        </div>
        <div class="grid grid-cols-2 gap-2 items-center">
          <base-currency-input
            :label="$t('contract_templates.shipping_costs')"
            v-model.number="contractTemplate.shipping_costs"
            required="true"
          />
          <base-currency-input
            :label="$t('contract_templates.deposit')"
            v-model="contractTemplate.deposit"
            required="true"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <h2 class="text-xl font-bold tracking-wide col-span-2">
          {{ $t('contract_templates.rule_definition') }}
        </h2>
          <base-object-select
            :label="$t('contract_templates.good_group')"
            :options="goodGropupOptions"
            valueField="id"
            descriptionField="description"
            v-model="ruleGroup"
            required="true"
          />
          <base-object-select
            :label="$t('contract_templates.select_harp')"
            :options="harpsOptions"
            valueField="id"
            descriptionField="description"
            v-if="ruleGroup === 'model'"
            v-model="modelRule"
            required="true"
          />
          <base-object-select
            :label="$t('contract_templates.product_line')"
            :options="productLineOptions"
            valueField="id"
            descriptionField="description"
            v-if="ruleGroup === 'line'"
            v-model="lineRule"
            required="true"
          />
          <base-object-select
            :label="$t('contract_templates.rule_good_status')"
            valueField="id"
            descriptionField="description"
            :options="goodStatusOptions"
            v-model="contractTemplate.rule_good_status"
            required="true"
          />
          <base-input
            :label="$t('contract_templates.duration')"
            v-model.number="contractTemplate.duration"
            required="true"
          />
        </div>
        <div class="grid grid-cols-2 gap-2">
          <h2 class="text-xl font-bold tracking-wide col-span-2">
            {{ $t('contract_templates.accessories') }}
          </h2>
          <base-select
            :label="$t('contract_templates.select_accessories')"
            valueField="id"
            :options="accessoriesList"
            descriptionField="description"
            multiOptions
            class="capitalize"
            v-model="selectedAccessories"
          />
          <base-input
            :label="$t('contract_templates.stripe_product_id')"
            v-model.number="contractTemplate.stripe_product_id"
            required="true"
          />
        </div>
        <base-button extra-class="border-emerald-400 hover:bg-emerald-300 text-emerald-400 self-end
        disabled:border-gray-300 disabled:text-gray-300 disabled:bg-gray-100 dark:disabled:bg-slate-500"
        :disabled="isButtonDisabled">
          {{ $t('contract_templates.save-btn') }}
        </base-button>
    </form>
</template>

<script>
import { useToast } from 'vue-toastification';
import { mapGetters, mapActions } from 'vuex';

import BaseInput from '../base/BaseInput.vue';
import BaseSelect from '../base/BaseSelect.vue';
import BaseObjectSelect from '../base/BaseObjectSelect.vue';
import BaseCurrencyInput from '../base/BaseCurrencyInput.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';
import BaseButton from '../base/BaseButton.vue';
import TranslationInput from '../base/TranslationInput.vue';

export default {
  props: {
    template: {
      type: Object,
    },
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseObjectSelect,
    BaseCurrencyInput,
    BaseButton,
    BaseCheckbox,
    TranslationInput,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      contractTemplate: null,
      ruleTarget: 'line',
      ruleTargetValue: '',
      ruleGroup: 'line',
      // familyRule: 'L',
      lineRule: '',
      modelRule: '',
      feeBasisOptions: [
        { id: 'daily', description: this.$t('contract_templates.daily') },
        { id: 'monthly', description: this.$t('contract_templates.monthly') },
        { id: 'quarterly', description: this.$t('contract_templates.quarterly') },
      ],
      goodStatusOptions: [
        { id: 'all', description: this.$t('contract_templates.good_status_all') },
        { id: 'new', description: this.$t('contract_templates.good_status_new') },
        { id: 'used', description: this.$t('contract_templates.good_status_used') },
      ],
      goodGropupOptions: [
        { id: 'line', description: this.$t('contract_templates.good_group_line') },
        { id: 'model', description: this.$t('contract_templates.good_group_model') },
      ],
      selectedAccessories: [],
      accessoriesList: [],
    };
  },
  async created() {
    this.contractTemplate = this.template;
    this.ruleGroup = this.contractTemplate.harps.length === 1 ? 'model' : 'line';
    this.lineRule = this.ruleGroup === 'line' ? this.contractTemplate.harps[0].product_line_id : '';
    this.modelRule = this.ruleGroup === 'model' ? this.contractTemplate.harps[0].id : '';
    this.selectedAccessories = this.contractTemplate.accessories.map(({ id, description }) => ({ id, description }));
    await this.getAccessories();
    this.accessoriesList = this.accessories.map(({ id, description }) => ({ id, description }));
  },
  computed: {
    ...mapGetters('harps', ['harps']),
    ...mapGetters('productLines', ['productLines']),
    ...mapGetters('accessories', ['accessories']),
    productLineOptions() {
      return this.productLines.map((line) => ({ id: line.id, description: line.line }));
    },
    harpsOptions() {
      return this.harps.map((harp) => ({ id: harp.id, description: harp.description }));
    },
    isButtonDisabled() {
      return this.contractTemplate.variable_fee === true && (this.contractTemplate.next_fee === 0 || this.contractTemplate.next_fee === null);
    },
  },
  watch: {
    ruleGroup: {
      deep: true,
      handler() {
        this.ruleTarget = this.ruleGroup;
        switch (this.ruleGroup) {
          case 'line':
            this.ruleTargetValue = this.lineRule;
            break;
          case 'model':
            this.ruleTargetValue = this.modelRule;
            break;
          default:
        }
      },
    },
    lineRule: {
      handler() {
        this.ruleTargetValue = this.lineRule;
      },
    },
    modelRule: {
      handler() {
        this.ruleTargetValue = this.modelRule;
      },
    },
  },
  methods: {
    ...mapActions('contractTemplates', ['putContractTemplate']),
    ...mapActions('accessories', ['getAccessories']),
    async saveContractTemplate() {
      this.contractTemplate.translations = this.contractTemplate.translations.reduce((acc, item) => { // eslint-disable-line
        (acc[item.field] = acc[item.field] || {})[item.lang] = item.text;
        return acc;
      }, {});
      const { harps, ...payload } = this.contractTemplate;
      const data = await this.putContractTemplate({
        ...payload,
        rule_target: this.ruleTarget,
        rule_target_value: this.ruleTargetValue,
        accessories: this.selectedAccessories.map((accessory) => accessory.id),
      });
      this.$emit('templateHasBeenUpdated', data);
      this.toast.success(this.$t('contract_templates.updated'));
    },
    updateLine(value) {
      this.contractTemplate.description = value;
    },
  },
};
</script>

<style>
.focus-input:focus {
  @apply outline outline-2 outline-emerald-300/50
}
.focus-input:active {
  @apply outline outline-2 outline-emerald-300/50
}
</style>
