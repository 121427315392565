<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="text-base relative inline-block">
    <label>
      {{ label }}<span v-if="this.$attrs.required" class="text-red-400"> *</span>
    </label>

    <div class="absolute w-full" v-if="multiOptions">
      <div class="flex items-center" @click="openClose" @keydown="openClose">
        <input type="text" :value="selectedItems"
        class="border border-gray-400 rounded-md p-2 md:px-4 w-full appearance-none bg-transparent capitalize dark:border-gray-100"/>
        <div class="absolute right-3 cursor-pointer"
        :class="openList ? 'rotate-180 duration-500 origin-center' : 'rotate-0 duration-500 origin-center'">
          <dropdown-arrow class="w-3 md:w-5" />
        </div>
      </div>
      <ul :class="openList ? 'flex flex-col' : 'hidden'"
      class="rounded overflow-hidden bg-stone-200 border border-stone-300 shadow-select max-h-32 overflow-y-scroll">
        <label v-for="(option, index) in options" :key="index" :id="option"
        class="flex items-center px-3 py-2 hover:bg-stone-300 cursor-pointer dark:bg-slate-700 dark:hover:bg-emerald-500">
          <input
            v-model="selectedOptions"
            type="checkbox"
            :value="option"
            @change="updateList()"
            class="flex items-center mr-2 appearance-none border border-gray-600 dark:border-white rounded-[0.15rem] w-4 h-4
            checked:outline-none checked:after:content-['\2713'] text-emerald-500 checked:border-emerald-500"
          />
            {{ option[descriptionField] }}
        </label>
      </ul>
    </div>

    <div class="relative flex items-center" v-else>
      <select
        v-bind="{
          ...$attrs,
          onChange: ($event) => {
            $emit('update:modelValue', $event.target.value);
            $emit('item-selected');
          },
        }"
        class="border z-0 rounded-md p-2 md:px-4 w-full appearance-none cursor-pointer capitalize bg-transparent"
        :value="modelValue"
        :class="selectNotAvailable ? 'cursor-not-allowed bg-slate-200 dark:bg-slate-500 border-transparent' : 'border-gray-400 dark:border-gray-100 bg-transparent'"
        :disabled="selectNotAvailable"
      >
        <option
          class="font-sans-serif font-normal capitalize"
          v-for="option in options"
          :key="option"
          :value="option"
          @click="$emit('save-function')"
        >
          {{ option }}
        </option>
      </select>
      <button class="absolute z-50 right-0 pr-3"
        @click="$emit('edit-function')" v-if="visibility">
        <edit-icon class="w-2 md:w-4" />
      </button>
      <div class="absolute right-0 pointer-events-none pr-3" v-else>
        <dropdown-arrow class="w-3 md:w-5" />
      </div>
    </div>
  </div>
</template>

<script>
import DropdownArrow from '../graphics/DropdownArrow.vue';
import EditIcon from '../graphics/EditIcon.vue';

export default {
  data() {
    return {
      openList: false,
      selectedOptions: [],
    };
  },
  emits: ['edit-function', 'save-function', 'update:modelValue', 'item-selected'],
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      required: true,
    },
    multiOptions: {
      type: Boolean,
    },
    descriptionField: {
      type: String,
      default: '',
    },
    visibility: {
      type: Boolean,
    },
    selectNotAvailable: {
      type: Boolean,
    },
  },
  components: {
    DropdownArrow,
    EditIcon,
  },
  mounted() {
    if (Array.isArray(this.modelValue) && this.modelValue.length > 0) {
      this.selectedOptions = this.modelValue;
      this.$emit('update:modelValue', this.selectedOptions);
    }
  },
  computed: {
    selectedItems() {
      return this.selectedOptions.map((item) => item.description).join(', ');
    },
  },
  methods: {
    openClose() {
      this.openList = !this.openList;
    },
    updateList() {
      this.$emit('update:modelValue', this.selectedOptions);
    },
  },
};
</script>

<style>
 input[type="checkbox"]:not(:checked) {
  outline: 0;
 }
 .shadow-select {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3) ;
 }
</style>
