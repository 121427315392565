<template>
  <button
    class="px-3 py-1 border rounded-md uppercase w-fit font-medium hover:text-white dark:hover:text-slate-600"
    v-bind="$attrs" :class="extraClass"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  props: {
    extraClass: {
      type: String,
      default: 'hover:bg-gray-500',
    },
  },
};
</script>
