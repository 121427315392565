<template>
  <!-- eslint-disable -->
  <form class="flex flex-col text-gray-700 dark:text-white font-thin" @submit.prevent="saveAccessory">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-4 gap-y-1">
      <base-input :label="$t('accessories.itemCode')" v-model="editedAccessory.item_code" required="true" />
      <base-currency-input :label="$t('accessories.price')" v-model="editedAccessory.price" required="true" />
    </div>
    <div for="translation-input" class="text-sm md:text-base">
      {{ $t("accessories.description") }}
    </div>
    <translation-input
      name="translation-input"
      :label="$t('accessories.description')"
      v-model="editedAccessory.translations.description.en"
      lang="en"
      @blur="updateLine"
      required="true"
      label-style="bg-violet-300"
    />
    <translation-input
      :label="$t('product-lines.line')"
      v-model="editedAccessory.translations.description.it"
      lang="it"
      required="true"
      label-style="bg-violet-300"
    />
    <base-button extra-class="border-violet-400 text-violet-400 hover:bg-violet-300 self-end mt-3">
      {{ $t("accessories.save") }}
    </base-button>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
import BaseInput from '../base/BaseInput.vue';
import TranslationInput from '../base/TranslationInput.vue';
import BaseCurrencyInput from '../base/BaseCurrencyInput.vue';
import BaseButton from '../base/BaseButton.vue';

export default {
  components: {
    BaseInput,
    BaseCurrencyInput,
    BaseButton,
    TranslationInput,
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  data() {
    return {
      editedAccessory: {},
    };
  },

  created() {
    this.editedAccessory = { ...this.accessory };
    const reducedTranslations = this.editedAccessory.translations.reduce((acc, item) => ({ ...acc, [item.lang]: item.text }), {});
    this.editedAccessory.translations = { description: { ...reducedTranslations } };
  },

  computed: {
    ...mapGetters('accessories', ['accessory']),
  },

  methods: {
    ...mapActions('accessories', ['getAccessories', 'putAccessory']),
    async saveAccessory() {
      await this.putAccessory(this.editedAccessory);
      await this.getAccessories();

      this.toast.success(this.$t('accessories.accessory_updated'));
      this.$emit('close');
    },
    updateLine() {
      this.editedAccessory.description = this.editedAccessory.translations.description.en;
    },
  },
};
</script>
