<template>
<label :for="nameToKebab" :class="labelStyle">
    <span>{{ label }} <span v-if="this.$attrs.required" class="text-red-400">*</span></span>
    <div class="relative flex items-center">
      <input
        autocomplete="off"
        v-bind="{
          ...$attrs,
          onInput: ($event) => {
            validate(this.rules, $event.target.value, this.error);
            emitValue($event);
          },
          onBlur: ($event) => {
            $emit('blur');
          },
          onChange: ($event) => {
            $emit('change');
          },
          onKeyUp: ($event) => {
            $emit('keyup');
          },
        }"
        class="rounded-md p-2 w-full"
        :id="id ? id : nameToKebab"
        :name="nameToKebab"
        :class="[inputStyles, darkText]"
        :value="modelValue && isDate ? `${$d(modelValue, 'short')}` || '' : modelValue"
      />
      <div class="absolute right-0 cursor-pointer pr-3">
        <save-icon v-if="icon && isEdit" @click="isEdit = !isEdit; $emit('save-event')" class="w-4" />
        <edit-icon v-if="icon && !isEdit" @click="isEdit = !isEdit; $emit('edit-event')" class="w-4" />
        <search-icon v-if="searchIcon" @click="$emit('search-event')" class="w-4" />
      </div>
    </div>
    <p v-if="error.status" class="error">{{ error.message }}</p>
  </label>
</template>

<script>
import { doValidations } from '../../utils/validate';
import EditIcon from '../graphics/EditIcon.vue';
import SaveIcon from '../graphics/SaveIcon.vue';
import SearchIcon from '../graphics/SearchIcon.vue';

export default {
  components: {
    SaveIcon,
    EditIcon,
    SearchIcon,
  },
  data() {
    return {
      isEdit: false,
      modifiers: {
        uppercase: (val) => val.toUpperCase(),
        lowercase: (val) => val.toLowerCase(),
        capitalize: (val) => val.charAt(0).toUpperCase() + val.slice(1),
      },
      error: {
        status: false,
        message: '',
      },
    };
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    labelStyle: {
      type: String,
      default: 'grid text-sm md:text-base',
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    modelModifiers: {
      default: () => ({}),
    },
    disabledClasses: {
      type: String,
      default: 'cursor-not-allowed bg-slate-200 dark:bg-slate-500 border border-transparent',
    },
    backgroundClass: {
      type: String,
      default: 'bg-transparent border border-gray-400 dark:border-gray-100',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    isDate: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputStyles() {
      return `${this.$attrs.disabled ? this.disabledClasses : this.backgroundClass}`;
    },
    nameToKebab() {
      return this.toKebab(this.label);
    },
    messageToShow() {
      if (this.message) {
        return this.message;
      }
      return this.$t('input.fillField');
    },
    darkText() {
      if (localStorage.getItem('theme') === 'dark') {
        return 'testo-safari-dark';
      }
      return 'testo-safari';
    },
  },
  methods: {
    toKebab(value) {
      return value.split(' ').join('-').toLowerCase();
    },
    validate(rules, val, error) {
      this.error = doValidations(rules, val, error, this.errorMessage);
    },
    emitValue(evt) {
      let { value } = evt.target;
      // eslint-disable-next-line
      for (const modifier of Object.keys(this.modelModifiers)) {
        if (this.modifiers[modifier]) {
          value = this.modifiers[modifier](value);
        }
      }
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style>
.testo-safari {
  -webkit-text-fill-color: black;
  opacity: 1;
}

.testo-safari-dark {
  -webkit-text-fill-color: white;
  opacity: 1
}

.error {
  color: red;
  font-size: smaller;
}

</style>
