<template>
  <ais-menu-select
    :attribute="attribute"
  >
    <template v-slot="{ items, canRefine, refine }">
      <div class="relative flex items-center">
        <label for="harp_description" class="w-full">
          <select
            name="harp_description"
            class="text-sm font-thin border z-0 rounded-md p-2 md:px-4 w-full appearance-none cursor-pointer capitalize bg-transparent border-gray-400 dark:border-gray-100"
            :disabled="!canRefine"
            @change="refine($event.currentTarget.value)"
          >
            <option value="">{{initialLabel}}</option>
            <option
              v-for="item in items"
              :key="item.value"
              :value="item.value"
              :selected="item.isRefined"
            >
              {{ item.label }}
            </option>
          </select>
        </label>
        <div class="absolute right-0 pointer-events-none pr-3">
          <dropdown-arrow class="w-3 md:w-5" />
        </div>
      </div>
    </template>
  </ais-menu-select>
</template>

<script>
import DropdownArrow from '../graphics/DropdownArrow.vue';

export default {
  components: {
    DropdownArrow,
  },
  props: {
    attribute: {
      type: String,
      required: true,
    },
    initialLabel: {
      type: String,
      default: 'All',
    },
  },
};
</script>

<style>
select option {
  text-transform: capitalize
}
</style>
