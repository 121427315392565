<template>
  <div class="relative flex items-center mb-2">
    <div
      class="dark:text-slate-600 text-white text-xs font-bold rounded uppercase mr-1 h-8 w-9 flex justify-center items-center"
      v-text="lang" :class="labelStyle"
    ></div>
    <input
      v-bind="{
        ...$attrs,
        onInput: ($event) => {
          $emit('update:modelValue', $event.target.value);
        },
        onBlur: ($event) => {
          $emit('blur');
        },
        onChange: ($event) => {
          $emit('change');
        },
      }"
      :class="inputStyles"
      :value="modelValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: 'en',
    },
    labelStyle: {
      type: String,
      default: 'grid text-sm md:text-base',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    inputClasses: {
      type: String,
      default: 'w-full rounded-md p-2 text-sm md:text-base',
    },
    disabledClasses: {
      type: String,
      default: 'cursor-not-allowed bg-slate-200 dark:bg-slate-500 border border-transparent',
    },
    backgroundClass: {
      type: String,
      default: 'border border-gray-400 dark:border-gray-100 bg-transparent',
    },
  },

  computed: {
    inputStyles() {
      return `${this.inputClasses} ${this.$attrs.disabled ? this.disabledClasses : this.backgroundClass}`;
    },
  },
};
</script>
