<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="67" height="67" viewBox="0 0 67 67">
    <defs>
      <clipPath id="clip-path">
        <circle id="Ellipse_9" data-name="Ellipse 9" cx="33.5" cy="33.5" r="33.5" transform="translate(4031 1264)" fill="currentColor"/>
      </clipPath>
    </defs>
    <g id="Mask_Group_48" data-name="Mask Group 48" transform="translate(-4031 -1264)" clip-path="url(#clip-path)">
      <g id="Group_30" data-name="Group 30" transform="translate(4038.37 1270.37)">
        <g id="Group_29" data-name="Group 29" transform="translate(0 0)">
          <circle id="Ellipse_4" data-name="Ellipse 4" cx="12.5" cy="12.5" r="12.5" transform="translate(13.63 2.629)" fill="currentColor"/>
          <rect id="Rectangle_112" data-name="Rectangle 112" width="45" height="38" rx="14" transform="translate(3.63 29.63)" fill="currentColor"/>
        </g>
      </g>
    </g>
  </svg>
</template>
