<template>
  <div class="flex flex-col font-thin">
    <div class="flex font-semibold space-x-1">
      <icon-button
        :title="$t('buttons.edit')"
        @click="$emit('edit', accessory)"
        extra-class="bg-violet-200 border-violet-500 hover:text-violet-300
        dark:text-violet-300 dark:hover:bg-violet-300 dark:bg-transparent">
        <edit-icon class="w-3 md:w-4 py-1" />
      </icon-button>
    </div>
    <div class="grid grid-rows-6 md:grid-rows-1 md:grid-cols-11 overflow-hidden rounded-md rounded-tl-none
          border border-violet-500 md:pr-2 md:p-0 pb-2">
      <div class="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 md:col-span-10
          row-span-5 items-center text-center dark:text-white">
        <p class="bg-violet-200 dark:bg-transparent p-3 text-violet-500 dark:text-violet-300 font-semibold
            md:w-3/4 text-center border-b md:border-0 md:border-r border-violet-500">
          {{ accessory.item_code }}
        </p>
        <p>
          {{ accessory.description }}
        </p>
        <p>
          {{ $n(accessory.price / 100, 'currency') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import EditIcon from '../graphics/EditIcon.vue';
import IconButton from '../base/IconButton.vue';

export default {
  components: {
    EditIcon,
    IconButton,
  },
  props: {
    accessory: {
      type: Object,
      required: true,
    },
  },
};
</script>
