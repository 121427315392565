<template>
  <form
    class="flex flex-col w-full space-y-4"
    @submit.prevent="saveContractTemplate"
  >
      <div class="grid grid-cols-1 gap-2">
        <div for="translation-input" class="text-sm md:text-base">
          {{ $t("contract_templates.description") }}
          <translation-input
            name="translation-input"
            :label="$t('contract_templates.description')"
            v-model="contractTemplate.translations.description.en"
            lang="en"
            @blur="updateLine"
            required="true"
            label-style="bg-violet-300"
          />
          <translation-input
            :label="$t('contract_templates.description')"
            v-model="contractTemplate.translations.description.it"
            lang="it"
            required="true"
            label-style="bg-violet-300"
          />
        </div>
      </div>
      <div class="grid gap-2" >
        <div class="grid grid-cols-3 items-center gap-2">
          <base-input
            :label="$t('contract_templates.rental_item_id')"
            required="true"
            v-model="contractTemplate.rental_item_id"
          />
          <base-currency-input
            :label="$t('contract_templates.fee')"
            v-model="contractTemplate.fee"
            required="true"
          />
          <base-object-select
            :label="$t('contract_templates.fee_basis')"
            :options="feeBasisOptions"
            valueField="id"
            descriptionField="description"
            v-model="contractTemplate.fee_basis"
            required="true"
          />
          <base-checkbox
            class="col-span-2"
            :label="$t('contract_templates.variable_fee')"
            v-model="contractTemplate.variable_fee"
          />
          <base-input
            v-if="contractTemplate.variable_fee"
            :label="$t('contract_templates.vary_after')"
            v-model="contractTemplate.fee_vary_after"
            :required="contractTemplate.variable_fee"
          />
          <base-currency-input
            v-if="contractTemplate.variable_fee"
            :label="$t('contract_templates.next_fee')"
            v-model="contractTemplate.next_fee"
            :required="contractTemplate.variable_fee"
          />
        </div>
        <div class="grid grid-cols-2 gap-2 items-center">
          <base-currency-input
            :label="$t('contract_templates.shipping_costs')"
            v-model.number="contractTemplate.shipping_costs"
            required="true"
          />
          <base-currency-input
            :label="$t('contract_templates.deposit')"
            v-model="contractTemplate.deposit"
            required="true"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <h2 class="text-xl font-bold tracking-wide col-span-2">
          {{ $t('contract_templates.rule_definition') }}
        </h2>
            <base-object-select
              :label="$t('contract_templates.good_group')"
              :options="goodGropupOptions"
              valueField="id"
              descriptionField="description"
              v-model="ruleGroup"
              required="true"
            />
            <base-object-select
              :label="$t('contract_templates.select_harp')"
              :options="harpsOptions"
              valueField="id"
              descriptionField="description"
              v-if="ruleGroup === 'model'"
              v-model="modelRule"
              required="true"
            />
            <base-object-select
              :label="$t('contract_templates.product_line')"
              :options="productLineOptions"
              valueField="id"
              descriptionField="description"
              v-if="ruleGroup === 'line'"
              v-model="lineRule"
              required="true"
            />
            <base-object-select
              :label="$t('contract_templates.good_status')"
              :options="goodStatusOptions"
              valueField="id"
              descriptionField="description"
              v-model="contractTemplate.rule_good_status"
              required="true"
            />
            <base-input
              :label="$t('contract_templates.duration')"
              v-model.number="contractTemplate.duration"
              required="true"
            />
      </div>
      <div class="grid grid-cols-2 gap-2">
        <h2 class="text-xl font-bold tracking-wide col-span-2">
          {{ $t('contract_templates.accessories') }}
        </h2>
        <base-select
          :label="$t('contract_templates.select_accessories')"
          :options="accessoriesList"
          descriptionField="description"
          multiOptions
          class="capitalize"
          v-model="selectedAccessories"
        />
        <base-input
          :label="$t('contract_templates.stripe_product_id')"
          v-model.trim="contractTemplate.stripe_product_id"
          required="true"
        />
      </div>
      <base-button
      extra-class="border-emerald-400 hover:bg-emerald-300 text-emerald-400 self-end
      disabled:border-gray-300 disabled:text-gray-300 disabled:bg-gray-100 dark:disabled:bg-slate-500"
      :disabled="isButtonDisabled">
        {{ $t('contract_templates.save-btn') }}
      </base-button>
    </form>
</template>

<script>
import { useToast } from 'vue-toastification';

import { mapGetters, mapActions } from 'vuex';

import BaseInput from '../base/BaseInput.vue';
import BaseSelect from '../base/BaseSelect.vue';
import BaseObjectSelect from '../base/BaseObjectSelect.vue';
import BaseCurrencyInput from '../base/BaseCurrencyInput.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';
import BaseButton from '../base/BaseButton.vue';
import TranslationInput from '../base/TranslationInput.vue';

export default {
  components: {
    BaseInput,
    BaseSelect,
    BaseObjectSelect,
    BaseCurrencyInput,
    BaseCheckbox,
    BaseButton,
    TranslationInput,
  },

  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      ruleTarget: 'line',
      ruleTargetValue: '',
      ruleGroup: 'line',
      lineRule: '',
      modelRule: '',
      contractTemplate: {
        slug: '',
        description: '',
        fee: 0,
        fee_basis: 'quarterly',
        variable_fee: false,
        fee_vary_after: 12,
        next_fee: 0,
        shipping_costs: 0,
        deposit: 0,
        rule_good_status: 'all',
        duration: 18,
        rental_item_id: null,
        stripe_product_id: null,
        translations: {
          description: {
            en: '',
            it: '',
          },
        },
      },
      feeBasisOptions: [
        { id: 'daily', description: this.$t('contract_templates.daily') },
        { id: 'monthly', description: this.$t('contract_templates.monthly') },
        { id: 'quarterly', description: this.$t('contract_templates.quarterly') },
      ],
      goodStatusOptions: [
        { id: 'all', description: this.$t('contract_templates.good_status_all') },
        { id: 'new', description: this.$t('contract_templates.good_status_new') },
        { id: 'used', description: this.$t('contract_templates.good_status_used') },
      ],
      goodGropupOptions: [
        { id: 'line', description: this.$t('contract_templates.good_group_line') },
        { id: 'model', description: this.$t('contract_templates.good_group_model') },
      ],
      selectedAccessories: [],
      accessoriesList: [],
    };
  },
  async created() {
    await this.getAccessories();
    this.accessoriesList = this.accessories.map(({ id, description }) => ({
      id,
      description,
    }));
  },
  watch: {
    contractTemplate: {
      deep: true,
      handler() {
        if (this.contractTemplate.variable_fee && this.contractTemplate.next_fee === 0) {
          this.contractTemplate.next_fee = this.contractTemplate.fee;
        }
      },
    },
    ruleGroup: {
      deep: true,
      handler() {
        this.ruleTarget = this.ruleGroup;
        switch (this.ruleGroup) {
          case this.$t('contract_templates.good_group_line'):
            this.ruleTargetValue = this.lineRule;
            break;
          case this.$t('contract_templates.good_group_model'):
            this.ruleTargetValue = this.modelRule;
            break;
          default:
        }
      },
    },
    lineRule: {
      handler() {
        this.ruleTargetValue = this.lineRule;
      },
    },
    modelRule: {
      handler() {
        this.ruleTargetValue = this.modelRule;
      },
    },
  },
  computed: {
    ...mapGetters('harps', ['harps']),
    ...mapGetters('productLines', ['productLines']),
    ...mapGetters('accessories', ['accessories']),
    productLineOptions() {
      return this.productLines.map((line) => ({
        id: line.id,
        description: line.line,
      }));
    },
    harpsOptions() {
      return this.harps.map((harp) => ({
        id: harp.id,
        description: harp.description,
      }));
    },
    isButtonDisabled() {
      return this.contractTemplate.variable_fee === true && (this.contractTemplate.next_fee === 0 || this.contractTemplate.next_fee === null);
    },
  },
  methods: {
    ...mapActions('contractTemplates', ['postContractTemplate']),
    ...mapActions('accessories', ['getAccessories']),

    async saveContractTemplate() {
      this.contractTemplate.slug = this.contractTemplate.description;
      const statusOptions = [
        { id: 'all', label: this.$t('contract_templates.good_status_all') },
        { id: 'new', label: this.$t('contract_templates.good_status_new') },
        { id: 'used', label: this.$t('contract_templates.good_status_used') },
      ];
      this.contractTemplate.rule_good_status = statusOptions.filter((option) => option.id === this.contractTemplate.rule_good_status)[0].id;
      const ruleOptions = [
        { id: 'line', label: this.$t('contract_templates.good_group_line') },
        { id: 'model', label: this.$t('contract_templates.good_group_model') },
      ];
      const data = await this.postContractTemplate({
        ...this.contractTemplate,
        rule_target: ruleOptions.filter((rule) => rule.id === this.ruleTarget)[0].id,
        rule_target_value: this.ruleTargetValue,
        accessories: this.selectedAccessories.map((accessory) => accessory.id),
      });
      this.$emit('templateHasBeenCreated', data);
      this.toast.success(this.$t('contract_templates.created'));
    },
    updateLine() {
      this.contractTemplate.description = this.contractTemplate.translations.description.en;
    },
  },
};
</script>

<style>
.focus-input:focus {
  @apply outline outline-2 outline-emerald-300/50
}
.focus-input:active {
  @apply outline outline-2 outline-emerald-300/50
}
</style>
