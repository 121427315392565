import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import DashboardView from '../views/DashboardView.vue';
import CustomersView from '../views/CustomersView.vue';
import HarpsView from '../views/HarpsView.vue';
import GoodsView from '../views/GoodsView.vue';
import ProductLinesView from '../views/ProductLinesView.vue';
import StringsLayoutsView from '../views/StringsLayoutsView.vue';
import HarpColorsView from '../views/HarpColorsView.vue';
import ContractTemplatesView from '../views/ContractTemplatesView.vue';
import ContractsView from '../views/ContractsView.vue';
import AccessoriesView from '../views/AccessoriesView.vue';
import UserView from '../views/UserView.vue';
import CustomerPrivateDashboard from '../views/CustomerPrivateDashboard.vue';
import store from '../store';

const isAuthorized = (to, from) => { //eslint-disable-line
  if (store.getters['auth/user'].is_super_admin) return true;
  if (store.getters['auth/user'].roles.some((item) => item.name === 'backoffice')) {
    return true;
  }
  store.dispatch('auth/signOut');
  return { name: 'home' };
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/customers',
    name: 'customers',
    component: CustomersView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/goods',
    name: 'goods',
    component: GoodsView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/harps',
    name: 'harps',
    component: HarpsView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/product-lines',
    name: 'productLines',
    component: ProductLinesView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/strings-layouts',
    name: 'stringsLayouts',
    component: StringsLayoutsView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/harp-colors',
    name: 'harpColors',
    component: HarpColorsView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: ContractsView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/contract-templates',
    name: 'contractTemplates',
    component: ContractTemplatesView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/accessories',
    name: 'accessories',
    component: AccessoriesView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/user',
    name: 'user',
    component: UserView,
    meta: { requiresAuth: true },
    beforeEnter: (to, from) => isAuthorized(to, from),
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/generate-contract/:hash',
    name: 'generate-contract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "generate-contract" */ '../views/GenerateContract.vue'),
  },
  {
    path: '/customer-payment-data/:hash',
    name: 'customer-payment-data',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "customer-payment-data" */ '../views/CollectCustomerPaymentData.vue'),
  },
  {
    path: '/customer-payment-data/completed/:lang',
    name: 'customer-payment-data-completed',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "customer-payment-data-completed" */ '../views/CustomerPaymentDataCollected.vue'),
  },
  {
    path: '/customer-dashboard',
    name: 'customer-private-dashboard',
    meta: { requiresAuth: true },
    component: CustomerPrivateDashboard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => { //eslint-disable-line
  if (to.meta.requiresAuth) {
    if (!store.getters['auth/authenticated']) {
      await store.dispatch('auth/auth');
    }
    const authorized = to.matched.some(async (record) => {
      // for route different from login/home page check if the user is logged in
      if (!record.name || record.name === 'home') return false; // go to login page

      // check if not already authenticated
      if (!store.getters['auth/authenticated']) {
        return { name: 'home' }; // go to login page
      }

      return true;
    });

    if (authorized) {
      return true;
    }

    return false;
  }
});

export default router;
