<template>
  <div class="w-full flex justify-around z-0 h-28 lg:h-60 shadow-inside overflow-x-hidden mb-8" :class="bgStyle">
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
    <div class="w-4 skew-x-[25deg] lg:skew-x-[30deg] shadow-banner"></div>
  </div>
</template>

<script>
export default {
  props: {
    bgStyle: {
      type: String,
      default: () => 'bg-default',
    },
  },
};
</script>

<style>
  .shadow-banner {
    box-shadow: 5px 0 5px 0 rgba(31, 31, 31, 0.2);
    z-index: 10;
  }

  .shadow-inside {
    box-shadow: inset 0 0 10px 0 rgba(31, 31, 31, 0.2);
  }

  .bg-default {
    @apply bg-gray-500
  }

  .bg-customer {
    @apply bg-blue-400
  }

  .bg-contracts {
    @apply bg-emerald-300
  }

  .bg-goods {
    @apply bg-violet-300
  }
</style>
