<template>
  <form class="flex flex-col w-full text-gray-700 space-y-5 text-sm xl:test-base dark:text-white"
  @submit.prevent="$emit('save-address', editedAddress)">
    <div class="grid grid-cols-2 gap-y-1 gap-x-3 xl:gap-x-4">
      <base-input
        :label="$t('customers.company')"
        v-model="editedAddress.company"
        :required="true"
        class="col-span-2"
      />
      <base-input
        :label="$t('customers.firstname')"
        v-model="editedAddress.firstname"
        :required="!editedAddress.company"
        class="capitalize"
      />
      <base-input
        :label="$t('customers.lastname')"
        v-model="editedAddress.lastname"
        @blur="companyName"
        :required="!editedAddress.company"
        class="capitalize"
      />
      <select-country
        v-model="editedAddress.country"
        required="true"
      />
      <base-input
        :label="$t('customers.address')"
        v-model="editedAddress.address"
        :required="true"
      />
      <base-input
        :label="$t('customers.co')"
        v-model="editedAddress.co"
      />
      <base-input
        :label="$t('customers.postcode')"
        v-model="editedAddress.postcode"
        :required="true"
        :disabled="!editedAddress.country.length"
        :maxLength="italianLimitPostcode()"
      />
      <base-input
        :label="$t('customers.city')"
        v-model="editedAddress.city"
        :required="true"
        class="capitalize"
      />
      <base-input :label="$t('customers.state')"
        v-model="editedAddress.state"
        :required="true"
        :disabled="!editedAddress.country.length"
        :maxLength="italianLimitState()"
        :class="editedAddress.country === 'IT' ? 'uppercase' : 'normal-case'"
        label-style="capitalize grid text-sm md:text-base"
      />
      <base-input :label="$t('customers.phone')"
        v-model="editedAddress.phone"
        @keypress="checkNumericValue($event)"
        required="true"
      />
      <base-checkbox
        :label="$t('customers.defaultAddress')"
        v-model="editedAddress.defaultAddress"
        extra-class="text-blue-400 checked:border-blue-400"
      />
      <label for="note" class="flex flex-col text-sm md:text-base">
        {{ $t("customers.note") }}
        <textarea id="note" v-model="editedAddress.note"
        class="bg-transparent rounded-md p-2 border border-gray-400 dark:border-gray-100" />
      </label>
    </div>
    <div class="flex w-full justify-end">
      <base-button extra-class="border-blue-400 text-blue-400 hover:bg-blue-300">
        {{ $t("customers.save") }}
      </base-button>
    </div>
  </form>
</template>

<script>
/* eslint-disable camelcase, no-useless-rename */
// import { mapActions, mapGetters } from 'vuex';
import BaseInput from '../base/BaseInput.vue';
import SelectCountry from '../base/SelectCountry.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';
import BaseButton from '../base/BaseButton.vue';

export default {
  components: {
    BaseInput,
    SelectCountry,
    BaseCheckbox,
    BaseButton,
  },

  props: {
    address: {
      type: Object,
      required: true,
    },
    customerType: {
      type: String,
      default: '',
    },
  },

  emits: ['save-address'],

  data() {
    return {
      editedAddress: {
        address: '',
        postcode: '',
        city: '',
        state: '',
        country: '',
        note: '',
        defaultAddress: false,
      },
    };
  },

  created() {
    this.editedAddress = this.address;
    this.editedAddress.firstname = this.editedAddress.firstname ? this.editedAddress.firstname : '';
    this.editedAddress.lastname = this.editedAddress.lastname ? this.editedAddress.lastname : '';
  },

  methods: {
    checkNumericValue(event) {
      if (!/[0-9\s+]/.test(event.key)) {
        event.preventDefault();
      }
    },
    companyName() {
      if (this.editedAddress.firstname || this.editedAddress.lastname) {
        this.editedAddress.company = `${this.editedAddress.lastname} ${this.editedAddress.firstname}`;
      }
    },
    italianLimitPostcode() {
      return this.editedAddress.country === 'IT' ? 5 : 10;
    },
    italianLimitState() {
      return this.editedAddress.country === 'IT' ? 2 : 100;
    },
  },
};
</script>
