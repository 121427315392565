import axios from 'axios';

export default {

  namespaced: true,

  state: {
    stringsLayouts: [],
  },

  getters: {
    stringsLayouts: (state) => state.stringsLayouts,
  },

  mutations: {
    setStringsLayouts(state, stringsLayouts) {
      state.stringsLayouts = stringsLayouts;
    },
  },

  actions: {
    async getStringsLayouts({ commit }) {
      const { data } = await axios.get('/api/strings-layouts');

      commit('setStringsLayouts', data);
    },
  },
};
