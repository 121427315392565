<!-- eslint-disable max-len -->
<template>
  <div class="flex">
    <login-view/>
    <home-image />
  </div>
</template>

<script>
import HomeImage from '@/components/HomeImage.vue';
import LoginView from './LoginView.vue';

export default {
  components: { LoginView, HomeImage },
};
</script>
