<template>
  <base-autocomplete
    v-if="changeGood"
    :selection-list="goodsSelectionList"
    @selected="goodSelection"
    v-model="goodSelected"
    :lbl-text="$t('contracts.select_good')"
    class="font-thin"
  />
  <base-checkbox v-else
    :label="$t('contracts.another_good')"
    v-model="changeGood"
  />
  <div class="grid md:grid-rows-2 md:grid-cols-2 gap-x-6 lg:gap-x-12 gap-y-6 font-thin w-full text-lg mt-3">
    <base-input
      :label="$t('goods.harp_model')"
      v-model="goodData.harp_sku" disabled="disabled"
    />
    <base-input
      :label="$t('goods.serial')"
      v-model="goodData.serial_no" disabled="disabled"
    />
    <base-input
      :label="$t('harps.description')"
      v-model="goodData.harp.description" disabled="disabled"
    />
    <base-input
      :label="$t('goods.color')"
      v-model="goodData.color_slug" disabled="disabled"
    />
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase, vue/no-unused-components */
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { mapGetters, mapActions } from 'vuex';
import BaseAutocomplete from '../base/BaseAutocomplete.vue';
import BaseInput from '../base/BaseInput.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';

export default {
  components: {
    BaseAutocomplete,
    BaseInput,
    BaseCheckbox,
  },
  data() {
    return {
      goodsSelectionList: [],
      goodSelected: '',
      changeGood: false,
      goodData: {
        id: null,
        harp_sku: '',
        serial_no: '',
        strings_layout_slug: '',
        color_slug: '',
        harp: {
          description: '',
        },
      },
      uninterceptedAxiosInstance: axios.create(),
    };
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  async created() {
    await this.getGoods();
    await this.getHarps();
    this.initData();
    this.selectNewGood();
  },
  watch: {
    good: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.goodData = this.goodFields(this.good);
        }
      },
    },
  },
  computed: {
    ...mapGetters('goods', ['notRentedGoods', 'good']),
    ...mapGetters('harps', ['harps']),
    contractTemplateFields() {
      return (template) => {
        const { created_at, updated_at, pivot, harps, ...remaining } = template;
        return remaining;
      };
    },
    goodFields() {
      return (good) => ((({ id, harp_sku, serial_no, harp: { description }, color_slug }) => ({ id, harp_sku, serial_no, harp: { description }, color_slug }))(good));
    },
  },
  methods: {
    ...mapActions('goods', ['setGood', 'getGoods']),
    ...mapActions('harps', ['getHarps']),
    ...mapActions('contractTemplates', ['setContractTemplate']),
    inheritContractTemplate() {
      const [selectedHarp] = this.harps.filter((harp) => harp.sku === this.good.harp_sku);
      if (selectedHarp.contract_templates && selectedHarp.contract_templates.length > 0) {
        const contractTemplate = this.contractTemplateFields(selectedHarp.contract_templates[0]);
        this.setContractTemplate(contractTemplate);
        return;
      }
      this.setContractTemplate({});
    },
    initData() {
      this.goodsSelectionList = this.notRentedGoods.map((good) => ({ id: good.id, text: `${good.harp.description} ${good.color_slug} ${good.harp_sku} ${good.serial_no}` }));

      if (this.good && Object.keys(this.good).length > 0) {
        this.goodData = this.goodFields(this.good);
        // this.inheritContractTemplate();
      }
    },
    selectNewGood() {
      if (!this.goodData.id) {
        this.changeGood = true;
        return;
      }
      this.changeGood = false;
    },
    async goodSelection(selected) {
      const [newGood] = this.notRentedGoods.filter((good) => good.id === selected.id);

      try {
        await this.uninterceptedAxiosInstance.get(`/api/goods/check-good/${newGood.serial_no}`);
        this.toast.success(this.$t('goods.free'));
        this.setGood(newGood);
        this.inheritContractTemplate();
        this.changeGood = false;
      } catch (error) {
        this.toast.error(this.$t('goods.inUse'));
      }
    },
  },
};
</script>
