<template>
  <base-autocomplete
    v-if="changeCustomer"
    :selection-list="customersSelectionList"
    @selected="customerSelection"
    v-model="customerSelected"
    :lbl-text="$t('contracts.select_customer')"
    class="font-thin"
    :selectNotAvailable="createShippingAddress || updateBillingAddress"
  />
  <base-checkbox
    :label="$t('contracts.another_customer')"
    v-model="changeCustomer"
    v-else
  />
  <div class="flex flex-col font-thin w-full text-lg space-y-6 mt-3">
    <p class="col-span-2 text-2xl text-left capitalize font-medium">{{ $t('customers.biodata') }}</p>
    <div v-if="isNotPerson" class="grid grid-cols-2 gap-3">
      <base-input
        :label="$t('customers.company')"
        v-model="customerData.company" disabled="true"
      />
      <base-input
        :label="$t('customers.email')"
        v-model="customerData.email" disabled="true"
      />
    </div>
    <div class="grid grid-cols-2 gap-3" v-if="!isNotPerson">
      <base-input
        :label="$t('customers.firstname')"
        v-model="customerData.firstname" disabled="true"
      />
      <base-input
        :label="$t('customers.lastname')"
        v-model="customerData.lastname" disabled="true"
      />
      <base-input
        :label="$t('customers.email')"
        v-model="customerData.email" disabled="true"
      />
    </div>

    <div class="grid grid-cols-6 gap-3">
      <p class="col-span-6 text-2xl text-left capitalize font-medium">{{ $t('customers.billingAddress')}}</p>
      <contract-addresses
        :address="customerData.billing_address"
        edit-icon="edit"
        save-icon="save"
        :visibility="updateBillingAddress"
        @edit-function="() => updateBillingAddress = !updateBillingAddress"
        @save-function="saveBillingAddress(address)"
      ></contract-addresses>
    </div>

    <div class="grid grid-cols-6 gap-3">
      <p class="col-span-6 text-2xl text-left capitalize font-medium">{{ $t('customers.shippingAddresses')}}</p>

      <base-checkbox
        class="col-span-6 font-normal"
        :label="$t('contracts.another_shipping_address')" v-model="changeAddress"
      />
      <div class="col-span-6 grid grid-cols-3 gap-x-3">
        <div v-for="(address, index) in addressCardsList" :key="index">
          <address-card
            selectedClass="border border-emerald-500 text-emerald-500 bg-emerald-50 dark:bg-slate-700"
            :extra-class="changeAddress ? 'flex cursor-pointer col-span-1' : 'hidden'"
            :addresses="address"
            @selected="addressSelection"
            @current="selectedCard(address)"
            :isSelected="addressSelected === address"
          >
          </address-card>
        </div>
      </div>

      <contract-addresses
        :address="customerData.shipping_address"
        edit-icon="add"
        save-icon="save"
        :visibility="createShippingAddress"
        @edit-function="newShippingAddress()"
        @save-function="saveShippingAddress(address)"
        shippingAddress
      ></contract-addresses>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline, camelcase, vue/no-unused-components */
import { mapGetters, mapActions } from 'vuex';
import deepEqual from 'deep-equal';
import BaseAutocomplete from '../base/BaseAutocomplete.vue';
import ContractAddresses from './ContractAddresses.vue';
import BaseInput from '../base/BaseInput.vue';
import AddressCard from '../base/AddressCard.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';

export default {
  components: {
    BaseAutocomplete,
    ContractAddresses,
    BaseInput,
    AddressCard,
    BaseCheckbox,
  },
  data() {
    return {
      customersSelectionList: [],
      customerSelected: '',
      changeCustomer: false,
      addressCardsList: [],
      addressSelected: null,
      changeAddress: false,
      updateBillingAddress: false,
      createShippingAddress: false,
      customerData: {
        id: null,
        type: null,
        company: '',
        firstname: '',
        lastname: '',
        email: '',
        billing_address: {
          id: null,
          address: '',
          postcode: '',
          city: '',
          state: '',
          country: '',
          phone: '',
        },
        shipping_address: {
          id: null,
          address: '',
          postcode: '',
          city: '',
          state: '',
          country: '',
          phone: '',
        },
      },
    };
  },
  async created() {
    await this.getCustomers();
    this.initData();
    this.selectNewCustomer();
  },
  watch: {
    customer: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && !deepEqual(newValue, oldValue, { strict: true })) {
          this.setShippingAddress({});
          this.refreshCustomerData(newValue);
        }
      },
    },
  },
  computed: {
    ...mapGetters('customers', ['customers', 'customer', 'shippingAddress']),
    isNotPerson() {
      return this.customer.type === 'G';
    },
  },
  methods: {
    ...mapActions('customers', ['setCustomer', 'getCustomers', 'putCustomerAddress', 'postAddress', 'setShippingAddress']),
    initData() {
      this.customersSelectionList = this.customers.map((customer) => {
        if (customer.type === 'G') {
          return { id: customer.id, text: `${customer.company}` };
        }
        return { id: customer.id, text: `${customer.lastname} ${customer.firstname}` };
      });
      if (this.customer && Object.keys(this.customer).length > 0) {
        this.refreshCustomerData(this.customer);
      }
    },
    selectNewCustomer() {
      if (!this.customerData.id) {
        this.changeCustomer = true;
        return;
      }
      this.changeCustomer = false;
    },
    selectedCard(address) {
      if (address.id !== this.shippingAddress.id) {
        return;
      }
      this.addressSelected = address;
    },
    customerSelection(selected) {
      const [newCustomer] = this.customers.filter((customer) => customer.id === selected.id);
      this.setCustomer(newCustomer);
      this.changeCustomer = false;
    },
    addressSelection(selected) {
      [this.customerData.shipping_address] = JSON.parse(JSON.stringify(this.customer.shipping_addresses.filter((address) => address.id === selected.id)));
      this.setShippingAddress(this.customerData.shipping_address);
    },
    refreshCustomerData(data) {
      // eslint-disable-nextline
      this.customerData = (({ id, type, company, firstname, email, lastname, billing_address, shipping_address, shipping_addresses, addresses }) => ({ id, type, company, firstname, email, lastname, billing_address, shipping_address, shipping_addresses, addresses }))(data);
      this.customerData.shipping_addresses = data.shipping_addresses;
      this.customerData.shipping_address = { address: '', postcode: '', city: '', state: '', phone: '' };

      this.addressCardsList = data.shipping_addresses.map((address) => ({ id: address.id, text: `${address.company} - ${address.address} - ${address.postcode} - ${address.city} - ${address.state}` }));
      const defaultShippingAddress = data.shipping_addresses.filter((address) => address.defaultAddress)[0]; //eslint-disable-line
      if ((data.shipping_addresses && data.shipping_addresses.length === 0) || !defaultShippingAddress) {
        this.customerData.shipping_address = { ...this.customerData.billing_address };
        this.setShippingAddress(this.customerData.shipping_address);
        return;
      }
      this.customerData.shipping_address = !this.shippingAddress.id ? JSON.parse(JSON.stringify(defaultShippingAddress)) : this.shippingAddress;
      this.setShippingAddress(this.customerData.shipping_address);
    },
    async saveBillingAddress() {
      this.updateBillingAddress = !this.updateBillingAddress;
      const { created_at, updated_at, id, ...otherAddressProperties } = this.customerData.billing_address; //eslint-disable-line
      await this.putCustomerAddress({
        customerId: this.customerData.id,
        addressId: id,
        payload: {
          ...otherAddressProperties,
        },
      });
    },
    newShippingAddress() {
      this.createShippingAddress = !this.createShippingAddress;
      this.customerData.shipping_address.company = this.customerData.company;
      this.customerData.shipping_address.firstname = this.customerData.firstname;
      this.customerData.shipping_address.lastname = this.customerData.lastname;
      this.customerData.shipping_address.address = '';
      this.customerData.shipping_address.co = '';
      this.customerData.shipping_address.postcode = '';
      this.customerData.shipping_address.city = '';
      this.customerData.shipping_address.state = '';
      this.customerData.shipping_address.country = '';
      this.customerData.shipping_address.phone = '';
      this.customerData.shipping_address.note = '';
      this.customerData.shipping_address.defaultAddress = false;
      this.customerData.shipping_address.type = 'S';
      delete this.customerData.shipping_address.id;
    },
    async saveShippingAddress() {
      this.createShippingAddress = !this.createShippingAddress;
      const newAddress = this.normalizeDefaultAddress(this.customerData.shipping_address);
      const addressAdded = await this.postAddress({ id: this.customerData.id, payload: { ...newAddress } });
      this.customerData.shipping_addresses.unshift(addressAdded);
      this.addressCardsList = this.customerData.shipping_addresses.map((address) => ({ id: address.id, text: `${address.company} - ${address.address} - ${address.postcode} - ${address.city} - ${address.state}` }));
    },

    normalizeDefaultAddress(address) {
      if (address.defaultAddress) {
        this.unsetDefaultAddress();
      }
      return address;
    },
    async unsetDefaultAddress() {
      const promises = [];
      for (const address of this.customerData.shipping_addresses) { // eslint-disable-line
        address.defaultAddress = false;
        const { id, customer_id, company, firstname, lastname, phone, created_at: _1, updated_at: _2, ...remaining } = address; // eslint-disable-line
        // eslint-disable-next-line
        promises.push(this.putCustomerAddress({
          customerId: customer_id,
          addressId: id,
          payload: {
            company,
            firstname,
            lastname,
            phone,
            ...remaining,
          },
        }));
      }
      await Promise.all(promises);
    },
  },
};
</script>
