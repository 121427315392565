<template>
  <div class="flex flex-col font-thin">
    <div v-if="isGoodSold" class="flex font-semibold space-x-1">
      <icon-button
        :title="$t('buttons.goodSold')"
        disabled
        extra-class="bg-gray-200 text-violet-500 disabled:hover:bg-gray-200 disabled:hover:text-violet-500 good-button cursor-not-allowed">
        {{ $t('buttons.goodSold') }}
      </icon-button>
    </div>
    <div v-else class="flex font-semibold space-x-1">
      <icon-button
        :title="$t('buttons.edit')"
        @click="$emit('edit')"
        extra-class="good-button">
        <edit-icon class="w-3 md:w-4 py-1" />
      </icon-button>
      <icon-button
        :title="$t('buttons.createContract')"
        @click="$emit('createContract')" v-if="!goodIsRentedNow"
        extra-class="good-button">
        <share-icon class="w-3 md:w-4 py-1" />
      </icon-button>
      <button
        :title="$t('buttons.contractInfo')"
        @click="$emit('openContract', good)" v-else
        class="text-2xl px-3 rounded-t-md border border-b-0 border-violet-500 hover:bg-white text-white hover:text-violet-300
        bg-violet-200 dark:text-violet-300 dark:hover:bg-violet-300 dark:bg-transparent dark:hover:text-slate-600">
        &#9432;
      </button>
    </div>
    <div class="grid grid-cols-3 p-4 rounded-b-md rounded-tr-md border border-violet-500 dark:text-white">
      <div class="flex flex-col items-start">
        <p class="font-bold dark:text-violet-300 text-violet-500">{{ good && good.harp ? good.harp.description : ''}}</p>
        <p>{{ good ? good.serial_no : '' }}</p>
        <!-- <p :class="`state-${good.state}`">{{ good.state }}</p> -->
      </div>
      <div class="flex flex-col">
        <p>{{ good && good.strings_layout ? good.strings_layout.description : '' }}</p>
        <p>{{ good ? good.color.color : '' }}</p>
      </div>
      <div class="flex flex-col">
        <p class="capitalize">{{ good ? good.good_location : '' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from '../base/IconButton.vue';
import EditIcon from '../graphics/EditIcon.vue';
import ShareIcon from '../graphics/ShareIcon.vue';

export default {
  components: { EditIcon, ShareIcon, IconButton },

  props: {
    good: {
      type: Object,
    },
  },
  computed: {
    goodIsRentedNow() {
      return this.good && this.good.is_rented_now;
    },
    isGoodSold() {
      return this.good && this.good.state === 'sold';
    },
  },
};
</script>

<style scoped>
.state-new {
  @apply font-medium text-green-500;
}
.state-used {
  @apply font-medium text-yellow-500;
}
</style>
