<template>
  <div class="w-11/12 2xl:w-4/5 mx-auto mt-5 grid grid-cols-5 font-thin dark:text-white h-5/6 text-sm xl:text-base">
    <div class="col-span-1 text-base xl:text-lg capitalize border-r p-5 space-y-1 flex flex-col">
      <p class="mb-3 font-normal">{{ $t('user.hello') }}<br> {{ userName }}</p>
      <button @click="selectPage(page)" v-for="page in pages" :key="page"
      class="text-left capitalize" :class="currentPage === page.label ? 'font-normal text-red-500' : 'text-black'">
        {{ page.label }}
      </button>
    </div>
    <div class="col-span-4 p-5" v-for="page in pages" :key="page">
      <component v-if="currentPage === page.label"
        :is="page.component" :property="page.property"
      />
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import TokenTab from '../components/user/TokenTab.vue';

export default {
  components: {
    TokenTab,
  },
  data() {
    return {
      userName: '',
      currentPage: 'token',
      pages: [
        {
          label: 'token',
          component: markRaw(TokenTab),
          property: this.auth(),
        },
      ],
    };
  },

  async created() {
    await this.auth();
    this.userName = this.user.name;
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    ...mapActions('auth', ['auth']),
    selectPage(page) {
      this.currentPage = page.label;
    },
  },
};
</script>
