<template>
  <div class="text-sm xl:text-base">
    <div class="flex justify-between">
      <h3 :class="titleStyle">{{tableTitle}}</h3>
      <button
        v-show="addIcon"
        @click.stop.prevent="$emit('add-row')"
        class="fill-gray-400 hover:fill-blue-500 px-1 dark:fill-white dark:hover:fill-blue-400">
        <add-icon class="w-4" />
      </button>
    </div>
    <div class="table w-full table-auto">
      <div v-if="!noHeader" class="table-header-group">
        <div class="table-row">
          <div v-for="label in labels" :key="label"
          class="table-cell responsive-text font-medium py-1 px-2 text-white border border-white dark:border-slate-600 dark:text-slate-600"
          :class="headerCellStyle"
          >
            {{ label }}
          </div>
        </div>
      </div>
      <div class="table-row-group">
        <div v-for="item in items" :key="item.id" class="table-row odd:bg-slate-100 even:bg-slate-200 dark:odd:bg-slate-500 dark:even:bg-slate-500">
          <div v-for="field in dataFields" :key="field" class="table-cell py-1 px-2 responsive-text break-words" :class="cellStyle">
            <span v-if="isBoolean(field)">
              <check-icon v-show="!!item[field.field]" class="w-4" />
            </span>
            <span v-else>
              {{ item[field.field] }}
            </span>
          </div>
          <div :class="cellStyle" class="px-1 table-cell text-left" v-show="iconFields">
            <button
              @click.stop.prevent="$emit('edit-row', item)"
              class="text-gray-500 px-1 dark:text-white hover:text-gray-800 dark:hover:text-gray-800">
              <edit-icon class="w-4" />
            </button>
            <button
              @click.stop.prevent="$emit('delete-row', item)"
              class="text-gray-500 px-1 dark:text-white hover:text-red-600 dark:hover:text-red-600">
              <delete-icon class="w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddIcon from '../graphics/AddIcon.vue';
import EditIcon from '../graphics/EditIcon.vue';
import DeleteIcon from '../graphics/DeleteIcon.vue';
import CheckIcon from '../graphics/CheckIcon.vue';

export default {

  components: {
    AddIcon,
    EditIcon,
    DeleteIcon,
    CheckIcon, // eslint-disable-line
  },

  emits: ['add-row', 'edit-row', 'delete-row'],

  props: {
    visibleFields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    tableTitle: {
      type: String,
      default: 'Data Table',
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    titleStyle: {
      type: String,
      default: 'text-xl text-stone-700 font-medium my-2 dark:text-white capitalize',
    },
    headerCellStyle: {
      type: String,
      default: '',
    },
    cellStyle: {
      type: String,
      default: 'text-left text-gray-800 dark:text-white border border-white dark:border-slate-600',
    },
    iconFields: {
      type: Boolean,
      default: true,
    },
    addIcon: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dataFields: [],
      labels: [],
    };
  },

  created() {
    this.init();
  },
  watch: {
    items: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.init();
        }
      },
    },
  },
  methods: {
    init() {
      this.dataFields = this.items.length > 0 ? this.visibleFields.map((field) => ({ field: field.field, type: field.type })) : [];
      this.labels = [...this.visibleFields.map((field) => field.label), this.$t('actions')];
    },
  },
  computed: {
    isBoolean() {
      return (field) => {
        if (field.type && field.type.toLowerCase() === 'boolean') {
          return true;
        }
        return false;
      };
    },
  },
};
</script>

<style>
.responsive-text {
  font-size: min(max(0.8em, 1vw), 1em);
}
</style>
