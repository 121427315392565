import axios from 'axios';

export default {

  namespaced: true,

  state: {
    customers: [],
    customer: {},
    shippingAddress: {},
  },

  getters: {
    customers(state) {
      return state.customers;
    },
    customer(state) {
      return state.customer;
    },
    shippingAddress(state) {
      return state.shippingAddress;
    },
  },

  mutations: {
    setCustomers(state, customers) {
      state.customers = customers;
    },
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setShippingAddress(state, shippingAddress) {
      state.shippingAddress = shippingAddress;
    },
  },

  actions: {
    async getCustomers({ commit }, query) {
      const queryString = query ? `?text=${query.text}` : '';
      const { data } = await axios.get(`/api/customers${queryString}`);

      commit('setCustomers', data);
    },
    async getCustomerByHash({ commit }, hash) { // eslint-disable-line
      const { data } = await axios.get(`/api/customers/${hash}`);
      return data;
    },
    async postCustomer({ dispatch }, customer) { // eslint-disable-line
      const { data } = await axios.post('/api/customers', customer);
      return data;
    },
    async postAddress({ dispatch }, customer) {
      const { id, payload } = customer;
      const { data } = await axios.post(`/api/customers/${id}/addresses`, payload);
      await dispatch('getCustomers');
      return data;
    },
    async putCustomer({ dispatch }, customer) {
      const { id, payload } = customer;
      const { data } = await axios.put(`/api/customers/${id}`, payload);
      await dispatch('getCustomers');
      return data;
    },
    async putCustomerAddress({ commit }, customerAddress) { // eslint-disable-line
      const { customerId, addressId, payload } = customerAddress;
      await axios.put(`/api/customers/${customerId}/addresses/${addressId}`, payload);
    },
    async postCustomerAddress({ commit }, customerAddress) { // eslint-disable-line
      const { customerId, payload } = customerAddress;
      const { data } = await axios.post(`/api/customers/${customerId}/addresses`, payload);
      return data;
    },
    async deleteCustomerAddress({ dispatch }, { customerId, addressId }) {
      await axios.delete(`/api/customers/${customerId}/addresses/${addressId}`);
      await dispatch('getCustomers');
    },
    setCustomer({ commit }, customer) {
      commit('setCustomer', customer);
    },
    setShippingAddress({ commit }, shippingAddress) {
      commit('setShippingAddress', shippingAddress);
    },
    async getCustomerStripeIntent({ commit }, customerId) { //eslint-disable-line
      const { data } = await axios.get(`/api/customers/${customerId}/intent`);
      return data.intent;
    },
    async updatePaymentMethod({ commit }, { id, paymentMethod, source }) { //eslint-disable-line
      await axios.post(`/api/customers/${id}/update-payment-method`, { payment_method: paymentMethod, source });
    },
    async checkCustomerPaymentMethod({ commit }, customerId) { //eslint-disable-line
      const { data } = await axios.get(`/api/customers/${customerId}/has-payment-method`);
      return data;
    },
    async getPaymentMethods({ commit }, customerId) { //eslint-disable-line
      const { data } = await axios.get(`/api/customers/${customerId}/payment-methods`);
      return data.data;
    },
    async detachPaymentMethod({ commit }, { customerId, paymentMethodId }) { //eslint-disable-line
      await axios.post(`/api/customers/${customerId}/payment-method/detach`, { paymentMethodId });
    },
    async getContractsByCustomer({ commit }, customerId) { //eslint-disable-line
      const { data } = await axios.get(`/api/customers/${customerId}/contracts`);
      return data;
    },
    async buyGood({ commit }, { customerId, contractId}) { //eslint-disable-line
      const { data } = await axios.post(`/api/customers/${customerId}/buy-good`, { contractId });
      return data;
    },
    async sendBackGood({ commit }, { customerId, contractId}) { //eslint-disable-line
      const { data } = await axios.post(`/api/customers/${customerId}/stop-rental`, { contractId });
      return data;
    },
    async goodSold({ commit }, { customerId, contractId}) { //eslint-disable-line
      const { data } = await axios.post(`/api/customers/${customerId}/good-sold`, { contractId });
      return data;
    },
    async resumeRent({ commit }, { customerId, contractId}) { //eslint-disable-line
      const { data } = await axios.post(`/api/customers/${customerId}/resume-rental`, { contractId });
      return data;
    },
  },
};
