<template>
  <div class="w-full" ref="topList">
    <color-banner bgStyle="bg-customer"></color-banner>
    <custom-modal v-if="showModalUpload" @close="!showModalUpload" />
    <custom-modal
      ref="addCustomer"
      extra-class="bg-blue-400 text-white/90 font-medium fill-white/90"
      container-class="w-5/6 xl:w-3/4"
    >
      <template v-slot:header>
        {{ $t('customers.addTitle') }}
      </template>
      <template v-slot:body>
        <add-customer
          @close="closeAddCustomer()"
          @create-contract="openAddContractModal"
        />
      </template>
    </custom-modal>
    <custom-modal
      ref="editCustomer"
      component-to-open="EditCustomer"
      extra-class="bg-blue-400 text-white/90 font-medium fill-white/90"
    >
      <template v-slot:header>
        {{ $t('customers.editTitle') }}
      </template>
      <template v-slot:body>
        <edit-customer @close="closeEditCustomer()"/>
      </template>
    </custom-modal>

    <custom-modal
      ref="addContract"
      component-to-open="AddContract"
      container-class="w-5/6 xl:w-3/4"
      extra-class="bg-emerald-400 text-white/90 font-medium fill-white/90"
      @close="clearWizard()"
    >
      <template v-slot:header>
        <h1 class="text-xl">
          {{ $t('contracts.addTitle') }}
        </h1>
      </template>
      <template v-slot:body>
        <add-contract @close="saveAndShowContract()"/>
      </template>
    </custom-modal>

    <custom-modal
      ref="contractDetails"
      component-to-open="ContractDetails"
      extra-class="bg-emerald-400 text-white/90 font-medium fill-white/90"
      @close="clearWizard()"
    >
      <template v-slot:header>
        {{ $t("contracts.contract") }}
      </template>
      <template v-slot:body>
        <contract-details @close="$refs.contractDetails.closeModal()"/>
      </template>
    </custom-modal>

    <base-container :buttons-slot="false">
      <template v-slot:title>
        {{ $t('customers.customers') }}
      </template>

      <template v-slot:body>
        <ais-instant-search
          :search-client="searchClient"
          index-name="customers"
          :future="{ preserveSharedStateOnUnmount: true}"
          :class-names="{
            'ais-InstantSearch': 'flex flex-col space-y-5',
          }"
        >
          <ais-configure
            :hits-per-page.camel="hitsPerPage"
          />
          <div class="flex flex-col xl:flex-row w-full justify-between mt-5 items-center">
            <div class="flex w-full xl:w-2/5 2xl:w-1/2 space-x-1 justify-between mb-2 xl:mb-0 xl:mr-3">
              <base-button @click="$refs.addCustomer.openModal()" :title="$t('buttons.addNew')"
                extra-class="fill-white bg-blue-200 hover:bg-transparent hover:fill-blue-400 border-blue-500
                dark:bg-transparent dark:fill-blue-300 dark:hover:bg-blue-300 dark:hover:fill-slate-600 mr-2">
                <add-icon />
              </base-button>
              <ais-search-box
                :class-names="{
                  'ais-SearchBox': 'w-1/2 xl:w-3/4',
                }"
              >
                <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                  <label for="srch">
                    <div class="relative flex items-center text-black dark:text-white">
                      <input
                        name="srch"
                        type="search"
                        :value="currentRefinement"
                        @input="refine($event.currentTarget.value)"
                        class="rounded-md p-2 w-full bg-transparent border border-gray-400 dark:border-gray-100"
                      />
                      <div class="absolute right-0 cursor-pointer pr-3">
                        <search-icon v-if="!currentRefinement" @click="refine($event.currentTarget.value)" class="w-4" />
                      </div>
                    </div>
                  </label>
                  <span :hidden="!isSearchStalled">Loading...</span>
                </template>
              </ais-search-box>
            </div>
            <ais-numeric-menu
              attribute="contracts_active"
              :items="filterButtons"
              :class-names="{
                'ais-NumericMenu': 'w-full xl:w-3/5 2xl:w-1/2 grid place-self-end self-center',
              }"
            >
              <template v-slot="{ items, refine }">
                <base-button-group
                  class="dark:text-slate-600 text-blue-500"
                  :buttons="filteredButtonsLabels"
                  :button-index="buttonIndex"
                  @action="filterByContractStatus($event, refine, items)"
                  base-classes="hover:bg-blue-200 hover:text-blue-500 bg-transparent border-blue-500 text-blue-400"
                  extra-classes="dark:bg-transparent dark:text-blue-300 dark:hover:bg-blue-300 dark:hover:text-slate-600"
                  active-classes="bg-transparent text-blue-500"
                  button-active-style="background-color: rgb(191 219 254); color: currentColor"
                >
                </base-button-group>
              </template>
            </ais-numeric-menu>
          </div>
          <algolia-refresh ref="algoliaRefresh"/>
          <ais-state-results>
            <template v-slot="{ results: { hits } }">
              <ais-hits v-if="hits.length > 0">
                <template v-slot:item="{ item }">
                  <customer-row
                    :key="item.id"
                    :customer="item"
                    @edit="openEditModal(item)"
                    @create-contract="openAddContractModal(item)"
                    class="flex justify-between h-full mb-5"
                  />
                </template>
              </ais-hits>
              <div v-else>
                <p class="w-full text-center font-thin text-2xl dark:text-white">{{ $t('noMatchFound') }}</p>
              </div>
            </template>
          </ais-state-results>
          <ais-pagination
            :class-names="{
              'ais-Pagination-list': 'flex justify-center my-5 text-blue-200',
              'ais-Pagination-item--selected': 'text-white dark:text-slate-700 bg-blue-200',
              'ais-Pagination-item': 'text-lg border border-blue-200 hover:text-white hover:dark:text-slate-700 hover:bg-blue-200 pagination-item',
              'ais-Pagination-item--firstPage': 'rounded-l-sm',
              'ais-Pagination-item--lastPage': 'rounded-r-sm',
              'ais-Pagination-link': 'px-2 py-1 pagination-link',
              'ais-Pagination-item--disabled': 'item-disabled cursor-not-allowed',
            }"
            @click.prevent="scrollToTop()"
          />
        </ais-instant-search>
      </template>
    </base-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import algoliasearch from 'algoliasearch/lite';

import AddIcon from '@/components/graphics/AddIcon.vue';
import SearchIcon from '@/components/graphics/SearchIcon.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseContainer from '@/components/base/BaseContainer.vue';
import AddCustomer from '@/components/customers/AddCustomer.vue';
import EditCustomer from '@/components/customers/EditCustomer.vue';
import CustomerRow from '@/components/customers/CustomerRow.vue';
import CustomModal from '@/components/base/CustomModal.vue';
import AddContract from '@/components/contracts/AddContract.vue';
import ColorBanner from '../components/graphics/ColorBanner.vue';
import BaseButtonGroup from '../components/base/BaseButtonGroup.vue';
import ContractDetails from '../components/contracts/ContractDetails.vue';
import AlgoliaRefresh from '../components/base/AlgoliaRefresh.vue';

export default {
  components: {
    AddCustomer,
    EditCustomer,
    BaseContainer,
    CustomModal,
    CustomerRow,
    AddContract,
    ColorBanner,
    BaseButton,
    AddIcon,
    SearchIcon,
    BaseButtonGroup,
    ContractDetails,
    AlgoliaRefresh,
  },

  data() {
    return {
      showModalUpload: false,
      searchText: '',
      buttonIndex: 0,
      hitsPerPage: 20,
      searchClient: algoliasearch(
        process.env.VUE_APP_ALGOLIA_APP_ID,
        process.env.VUE_APP_ALGOLIA_SECRET,
      ),
    };
  },

  async created() {
    await this.getCustomers();
    await this.getContracts();
  },

  computed: {
    ...mapGetters('customers', ['customers', 'customer']),
    ...mapGetters('contracts', ['contracts']),
    filterButtons() {
      return [
        {
          label: this.$t('contracts.all'),
        },
        {
          label: `${this.$t('contracts.contract')} ${this.$t('contracts.yes')}`,
          start: 1,
        },
        {
          label: `${this.$t('contracts.contract')} ${this.$t('contracts.no')}`,
          end: 0,
        },
      ];
    },
    filteredButtonsLabels() {
      return this.filterButtons.map((btn) => btn.label);
    },
    // filterContracts() {
    //   if (this.buttonIndex === 0) {
    //     return 'contracts_active >= 0';
    //   }
    //   if (this.buttonIndex === 1) {
    //     return 'contracts_active >= 1';
    //   }
    //   return 'contracts_active = 0';
    // },
  },

  methods: {
    ...mapActions('goods', ['setGood']),
    ...mapActions('contractTemplates', ['setContractTemplate']),
    ...mapActions('contracts', ['getContracts']),
    ...mapActions('customers', ['getCustomers', 'setCustomer', 'setShippingAddress']),
    openEditModal(customer) {
      this.setCustomer(customer);
      this.$refs.editCustomer.openModal();
    },
    openAddContractModal(customer) {
      this.setCustomer(customer);
      this.$refs.addContract.openModal();
    },
    saveAndShowContract() {
      this.$refs.addContract.closeModal();
      this.$refs.contractDetails.openModal();
    },
    clearWizard() {
      this.setContractTemplate({});
      this.setCustomer({});
      this.setGood({});
      this.setShippingAddress({});
    },
    async filterByContractStatus(ev, refine, items) {
      this.buttonIndex = ev;
      refine(items[ev].value);
    },
    scrollToTop() {
      this.$refs.topList.scrollIntoView({ behavior: 'smooth' });
    },
    closeEditCustomer() {
      this.$refs.algoliaRefresh.refresh();
      this.$refs.editCustomer.closeModal();
    },
    closeAddCustomer() {
      this.$refs.algoliaRefresh.refresh();
      this.$refs.addCustomer.closeModal();
    },
  },
};
</script>

<style>
.item-disabled .pagination-link {
  @apply bg-gradient-to-l from-gray-100 to-gray-100 dark:from-slate-400 dark:to-slate-400 text-gray-400 dark:text-slate-500
}

.item-disabled .pagination-link:hover {
  @apply text-gray-400 dark:text-slate-500
}

.item-disabled.pagination-item {
  @apply border border-gray-300 dark:border-slate-500
}
</style>
