import axios from 'axios';

export default {

  namespaced: true,

  state: {
    harpColors: [],
  },

  getters: {
    harpColors: (state) => state.harpColors,
  },

  mutations: {
    setHarpColors(state, harpColors) {
      state.harpColors = harpColors;
    },
  },

  actions: {
    async getHarpColors({ commit }) {
      const { data } = await axios.get('/api/harp-colors');

      commit('setHarpColors', data);
    },
  },
};
