<template>
  <button
    class="rounded-t-md border border-b-0 hover:bg-white py-1.5 px-3 text-white hover:dark:text-slate-600 dark:bg-transparent"
    :class="extraClass">
    <slot/>
  </button>
</template>

<script>
export default {
  props: {
    extraClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>
  .good-button {
    @apply bg-violet-200 border-violet-500 hover:text-violet-300 dark:text-violet-300 dark:hover:bg-violet-300
  }
</style>
