<template>
  <div class="flex">
    <div class="w-screen md:w-2/5 xl:w-1/4 2xl:w-1/5 h-screen grid grid-rows-3
      dark:bg-gray-800 dark:text-white z-50">
      <img src="@/assets/logo-sonar.png"
        alt="Logo Sonar"
        class="dark:hidden flex w-2/3 place-self-center"
      >
      <img src="@/assets/logo-sonar-white.png"
        alt="Logo Sonar"
        class="hidden dark:flex w-2/3 place-self-center"
      >
      <form @submit.prevent="reset" class="flex flex-col items-center space-y-6 font-thin w-4/5
        self-end justify-self-center row-start-2">
        <h2 class="text-3xl font-medium text-left w-full mb-4
          text-light-blue-rental dark:text-light-blue-rental-200"
        >{{ $t('resetPassword.reset') }}</h2>
        <label for="email" class="flex flex-col w-full">
          {{ $t('resetPassword.email') }}
          <input
            type="email"
            name="email"
            class="border rounded-lg px-3 py-2 border-gray-300 bg-transparent
              dark:border-light-blue-rental-200"
            v-model="credentials.email"
            />
        </label>
        <label for="password" class="flex flex-col w-full">
            {{ $t('resetPassword.password') }}
          <input
            type="password"
            name="password"
            class="border rounded-lg px-3 py-2 border-gray-300 bg-transparent
              dark:border-light-blue-rental-200"
            v-model="credentials.password"
          />
        </label>
        <label for="password-confirmation" class="flex flex-col w-full">
            {{ $t('resetPassword.passwordConfirmation') }}
          <input
            type="password"
            name="password-confirmation"
            class="border rounded-lg px-3 py-2 border-gray-300 bg-transparent
              dark:border-light-blue-rental-200"
            v-model="credentials.password_confirmation"
          />
        </label>
        <button
          type="submit"
          class="border border-light-blue-rental dark:border-light-blue-rental-200
            px-3 py-1 rounded-md uppercase dark:hover:text-gray-800
            text-base text-light-blue-rental dark:text-light-blue-rental-200
            font-medium hover:bg-light-blue-rental hover:text-white self-end"
        >
        {{ $t('resetPassword.send') }}
        </button>
      </form>
      <p class="text-xs text-center font-thin text-light-blue-rental dark:text-white
        self-end justify-self-center w-4/5 pb-5 row-start-3">
        {{ $t('rights') }} {{currentYear}}
      </p>
    </div>

    <home-image />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
import HomeImage from '@/components/HomeImage.vue';

export default {
  components: {
    HomeImage,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      credentials: {
        token: this.$route.params.token,
        email: '',
        password: '',
        password_confirmation: '',
      },
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    async reset() {
      const { message } = await this.resetPassword(this.credentials);
      this.toast.success(message);
      this.$router.push('/');
    },
  },
};
</script>
