<template>
  <custom-modal
    ref="contractList"
    component-to-open="ContractList"
    extra-class="bg-emerald-400 text-white/90 font-medium fill-white/90"
  >
    <template v-slot:header>
      {{ $t("contracts.contractList") }}
    </template>
    <template v-slot:body>
      <contract-list @close="$refs.contractList.closeModal()" :contracts="filteredContracts"/>
    </template>
  </custom-modal>

  <div class="w-5/6 2xl:w-3/4 flex justify-start mx-auto mt-10 capitalize">
    <div class="w-1/2 grid grid-cols-2 grid-rows-1 gap-6">
      <router-link
        :class="signedContract.length === 0 ? 'cursor-default' : 'cursor-pointer'"
        :to="signedContract.length === 0 ? '' : { name: 'contracts', query: { status: 'agreement_signed' }}"
      >
        <base-dashboard-container extra-class="bg-red-50 text-red-500 border-red-500">
          <template v-slot:title>{{ $t('dashboard.signed') }}</template>
          <template v-slot:number> {{ signedContract.length }} </template>
        </base-dashboard-container>
      </router-link>
      <router-link
        :class="readyToBeShipped.length === 0 ? 'cursor-default' : 'cursor-pointer'"
        :to="readyToBeShipped.length === 0 ? '' : { name: 'contracts', query: { status: 'ready_to_be_shipped' }}"
      >
        <base-dashboard-container extra-class="bg-sky-50 text-sky-500 border-sky-500">
          <template v-slot:title>{{ $t('dashboard.ready-to-be-shipped') }}</template>
          <template v-slot:number> {{ readyToBeShipped.length }} </template>
        </base-dashboard-container>
      </router-link>
    </div>
    <div class="ml-6 w-1/2 grid grid-rows-1 gap-y-6">
      <router-link
        :class="shipped.length === 0 ? 'cursor-default' : 'cursor-pointer'"
        :to="shipped.length === 0 ? '' : { name: 'contracts', query: { status: 'shipped' }}"
      >
        <base-dashboard-container extra-class="bg-lime-50 text-lime-600 border-lime-600">
          <template v-slot:title>{{ $t('dashboard.shipped') }}</template>
          <template v-slot:number> {{ shipped.length }}  </template>
        </base-dashboard-container>
      </router-link>
    </div>
  </div>
  <div class="w-5/6 2xl:w-3/4 flex justify-start mx-auto mt-10 capitalize">
    <div class="w-full grid grid-cols-2 grid-rows-1 gap-6">
      <base-dashboard-container
        extra-class="bg-green-50 text-green-500 border-green-500 cursor-pointer"
        :class="buyGoodRequested.length === 0 ? 'cursor-default' : 'cursor-pointer'"
        @click="buyGoodRequested.length > 0 ? openContractsModalByStatus('buy_good_requested') : ''"
      >
        <template v-slot:title>{{ $t('dashboard.buy_good_requested') }}</template>
        <template v-slot:number> {{ buyGoodRequested.length }} </template>
      </base-dashboard-container>
      <base-dashboard-container
        extra-class="bg-red-100 text-red-500 border-red-500 cursor-pointer"
        :class="stopRentRequested.length === 0 ? 'cursor-default' : 'cursor-pointer'"
        @click="stopRentRequested.length > 0 ? openContractsModalByStatus('stop_rent_requested') : ''"
      >
        <template v-slot:title>{{ $t('dashboard.stop_rent_requested') }}</template>
        <template v-slot:number> {{ stopRentRequested.length }} </template>
      </base-dashboard-container>
    </div>
  </div>
  <div class="w-5/6 2xl:w-3/4 flex justify-start mx-auto mt-10 capitalize">
    <div class="w-full grid grid-cols-2 grid-rows-1 gap-6">
      <base-dashboard-container
        extra-class="bg-violet-50 text-violet-500 border-violet-500 cursor-pointer"
        :class="outstandingSubscriptions.length === 0 ? 'cursor-default' : 'cursor-pointer'"
        @click="outstandingSubscriptions.length > 0 ? openContractsOutstandingSubscriptionModal() : ''"
      >
        <template v-slot:title>{{ $t('dashboard.outstanding_subscriptions') }}</template>
        <template v-slot:number> {{ outstandingSubscriptions.length }} </template>
      </base-dashboard-container>
      <base-dashboard-container
        extra-class="bg-orange-50 text-orange-500 border-orange-500 cursor-pointer"
        :class="activeBy18Months.length === 0 ? 'cursor-default' : 'cursor-pointer'"
        @click="activeBy18Months.length > 0 ? openContractsActiveBy18MonthsModal() : ''"
      >
        <template v-slot:title>{{ $t('dashboard.active_by_18_month') }}</template>
        <template v-slot:number> {{ activeBy18Months.length }} </template>
      </base-dashboard-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import BaseDashboardContainer from '@/components/base/BaseDashboardContainer.vue';
import CustomModal from '@/components/base/CustomModal.vue';
import ContractList from '@/components/contracts/ContractList.vue';

export default {
  components: {
    BaseDashboardContainer,
    CustomModal,
    ContractList,
  },
  data() {
    return {
      filteredContracts: [],
      outstandingSubscriptions: [],
    };
  },
  async created() {
    await this.getContracts();
    this.outstandingSubscriptions = await this.getOutstandingSubscriptions();
  },
  computed: {
    ...mapGetters('contracts', ['contract', 'contracts']),
    signedContract() {
      return this.contracts.filter((status) => status.workflow_status === 'agreement_signed');
    },
    readyToBeShipped() {
      return this.contracts.filter((status) => status.workflow_status === 'ready_to_be_shipped');
    },
    shipped() {
      return this.contracts.filter((status) => status.workflow_status === 'shipped');
    },
    buyGoodRequested() {
      return this.contracts.filter((status) => status.workflow_status === 'buy_good_requested');
    },
    stopRentRequested() {
      return this.contracts.filter((status) => status.workflow_status === 'stop_rent_requested');
    },
    activeBy18Months() {
      const comparisonDate = dayjs().subtract(18, 'months');
      return this.contracts.filter((contract) => contract.is_active && dayjs(contract.created_at) <= comparisonDate);
    },
  },
  methods: {
    ...mapActions('contracts', ['getContracts', 'getOutstandingSubscriptions']),
    openContractsModalByStatus(statusFilter) {
      this.filteredContracts = this.contracts.filter((status) => status.workflow_status === statusFilter);
      this.$refs.contractList.openModal();
    },
    openContractsOutstandingSubscriptionModal() {
      const customerIds = [...new Set(this.outstandingSubscriptions.map((item) => (item.customer_id)))];
      this.filteredContracts = this.contracts.filter((contract) => customerIds.includes(contract.customer_id));
      this.$refs.contractList.openModal();
    },
    openContractsActiveBy18MonthsModal() {
      this.filteredContracts = this.activeBy18Months;
      this.$refs.contractList.openModal();
    },
  },
};
</script>
