<template>
  <div class="w-full flex space-x-10">
    <keep-alive class="w-8">
      <component class="items-center justify-start" :is="cardBrandLogo" />
    </keep-alive>
    <span v-if="method.type === 'card'" class="place-self-start"> xxxx xxxx xxxx {{method.card.last4}}</span>
    <span v-if="method.type === 'sepa_debit'" class="place-self-start">{{method.sepa_debit.country}}xxxxxxxxxxxxxx{{method.sepa_debit.last4}}</span>
</div>
</template>

<script>
import MastercardLogo from './MastercardLogo.vue';
import AmexLogo from './AmexLogo.vue';
import VisaLogo from './VisaLogo.vue';
import SepaLogo from './SepaLogo.vue';

export default {
  props: ['method'],

  data() {
    return {
      cardBrandLogo: null,
    };
  },

  mounted() {
    this.cardBrandLogo = this.method.type === 'card' ? `${this.method.card.brand}Logo` : 'SepaLogo';
  },

  components: {
    MastercardLogo,
    AmexLogo,
    VisaLogo,
    SepaLogo,
  },
};
</script>
