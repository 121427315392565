<template>
  <div class="font-thin">
    <h2 class="text-base lg:text-lg font-medium capitalize">{{ $t('user.new-key') }}</h2>
    <p>
      {{ $t('user.how-to-add-key') }} <br>
      <span class="font-bold text-red-500 uppercase"> {{ $t('user.attention') }} </span><br>
      {{ $t('user.copy-token-please') }}
    </p>
    <div class="grid gap-y-2 mt-3">
      <label for="input" class="capitalize">
        {{ $t('user.name') }}
        <input type="text" v-model="name"
        class="rounded-md p-2 w-full bg-transparent border border-gray-400 dark:border-gray-100">
      </label>
      <base-button extra-class="border-red-500 hover:bg-red-500 text-red-500"
        @click="generateKey()">
        {{ $t('user.create-key') }} +
      </base-button>
      <div class="grid grid-cols-10 2xl:grid-cols-12 gap-x-3 gap-y-2 justify-end items-end" v-show="activeSave">
        <label for="input" class="col-span-9 2xl:col-span-11 capitalize">
          {{ $t('user.token') }}
          <input type="text" v-model="token" ref="newKey"
          class="rounded-md p-2 w-full bg-transparent border border-gray-400 dark:border-gray-100">
        </label>
        <button class="col-span-1 h-fit rounded-md bg-red-500 py-0.5 px-3 text-white text-2xl lg:text-3xl" @click="copyKey()">
          <svg class="w-4 mx-auto"
          xmlns="http://www.w3.org/2000/svg" width="34.958" height="39.32" viewBox="0 0 34.958 39.32">
            <path id="Rectangle_1" data-name="Rectangle 1" d="M2.835,0H19.843a2.835,2.835,0,0,1,2.835,2.835V25.512a2.834,2.834,0,0,1-2.834,2.834H2.835A2.835,2.835,0,0,1,0,25.511V2.835A2.835,2.835,0,0,1,2.835,0Z" transform="translate(10.281 8.974)" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="4"/>
            <path id="Path_1" data-name="Path 1" d="M24.677,8.974V4.835A2.835,2.835,0,0,0,21.843,2H4.834A2.835,2.835,0,0,0,2,4.835V27.512a2.834,2.834,0,0,0,2.834,2.835h5.447" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="4"/>
          </svg>
        </button>
        <base-button
          @click="saveKey"
          extra-class="border-red-500 hover:bg-red-500 text-red-500"
        >
          {{ $t('user.ok') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '../base/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },

  data() {
    return {
      token: '',
      name: '',
      activeSave: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['postRequestBearerToken']),
    async generateKey() {
      this.token = await this.postRequestBearerToken(this.name);
      this.activeSave = true;
    },
    copyKey() {
      this.$refs.newKey.select();
      navigator.clipboard.writeText(this.token);
    },
    saveKey() {
      this.$emit('close');
    },
  },
};
</script>
