<template>
  <label :for="name" class="flex flex-col">
    {{ name }}
    <input
      :type="type ? type : 'text'"
      :id="name"
      :value="modelValue"
      :required="required"
      :disabled="disabled ? disabled : false"
      :min="min"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="$emit('blur')"
      class="rounded-md p-2 bg-transparent border border-gray-400 dark:border-white"
      :class="borderStyle"
    />
  </label>
</template>

<script>
export default {
  name: 'InputField',

  props: ['modelValue', 'name', 'required', 'disabled', 'type', 'min', 'borderStyle'],
};
</script>
