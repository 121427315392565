<template>
  <div class="grid grid-rows-6 md:grid-rows-1 md:grid-cols-11 overflow-hidden rounded-md
  border border-violet-500 md:pr-2 md:p-0 pb-2">
    <div class="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 md:col-span-10
    row-span-5 items-center text-center dark:text-white">
      <p class="bg-violet-200 dark:bg-transparent p-3 text-violet-500 dark:text-violet-300 font-semibold
      md:w-3/4 text-center border-b md:border-0 md:border-r border-violet-500">
        {{ harp.sku }}
      </p>
      <p>
        {{ harp.description }}
      </p>
      <p>
        {{ harp.product_line.line }}
      </p>
    </div>
    <button :title="$t('buttons.edit')" @click="$emit('edit')" class="flex justify-center items-center dark:text-white dark:hover:text-violet-400">
      <edit-icon class="w-4" />
    </button>
  </div>
</template>

<script>
import EditIcon from '../graphics/EditIcon.vue';

export default {
  components: {
    EditIcon,
  },
  props: {
    harp: {
      type: Object,
      required: true,
    },
  },
};
</script>
