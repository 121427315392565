<template>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.company')"
      v-model="customerAddress.company" :disabled="!visibility"
      :required="true"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.firstname')"
      v-model="customerAddress.firstname" :disabled="!visibility"
      class="capitalize"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.lastname')"
      v-model="customerAddress.lastname" :disabled="!visibility"
      @blur="companyName"
      class="capitalize"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.phone')"
      v-model="customerAddress.phone" :disabled="!visibility"
      :required="true"
    />
  </div>
  <div class="col-span-2">
    <select-country
      v-model="customerAddress.country"
      :required="true"
      :disabled="!visibility"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.address')"
      v-model="customerAddress.address" :disabled="!visibility"
      :required="true"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.co')"
      v-model="customerAddress.co" :disabled="!visibility"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.postcode')"
      v-model="customerAddress.postcode" :disabled="!visibility || !customerAddress.country.length"
      :required="true"
      :maxLength="italianLimitPostcode()"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.city')"
      v-model="customerAddress.city" :disabled="!visibility"
      :required="true"
      class="capitalize"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.state')"
      v-model="customerAddress.state" :disabled="!visibility || !customerAddress.country.length"
      :required="true"
      :maxLength="italianLimitState()"
      :class="customerAddress.country === 'IT' ? 'uppercase' : 'normal-case'"
      label-style="capitalize grid text-sm md:text-base"
    />
  </div>
  <div class="col-span-2">
    <base-input
      :label="$t('customers.note')"
      v-model="customerAddress.note" :disabled="!visibility"
    />
  </div>
  <div class="col-span-1">
    <base-checkbox
      :label="$t('customers.defaultAddress')"
      v-model="customerAddress.defaultAddress"
      :disabled="!visibility"
      v-if="shippingAddress"
    />
  </div>
  <div class="col-span-1 flex items-end justify-end">
    <button v-if="!visibility" @click="$emit('edit-function')"
     class="border border-gray-400 fill-gray-400 text-gray-400 rounded-md p-3
     hover:border-emerald-400 hover:text-emerald-400 hover:fill-emerald-400
     dark:fill-gray-100 dark:text-gray-100 dark:border-gray-100
     dark:hover:text-emerald-400 dark:hover:fill-emerald-400">
      <component :is="`${editIcon}-icon`" class="w-4" />
    </button>
    <button v-else @click="$emit('save-function', customerAddress)"
    class="border border-gray-400 text-gray-400 rounded-md p-3
    hover:border-emerald-400 hover:text-emerald-400 hover:fill-emerald-400
    dark:fill-gray-100 dark:text-gray-100 dark:border-gray-100
    dark:hover:text-emerald-400 dark:hover:fill-emerald-400">
      <component :is="`${saveIcon}-icon`" class="w-4" />
    </button>
  </div>
</template>

<script>
import BaseInput from '../base/BaseInput.vue';
import AddIcon from '../graphics/AddIcon.vue';
import EditIcon from '../graphics/EditIcon.vue';
import SaveIcon from '../graphics/SaveIcon.vue';
import SelectCountry from '../base/SelectCountry.vue';
import BaseCheckbox from '../base/BaseCheckbox.vue';

export default {
  components: {
    AddIcon,
    SaveIcon,
    EditIcon,
    BaseInput,
    SelectCountry,
    BaseCheckbox,
  },
  emits: ['edit-function', 'save-function'],
  props: {
    address: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Boolean,
      required: true,
    },
    editIcon: {
      type: String,
      required: true,
    },
    saveIcon: {
      type: String,
      required: true,
    },
    shippingAddress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customerAddress: {},
      editedAddress: {
        address: '',
        postcode: '',
        city: '',
        state: '',
        country: '',
        note: '',
        defaultAddress: false,
      },
      addressFields: [
        {
          field: 'defaultAddress',
          label: this.$t('customers.defaultAddress'),
          type: 'boolean',
        },
        {
          field: 'address',
          label: this.$t('customers.address'),
        },
        {
          field: 'postcode',
          label: this.$t('customers.postcode'),
        },
        {
          field: 'city',
          label: this.$t('customers.city'),
        },
        {
          field: 'state',
          label: this.$t('customers.state'),
        },
        {
          field: 'country',
          label: this.$t('customers.country'),
        },
        {
          field: 'phone',
          label: this.$t('customers.phone'),
        },
        {
          field: 'note',
          label: this.$t('customers.note'),
        },
      ],
      editedCustomer: {},
    };
  },
  watch: {
    address: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.customerAddress = newValue;
        }
      },
    },
  },
  methods: {
    companyName() {
      if (this.customerAddress.firstname || this.customerAddress.lastname) {
        this.customerAddress.company = `${this.customerAddress.lastname} ${this.customerAddress.firstname}`;
      }
    },
    italianLimitPostcode() {
      return this.customerAddress.country === 'IT' ? 5 : 10;
    },
    italianLimitState() {
      return this.customerAddress.country === 'IT' ? 2 : 100;
    },
  },
};
</script>
