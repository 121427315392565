import axios from 'axios';

export default {

  namespaced: true,

  state: {
    harps: [],
    harp: {},
  },

  getters: {
    harps: (state) => state.harps,
    harp: (state) => state.harp,
  },

  mutations: {
    setHarps(state, harps) {
      state.harps = harps;
    },
    setHarp(state, harp) {
      state.harp = harp;
    },
  },

  actions: {
    async getHarps({ commit }) {
      const { data } = await axios.get('/api/harps');

      commit('setHarps', data);
    },
    async putHarp({ dispatch }, harp) {
      const { id, ...payload } = harp;
      await axios.put(`/api/harps/${id}`, payload);
      await dispatch('getHarps');
    },
    setHarp({ commit }, harp) {
      commit('setHarp', harp);
    },
  },
};
