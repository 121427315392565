import axios from 'axios';

export default {

  namespaced: true,

  state: {
    contracts: [],
    contract: {},
  },

  getters: {
    contracts(state) {
      return state.contracts;
    },
    contract(state) {
      return state.contract;
    },
    contractById: (state) => (id) => state.contracts.find((contract) => contract.id === id),
  },

  mutations: {
    setContracts(state, contracts) {
      state.contracts = contracts;
    },
    setContract(state, contract) {
      state.contract = contract;
    },
  },

  actions: {
    async getContracts({ commit }) {
      const { data } = await axios.get('/api/contracts');

      commit('setContracts', data);
    },
    async postContract({ dispatch }, contract) {
      // this.newContract.expiring_date = dayjs(this.expiringDate).format('YYYY-MM-DD');
      // axios.post('/api/contracts', this.newContract).then(() => {
      //   window.location.href = '/contracts';
      // });
      const { data } = await axios.post('/api/contracts', contract);
      await dispatch('getContracts');
      dispatch('setContract', data);
      return data;
    },
    async putContract({ dispatch }, contract) {
      const { id, payload } = contract;
      const { data } = await axios.put(`/api/contracts/${id}`, payload);
      await dispatch('getContracts');
      return data;
    },
    setContract({ commit }, contract) {
      commit('setContract', contract);
    },
    async generateContract({ commit }, hash) { //eslint-disable-line
      const { data } = await axios.get(`/api/generate-contract/${hash}`);
      return data;
    },
    async resendEmails({ commit }, payload) { //eslint-disable-line
      const { data } = await axios.post('/api/email/send', payload);
      return data;
    },
    async getOutstandingSubscriptions() {
      const { data } = await axios.get('/api/contracts/outstanding-subs');
      return data;
    },
    async getInvoices({ commit }, id) { // eslint-disable-line
      const { data } = await axios.get(`/api/contract/${id}/invoices`);
      return data;
    },
  },
};
