<template>
  <form class="flex flex-col text-gray-700 dark:text-white font-thin" @submit.prevent="saveAccessory">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-1">
      <base-input :label="$t('accessories.itemCode')" v-model="item_code" required="true" />
      <base-currency-input :label="$t('accessories.price')" v-model="price" required="true" />
    </div>
    <div for="translation-input" class="text-sm md:text-base">
      {{ $t("accessories.description") }}
    </div>
    <translation-input
      name="translation-input"
      :label="$t('accessories.description')"
      v-model="translations.description.en"
      lang="en"
      @blur="updateLine"
      required="true"
      label-style="bg-violet-300"
    />
    <translation-input
      :label="$t('product-lines.line')"
      v-model="translations.description.it"
      lang="it"
      required="true"
      label-style="bg-violet-300"
    />
    <base-button extra-class="border-violet-400 text-violet-400 hover:bg-violet-300 self-end mt-3">
      {{ $t('accessories.save') }}
    </base-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
import BaseInput from '../base/BaseInput.vue';
import TranslationInput from '../base/TranslationInput.vue';
import BaseCurrencyInput from '../base/BaseCurrencyInput.vue';
import BaseButton from '../base/BaseButton.vue';

export default {
  components: {
    BaseInput,
    BaseCurrencyInput,
    BaseButton,
    TranslationInput,
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  data() {
    return {
      item_code: '',
      description: '',
      price: 0,
      translations: {
        description: {
          en: '',
          it: '',
        },
      },
    };
  },

  methods: {
    ...mapActions('accessories', ['getAccessories', 'postAccessory']),
    async saveAccessory() {
      await this.postAccessory({ ...this.$data });
      await this.getAccessories();

      this.toast.success(this.$t('accessories.accessory_added'));
      this.$emit('close');
    },
    updateLine() {
      this.description = this.translations.description.en;
    },
  },
};
</script>
