import axios from 'axios';

export default {

  namespaced: true,

  state: {
    contractTemplates: [],
    contractTemplate: {},
  },

  getters: {
    contractTemplates: (state) => state.contractTemplates,
    contractTemplate: (state) => state.contractTemplate,
  },

  mutations: {
    setContractTemplates(state, contractTemplates) {
      state.contractTemplates = contractTemplates;
    },
    setContractTemplate(state, contractTemplate) {
      state.contractTemplate = contractTemplate;
    },
  },

  actions: {
    async getContractTemplates({ commit }) {
      const { data } = await axios.get('/api/contract-templates');

      commit('setContractTemplates', data);
    },
    async postContractTemplate({ dispatch }, template) {
      const { data } = await axios.post('/api/contract-templates', template);
      await dispatch('getContractTemplates');
      return data;
    },
    async putContractTemplate({ dispatch }, template) {
      const { id, ...payload } = template;
      const { data } = await axios.put(`/api/contract-templates/${id}`, payload);
      await dispatch('getContractTemplates');
      return data;
    },
    setContractTemplate({ commit }, contractTemplate) {
      commit('setContractTemplate', contractTemplate);
    },
  },
};
