<template>
  <div class="w-full flex">
    <div class="px-1 lg:px-6 py-3 text-sm flex w-full">
      <card-logo-and-numbers :method="method" />
      <div v-if="method.type === 'card'" class="w-1/5">
        <span v-if="!expired">
          {{getFormattedMonth(method.card.exp_month)}}/{{method.card.exp_year}}
        </span>
        <span v-else>{{ $t('contracts.landingPages.expired') }}</span>
      </div>
    </div>
    <!-- <button class="hover:text-b2c-red-300 w-3 px-4 md:px-6" @click="openAreYouSure" title="delete">
      <delete-icon />
    </button> -->
  </div>
</template>

<script>
// import AreYouSureToDeleteCard from '@/components/graphics/AreYouSureToDeleteCard.vue';
// import DeleteIcon from './DeleteIcon.vue';
import CardLogoAndNumbers from './CardLogoAndNumbers.vue';

export default {
  props: ['method'],

  data() {
    return {
      expired: false,
    };
  },

  components: {
    CardLogoAndNumbers,
    // DeleteIcon,
  },

  created() {
    this.checkDate();
  },

  methods: {
    checkDate() {
      if (this.method.type !== 'card') return;
      const now = new Date();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();
      if (this.method.card.exp_year < year) {
        this.expired = true;
        return;
      }
      if (this.method.card.exp_year === year && this.method.card.exp_month < month) {
        this.expired = true;
      }
    },

    // openAreYouSure() {
    //   this.$modal.show(AreYouSureToDeleteCard, { id: this.card.id }, { height: 'auto', adaptive: true });
    // },

    getFormattedMonth(month) {
      if (month < 9) return `0${month}`;
      return month;
    },
  },

};
</script>
