<!-- eslint-disable camelcase -->
<template>
  <div class="flex flex-col font-thin">
    <div class="flex font-semibold space-x-1">
      <icon-button
        :title="$t('buttons.edit')"
        @click="$emit('edit')"
        extra-class="bg-blue-200 border-blue-500 hover:text-blue-300 dark:hover:text-slate-600 dark:bg-transparent
          dark:text-blue-300 dark:hover:bg-blue-200">
        <edit-icon class="w-4" />
      </icon-button>
      <icon-button
        :title="$t('buttons.createContract')"
        @click="$emit('createContract', customer)"
        extra-class="bg-blue-200 border-blue-500 hover:text-blue-300 dark:hover:text-slate-600 dark:bg-transparent
          dark:text-blue-300 dark:hover:bg-blue-200">
        <share-icon class="w-4" />
      </icon-button>
      <p class="py-1.5 px-3 w-fit rounded-t-md bg-blue-200 border border-blue-500 border-b-0 text-blue-500
      dark:bg-transparent dark:text-blue-300">
        {{ customerInfo.company }}
      </p>
    </div>
    <div class="grid grid-cols-8 p-4 rounded-b-md rounded-tr-md border border-blue-500 dark:text-gray-100">
      <div class="col-span-2">
        <p>
          {{ customerInfo.billing_address.address }}
        </p>
        <p>
          {{ customerInfo.billing_address.city }}
        </p>
      </div>
      <div class="col-span-3 text-right">
        <p>{{ customerInfo.email }}</p>
        <p>{{ customerInfo.billing_address.phone ? customerInfo.billing_address.phone : "No phone number" }}</p>
      </div>
      <div class="col-span-3 text-right">
        <p>{{ customerInfo.panthera_customer_id }}</p>
        <p v-show="isCustomerIndividual">{{ customerInfo.tax_number }}</p>
        <p v-show="isCustomerLegal">{{ customerInfo.vat_id }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EditIcon from '@/components/graphics/EditIcon.vue';
import ShareIcon from '@/components/graphics/ShareIcon.vue';
import IconButton from '@/components/base/IconButton.vue';

export default {
  components: {
    EditIcon,
    ShareIcon,
    IconButton,
  },
  data() {
    return {
      customerInfo: {
        id: null,
        type: null,
        company: '',
        email: '',
        panthera_customer_id: '',
        tax_number: '',
        vat_id: '',
        billing_address: {
          address: '',
          postcode: '',
          city: '',
          state: '',
          country: '',
          phone: '',
        },
      },
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.refreshcustomerInfo(this.customer);
  },
  watch: {
    customer: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.refreshcustomerInfo(newValue);
        }
      },
    },
  },
  computed: {
    isCustomerIndividual() {
      return this.customer.type === 'F';
    },
    isCustomerLegal() {
      return this.customer.type === 'G';
    },
  },
  methods: {
    ...mapActions('customers', ['getCustomers']),
    refreshcustomerInfo(data) {
      this.customerInfo = (({
        // eslint-disable-next-line camelcase
        id, company, type, firstname, lastname, email, billing_address, tax_number, vat_id, panthera_customer_id,
      }) => ({
        id, company, type, firstname, lastname, email, billing_address, tax_number, vat_id, panthera_customer_id,
      }))(data);
    },
  },
};
</script>
