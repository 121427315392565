<template>
  <custom-modal
  ref="generateToken"
  extra-class="bg-red-500 text-white/90 font-medium fill-white/90"
  container-class="w-3/4 xl:w-1/2">
    <template v-slot:header>
      {{ $t('user.create-key') }}
    </template>
    <template v-slot:body>
      <token-generator @close="[$refs.generateToken.closeModal(), initData()]"/>
    </template>
  </custom-modal>

  <p class="text-base lg:text-lg font-medium">{{ $t('user.your-api-keys') }}</p>
  <p>{{ $t('user.keys-explanation') }}</p>
  <base-button extra-class="border-red-500 hover:bg-red-500 text-red-500 my-3"
    @click="$refs.generateToken.openModal()">
    {{ $t('user.new-key') }}
  </base-button>
  <div class="grid grid-cols-7 gap-x-4 border-b border-gray-300 font-normal py-1 px-3">
    <p class="col-span-2">{{ $t('user.name') }}</p>
    <p class="col-span-2">{{ $t('user.created_at') }}</p>
    <p class="col-span-2">{{ $t('user.last_used') }}</p>
  </div>
  <div v-for="token in tokens" :key="token"
  class="grid grid-cols-7 gap-x-4 py-1 px-3 items-center even:bg-gray-200 odd:bg-gray-50
  dark:odd:bg-slate-500 dark:even:bg-slate-500 dark:border dark:border-slate-600">
    <p class="col-span-2">
      {{ token.name }}
    </p>
    <p class="col-span-2">
      {{ $d(token.created_at, 'short') }}
    </p>
    <p class="col-span-2">
      {{ token.last_used_at === null ? `${$t('user.never_used')}` : `${$d(token.last_used_at, 'short')}` }}
    </p>
    <button @click="revokeToken(token)" class="col-span-1 flex justify-end"><delete-icon class="w-4"/></button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomModal from '../base/CustomModal.vue';
import TokenGenerator from './TokenGenerator.vue';
import BaseButton from '../base/BaseButton.vue';
import DeleteIcon from '../graphics/DeleteIcon.vue';

export default {
  components: {
    CustomModal,
    TokenGenerator,
    BaseButton,
    DeleteIcon,
  },

  data() {
    return {
      tokens: [],
    };
  },

  props: {
    property: {
      type: Promise,
    },
  },

  async created() {
    await this.property;
    await this.initData();
  },

  methods: {
    ...mapActions('auth', ['getUserTokens', 'revokeUserToken']),
    async initData() {
      this.tokens = await this.getUserTokens();
    },
    async revokeToken(token) {
      await this.revokeUserToken(token.id);
      this.tokens = await this.getUserTokens();
    },
  },
};
</script>
