<template>
  <div class="hidden h-screen font-thin text-gray-500 w-1/4 xl:w-1/5 2xl:w-1/6
      dark:bg-slate-700 dark:text-white/90 shadow-menu lg:grid lg:grid-rows-5">
    <div class="row-span-2 flex items-center">
      <router-link to="/dashboard" class="dark:hidden flex w-2/3 mx-auto">
        <img src="@/assets/logo-sonar.png" alt="Logo Sonar">
      </router-link>
      <router-link to="/dashboard" class="hidden dark:flex w-2/3 mx-auto">
        <img src="@/assets/logo-sonar-white.png" alt="Logo Sonar">
      </router-link>
    </div>
    <div class="pl-10 row-span-3">
      <base-accordion>
        <template v-slot:title>
          <router-link to="/dashboard" class="link dashboard">
            {{ $t('dashboard.dashboard') }}
          </router-link>
        </template>
      </base-accordion>
      <base-accordion>
        <template v-slot:title>
          <router-link to="/customers" class="link customers">
            {{ $t('customers.customers') }}
          </router-link>
        </template>
        <!-- <template v-slot:content>
          <a class="link customers">
            {{ $t('customers.tabs.payments') }}
          </a>
        </template> -->
      </base-accordion>
      <base-accordion>
        <template v-slot:title>
          <router-link to="/goods" class="link goods">
            {{ $t('goods.goods') }}
          </router-link>
        </template>
        <template v-slot:content>
          <router-link to="/harps" class="link goods">
            {{ $t('harps.harps') }}
          </router-link>
          <div class="flex flex-col ml-4">
            <router-link to="/product-lines" class="link goods">
              {{ $t('harps.product-lines') }}
            </router-link>
            <router-link to="/strings-layouts" class="link goods">
              {{ $t('harps.strings-layouts') }}
            </router-link>
            <router-link to="/harp-colors" class="link goods">
              {{ $t('harps.harp-colors') }}
            </router-link>
            <router-link to="/accessories" class="link goods">
              {{ $t('harps.harp-accessories') }}
            </router-link>
          </div>
        </template>
      </base-accordion>
      <base-accordion>
        <template v-slot:title>
          <router-link to="/contracts" class="link contracts">
            {{ $t('contracts.contracts') }}
          </router-link>
        </template>
        <template v-slot:content>
          <router-link to="/contract-templates" class="link contracts">
            {{ $t('contract_templates.contract_templates') }}
          </router-link>
        </template>
      </base-accordion>
    </div>
  </div>

  <button @click="toggleAccordion" class="z-50 p-6 absolute lg:hidden">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 17" class="w-5 dark:fill-white">
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect class="cls-1" x="1" y="1" width="18" height="1" rx="0.38" />
          <rect class="cls-1" x="1" y="15" width="29" height="1" rx="0.38" />
          <rect class="cls-1" x="1" y="8" width="23" height="1" rx="0.38" />
        </g>
      </g>
    </svg>
  </button>
  <transition class="z-30" name="slide-fade">
    <div class="flex w-screen h-screen m-auto fixed inset-0" v-if="isOpen">
      <div class="h-screen w-full md:w-1/2 lg:w-1/4 bg-white dark:bg-gray-800 dark:text-white
      shadow-inner transition grid grid-rows-5 font-thin text-gray-500">
        <div class="row-span-2 flex items-center">
          <router-link to="/dashboard" class="dark:hidden flex w-2/3 mx-auto">
            <img src="@/assets/logo-sonar.png" alt="Logo Salvi Music">
          </router-link>
          <router-link to="/dashboard" class="hidden dark:flex w-2/3 mx-auto">
            <img src="@/assets/logo-sonar-white.png" alt="Logo Salvi Music">
          </router-link>
        </div>
        <div class="pl-10 row-span-3">
          <base-accordion>
            <template v-slot:title>
              <router-link to="/dashboard" class="link dashboard" @click.prevent="closeMenu()">
                {{ $t('dashboard.dashboard') }}
              </router-link>
            </template>
          </base-accordion>
          <base-accordion>
            <template v-slot:title>
              <router-link to="/customers" class="link customers" @click.prevent="closeMenu()">
                {{ $t('customers.customers') }}
              </router-link>
            </template>
            <!-- <template v-slot:content>
              <a class="link customers">
                {{ $t('customers.tabs.payments') }}
              </a>
            </template> -->
          </base-accordion>
          <base-accordion>
            <template v-slot:title>
              <router-link to="/goods" class="link goods" @click.prevent="closeMenu()">
                {{ $t('goods.goods') }}
              </router-link>
            </template>
            <template v-slot:content>
              <router-link to="/harps" class="link goods" @click.prevent="closeMenu()">
                {{ $t('harps.harps') }}
              </router-link>
              <div class="flex flex-col">
                <router-link to="/product-lines" class="link goods" @click.prevent="closeMenu()">
                  {{ $t('harps.product-lines') }}
                </router-link>
                <router-link to="/strings-layouts" class="link goods" @click.prevent="closeMenu()">
                  {{ $t('harps.strings-layouts') }}
                </router-link>
                <router-link to="/harp-colors" class="link goods" @click.prevent="closeMenu()">
                  {{ $t('harps.harp-colors') }}
                </router-link>
                <router-link to="/accessories" class="link goods" @click.prevent="closeMenu()">
                  {{ $t('harps.harp-accessories') }}
                </router-link>
              </div>
            </template>
          </base-accordion>
          <base-accordion>
            <template v-slot:title>
              <router-link to="/contracts" class="link contracts" @click.prevent="closeMenu()">
                {{ $t('contracts.contracts') }}
              </router-link>
            </template>
            <template v-slot:content>
              <router-link to="/contract-templates" class="link contracts" @click.prevent="closeMenu()">
                {{ $t('contract_templates.contract_templates') }}
              </router-link>
            </template>
          </base-accordion>
        </div>
      </div>
      <button class="w-0 md:w-1/2 h-screen backdrop-blur-md lg:hidden cursor-default" @click="closeMenu()"></button>
    </div>
  </transition>
</template>

<script>
import BaseAccordion from './BaseAccordion.vue';

export default {
  components: {
    BaseAccordion,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    closeMenu() {
      this.isOpen = false;
    },

    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.shadow-menu {
  box-shadow: inset 0 0 10px 0 rgba(31, 31, 31, 0.2);
}

.link {
  @apply bg-[length:0_2px] w-fit bg-left-bottom bg-no-repeat transition-all
  duration-300 ease-in-out text-stone-600 dark:text-white rounded
}

.router-link-active.link {
  @apply bg-[length:100%_2px] bg-left-bottom font-light my-1 px-0.5
}

.dashboard {
  @apply bg-gradient-to-r from-red-500 to-red-500
}

.customers {
  @apply bg-gradient-to-r from-blue-400 to-blue-400
}

.goods {
  @apply bg-gradient-to-r from-violet-400 to-violet-400
}

.contracts {
  @apply bg-gradient-to-r from-emerald-400 to-emerald-400
}
</style>
