import axios from 'axios';

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },

    isSuperAdmin(state) {
      return state.user.is_super_admin;
    },

    user(state) {
      return state.user;
    },

    userPermissions(state) {
      return state.user.get_permissions_via_company_roles.map(({ name }) => name);
    },
  },

  mutations: {
    setAuthenticated(state, value) {
      state.authenticated = value;
    },

    setUser(state, value) {
      state.user = value;
    },
  },

  actions: {
    async register({ dispatch }, credentials) {
      await axios.post('/register', credentials);
      return dispatch('me');
    },

    async signIn({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie');
      await axios.post('/login', credentials);

      return dispatch('me');
    },

    async signOut({ dispatch }) {
      await axios.post('/logout');

      dispatch('clearCredentials');
    },

    clearCredentials({ commit }) {
      commit('setAuthenticated', false);
      commit('setUser', null);
    },

    me({ commit, dispatch }) {
      return axios.get('/api/user')
        .then(({ data }) => {
          commit('setAuthenticated', true);
          commit('setUser', data);
        }).catch(() => {
          dispatch('clearCredentials');
        });
    },

    auth({ commit }) {
      return axios.get('/api/user')
        .then(({ data }) => {
          commit('setUser', null);
          commit('setAuthenticated', true);
          commit('setUser', data);
        }).catch(() => {
          commit('setAuthenticated', false);
          commit('setUser', null);
        });
    },

    async postRequestBearerToken({ state }, name) {
      const { data } = await axios.post(`/api/users/${state.user.id}/request-token`, { name });
      return data.token;
    },

    async getUserTokens({ state }) {
      const { data } = await axios.get(`/api/users/${state.user.id}/tokens`);
      return data;
    },

    async revokeUserToken({ state }, id) {
      await axios.delete(`/api/users/${state.user.id}/${id}/revoke`, { id });
    },
    async forgotPassword({ state }, email) { // eslint-disable-line
      const { data } = await axios.post('/forgot-password', { email });
      return data;
    },
    async resetPassword({ state }, payload) { // eslint-disable-line
      const { data } = await axios.post('/reset-password', payload);
      return data;
    },
  },

};
