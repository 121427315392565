<template>
  <label for="select-country" class="grid text-sm md:text-base">
    <span>{{ $t("customers.country") }} <span v-if="this.$attrs.required" class="text-red-400">*</span></span>
    <div class="relative flex items-center rounded-md">
      <select
      class="rounded-md p-2 w-full border border-gray-400 dark:border-gray-100 appearance-none bg-transparent"
        id="select-country"
        v-bind="{
          ...$attrs,
          onChange: ($event) => {
            $emit('update:modelValue', $event.target.value);
          },
        }"
        :value="modelValue"
        :class="inputStyles"
      >
        <option :value="null" disabled>
          {{ $t("customers.selectCountry") }}
        </option>
        <option
          v-for="country in countries"
          :key="country.code"
          :value="country.code.toUpperCase()"
        >
          {{ country.name }}
        </option>
      </select>
      <div class="absolute right-0 pointer-events-none pr-3">
        <dropdown-arrow class="w-3 md:w-5" />
      </div>
    </div>
  </label>
</template>

<script>
import countriesList from '../../config/countries-list';
import DropdownArrow from '../graphics/DropdownArrow.vue';

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabledClasses: {
      type: String,
      default: 'cursor-not-allowed bg-slate-200 dark:bg-slate-500 border-none',
    },
  },

  components: {
    DropdownArrow,
  },

  data() {
    return {
      countries: countriesList[this.$i18n.locale],
    };
  },
  computed: {
    inputStyles() {
      return `${this.$attrs.disabled ? this.disabledClasses : ''}`;
    },
  },
};
</script>
