<template>
  <color-banner bg-style="bg-emerald-300"></color-banner>
  <div class="w-full pb-20">
    <custom-modal ref="addContractTemplate" extra-class="bg-emerald-300 text-white/90 font-medium fill-white/90">
      <template v-slot:header>
        {{ $t('contract_templates.newTitle') }}
      </template>
      <template v-slot:body>
        <add-contract-template @template-has-been-created="onTemplateHasBeenCreated" />
      </template>
    </custom-modal>
    <custom-modal ref="editContractTemplate" extra-class="bg-emerald-300 text-white/90 font-medium fill-white/90">
      <template v-slot:header>
        {{ $t('contract_templates.editTitle') }}
      </template>
      <template v-slot:body>
        <edit-contract-template
          :template="selectedTemplate"
          @close="$refs.editContractTemplate.closeModal()"
          @template-has-been-updated="onTemplateHasBeenUpdated"
        />
      </template>
    </custom-modal>
    <base-container>
      <template v-slot:buttons>
        <base-button
          @click="$refs.addContractTemplate.openModal()" :title="$t('buttons.addNew')"
          v-show="isAbleToCreateStripeProduct"
          extra-class="fill-white hover:fill-emerald-400 bg-emerald-200 border-emerald-500 hover:bg-transparent
          hover:fill-emerald-300 dark:bg-transparent dark:fill-emerald-300 dark:hover:bg-emerald-300 dark:hover:fill-slate-600">
          <add-icon />
        </base-button>
      </template>
      <template v-slot:title>
        {{ $t('contract_templates.contract_templates') }}
      </template>
      <template v-slot:body>
        <contract-template-row
          @edit="openEditModal(template)"
          v-for="template in contractTemplates"
          :key="template.id"
          :template="template"
          class="mb-5"
        />
      </template>
    </base-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddContractTemplate from '../components/contracts/AddContractTemplate.vue';
import ContractTemplateRow from '../components/contracts/ContractTemplateRow.vue';
import EditContractTemplate from '../components/contracts/EditContractTemplate.vue';
import CustomModal from '../components/base/CustomModal.vue';
import BaseContainer from '../components/base/BaseContainer.vue';
import ColorBanner from '../components/graphics/ColorBanner.vue';
import BaseButton from '../components/base/BaseButton.vue';
import AddIcon from '../components/graphics/AddIcon.vue';

export default {

  components: {
    CustomModal,
    AddContractTemplate,
    ContractTemplateRow,
    EditContractTemplate,
    BaseContainer,
    ColorBanner,
    BaseButton,
    AddIcon,
  },

  async created() {
    await this.auth();
    await this.getContractTemplates();
    await this.getProductLines();
    await this.getHarps();
  },

  data() {
    return {
      selectedTemplate: null,
    };
  },

  computed: {
    ...mapGetters('contractTemplates', ['contractTemplates']),
    ...mapGetters('auth', ['user', 'isSuperAdmin']),
    // TODO: Remove the below computed property (and all his references) when the application will be able to create
    //       a corresponding Stripe Product during the Contract Template creation.
    isAbleToCreateStripeProduct() {
      return this.user ? this.isSuperAdmin : false;
    },
  },

  methods: {
    ...mapActions('auth', ['auth']),
    ...mapActions('harps', ['getHarps']),
    ...mapActions('productLines', ['getProductLines']),
    ...mapActions('contractTemplates', ['getContractTemplates']),
    onTemplateHasBeenCreated() {
      this.$refs.addContractTemplate.closeModal();
    },
    onTemplateHasBeenUpdated() {
      this.$refs.editContractTemplate.closeModal();
    },
    openEditModal(template) {
      this.selectedTemplate = template;
      this.$refs.editContractTemplate.openModal();
    },
  },
};
</script>
