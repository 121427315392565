<template>
  <div class="flex flex-col font-thin bg-transparent">
    <div class="flex font-semibold space-x-1">
      <icon-button
        :title="$t('buttons.edit')"
        @click="$emit('edit')"
        v-if="isAbleToCreateStripeProduct"
        extra-class="bg-emerald-200 border-emerald-500 hover:bg-transparent hover:text-emerald-300
        dark:text-emerald-300 dark:hover:bg-emerald-200 dark:hover:text-slate-600 dark:bg-transparent"
      >
        <edit-icon class="w-4" />
      </icon-button>
      <p
        class="py-1.5 px-3 w-fit rounded-t-md bg-emerald-200 text-emerald-500
        border border-b-0 border-emerald-500 dark:text-emerald-300 dark:bg-transparent"
      >
        {{ template.description }}
      </p>
    </div>
    <div
      class="grid grid-cols-4 gap-x-4 p-4 rounded-b-md rounded-tr-md border border-emerald-500 dark:text-gray-100"
    >
      <div class="col-span-2 font-thin capitalize">
        <p>
          <span>{{ $t("contract_templates.rule_good_status") }}:</span>
          {{ goodStatus }}
        </p>
        <p>
          <span>{{ $t("contract_templates.fee") }}:</span>
          {{ $n(template.fee / 100, "currency") }}
        </p>
        <p>
          <span>{{ $t('contract_templates.shipping_costs') }}:</span>
          {{ $n(template.shipping_costs / 100, "currency") }}
        </p>
        <p>
          <span>{{ $t("contract_templates.deposit") }}:</span>
          {{ $n(template.deposit / 100, "currency") }}
        </p>
      </div>
      <div class="col-span-2">
        <p class="font-medium">{{ $t("contract_templates.harps_related") }}</p>
        <ul
          class="grid gap-x-4 font-thin"
          :class="totalHarps >= 5 ? 'grid-cols-2' : 'grid-cols-1'"
        >
          <li v-for="harp in template.harps" :key="harp.id">
            {{ harp.description }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EditIcon from '../graphics/EditIcon.vue';
import IconButton from '../base/IconButton.vue';

export default {
  components: {
    EditIcon,
    IconButton,
  },
  data() {
    return {
      totalHarps: 0,
    };
  },
  created() {
    this.totalHarps = this.template.harps.length - 1;
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin']),
    // TODO: Remove the below variable (and all his references) when the application will be able to create
    //       a corresponding Stripe Product during the Contract Template creation.
    isAbleToCreateStripeProduct() {
      return this.isSuperAdmin;
    },
    goodStatus() {
      const goodStates = {
        all: this.$t('contract_templates.good_status_all'),
        new: this.$t('contract_templates.good_status_new'),
        used: this.$t('contract_templates.good_status_used'),
      };
      return goodStates[this.template.rule_good_status];
    },
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
span {
  font-weight: 500;
}
</style>
