<template>
  <div class="grid grid-cols-2 gap-x-3 overflow-hidden rounded-md border border-violet-500 pr-2
  items-center font-thin dark:text-white text-center">
    <p class="bg-violet-200 dark:bg-transparent p-3 text-violet-500 dark:text-violet-300 font-semibold
    border-r border-violet-500">
      {{ line.slug }}
    </p>
    <p>
      {{ line.line }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    line: {
      type: Object,
      required: true,
    },
  },
};
</script>
