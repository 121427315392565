import axios from 'axios';

export default {

  namespaced: true,

  state: {
    accessories: [],
    accessory: {},
  },

  getters: {
    accessories(state) {
      return state.accessories;
    },
    accessory(state) {
      return state.accessory;
    },
  },

  mutations: {
    setAccessories(state, accessories) {
      state.accessories = accessories;
    },
    setAccessory(state, accessory) {
      state.accessory = accessory;
    },
  },

  actions: {
    async getAccessories({ commit }) {
      const { data } = await axios.get('/api/accessories');

      commit('setAccessories', data);
    },
    async postAccessory({ commit }, accessory) { // eslint-disable-line
      await axios.post('/api/accessories', accessory);
    },
    async putAccessory({ commit }, accessory) { // eslint-disable-line
      const { id, created_at: _1, updated_at: _2, ...remaining } = accessory; // eslint-disable-line
      await axios.put(`/api/accessories/${id}`, remaining);
    },
    setAccessory({ commit }, accessory) {
      commit('setAccessory', accessory);
    },
  },
};
