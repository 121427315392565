import axios from 'axios';

export default {

  namespaced: true,

  state: {
    productLines: [],
  },

  getters: {
    productLines: (state) => state.productLines,
  },

  mutations: {
    setProductLines(state, productLines) {
      state.productLines = productLines;
    },
  },

  actions: {
    async getProductLines({ commit }) {
      const { data } = await axios.get('/api/product-lines');

      commit('setProductLines', data);
    },
    async postProductLine({ dispatch }, payload) {
      await axios.post('/api/product-lines', payload);
      await dispatch('getProductLines');
    },
  },
};
