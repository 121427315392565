<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <div class="flex flex-col">
    <label class="text-sm md:text-base">
    <span>{{ label }} <span v-if="this.$attrs.required" class="text-red-400">*</span></span>
    </label>
    <div class="relative flex items-center">
      <select
        v-bind="{
          ...$attrs,
          onChange: ($event) => {
            $emit('update:modelValue', $event.target.value);
          },
        }"
        class="border rounded-md px-2 md:px-4 w-full appearance-none cursor-pointer py-2 bg-transparent text-sm md:text-base"
        :value="modelValue"
        :class="borderClasses"
      >
        <option
          class="font-sans-serif font-normal capitalize"
          v-for="option in options"
          :key="option[valueField]"
          :value="option[valueField]"
        >
          {{ option[descriptionField] }}
        </option>
      </select>
      <div class="absolute right-0 pointer-events-none pr-3">
        <dropdown-arrow class="w-3 md:w-5" />
      </div>
    </div>
  </div>
</template>

<script>
import DropdownArrow from '../graphics/DropdownArrow.vue';

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    valueField: {
      type: String,
      required: true,
    },
    descriptionField: {
      type: String,
      required: true,
    },
    options: {
      required: true,
    },
    borderClasses: {
      type: String,
      default: 'border-gray-400 dark:border-gray-100',
    },
  },
  components: {
    DropdownArrow,
  },
};
</script>
