<template>
    <div>
        <svg id="Amex" xmlns="http://www.w3.org/2000/svg" width="27.321" height="27.322" viewBox="0 0 27.321 27.322">
            <path id="Path_62" data-name="Path 62" class="cls-1" d="M207,331.89h27.321v27.322H207V331.89Z"
                  transform="translate(-207 -331.89)"/>
            <path id="Path_63" data-name="Path 63" class="cls-2"
                  d="M272.206,428.7v-9.79l17.424.016v2.7l-2.014,2.152,2.014,2.172v2.762h-3.216l-1.709-1.886-1.7,1.893-10.8-.023Z"
                  transform="translate(-262.309 -405.702)"/>
            <path id="Path_64" data-name="Path 64" class="cls-1"
                  d="M279.874,433.648v-7.639h6.477v1.76h-4.38v1.195h4.276v1.731h-4.276v1.173h4.38v1.781Z"
                  transform="translate(-268.813 -411.723)"/>
            <path id="Path_65" data-name="Path 65" class="cls-1"
                  d="M322.334,433.649l3.584-3.824-3.584-3.815h2.774l2.19,2.421,2.2-2.421h2.655v.06l-3.508,3.755,3.508,3.715v.109h-2.682l-2.229-2.446-2.206,2.446Z"
                  transform="translate(-304.828 -411.724)"/>
            <path id="Path_66" data-name="Path 66" class="cls-2"
                  d="M253.454,361.664h4.2l1.475,3.35v-3.35h5.185l.894,2.51.9-2.51h3.959v9.79H249.14l4.314-9.79Z"
                  transform="translate(-242.744 -357.145)"/>
            <path id="Path_67" data-name="Path 67" class="cls-1"
                  d="M263.858,368.671l-3.389,7.633h2.325l.639-1.528H266.9l.639,1.528h2.383l-3.375-7.633Zm.291,4.391,1.016-2.43,1.016,2.43Z"
                  transform="translate(-252.353 -363.088)"/>
            <path id="Path_68" data-name="Path 68" class="cls-1"
                  d="M322.523,376.3v-7.633l3.268.011,1.681,4.692,1.693-4.7h3.145V376.3l-2.024.018V371.07l-1.91,5.225h-1.847l-1.95-5.243V376.3Z"
                  transform="translate(-304.988 -363.081)"/>
        </svg>
    </div>
</template>

<style scoped>
.cls-1 {
  fill: #005aa6;
}
.cls-1, .cls-2 {
  fill-rule: evenodd;
}
.cls-2 {
  fill: #fff;
}

</style>
