<!-- eslint-disable max-len -->
<template>
  <transition name="fade">
    <div v-if="show" class="fixed flex justify-center xl:justify-end inset-0 bg-gray-800/50
    dark:bg-gray-500/50 z-50 dark:text-white">
      <div class="inset-0 overflow-y-auto" :class="widthClass">
        <div class="mx-auto my-12 flex flex-col" :class="containerClass">
          <div :class="extraClass" class="flex py-2 px-4 md:py-4 md:px-8 rounded-t-lg z-30 w-full justify-between
          uppercase font-light md:text-xl dark:text-slate-600 dark:fill-slate-600">
            <slot name="header"/>
            <button type="button" @click="closeModal()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="w-3 dark:fill-slate-600">
                <path
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </button>
          </div>

          <div class="z-30 p-4 md:p-8 bg-white dark:bg-slate-600 rounded-b-lg">
            <slot name="body"/>
          </div>

          <div class="z-30">
            <slot name="footer"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomModal',
  props: {
    containerClass: {
      type: String,
      default: 'w-5/6 xl:w-3/4',
    },
    extraClass: {
      type: String,
      default: '',
    },
    widthClass: {
      type: String,
      default: 'w-screen lg:w-5/6',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
      this.$emit('close');
    },
    openModal() {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    },
  },
};
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
