<template>
  <label :for="nameToKebab" :class="labelStyle">
    <span>{{ label }} <span v-if="this.$attrs.required" class="text-red-400">*</span></span>
    <div class="relative flex items-center">
      <textarea
        :rows="rows"
        :cols="cols"
        autocomplete="off"
        v-bind="{
          ...$attrs,
          onInput: ($event) => {
            $emit('update:modelValue', $event.target.value);
          },
          onBlur: ($event) => {
            $emit('blur');
          },
          onChange: ($event) => {
            $emit('change');
          },
          onKeyUp: ($event) => {
            $emit('keyup');
          },
        }"
        class="rounded-md p-2 w-full"
        :id="id ? id : nameToKebab"
        :name="nameToKebab"
        :class="[inputStyles, darkText]"
        :value="modelValue"
      ></textarea>
    </div>
  </label>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    labelStyle: {
      type: String,
      default: 'grid text-sm md:text-base',
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    disabledClasses: {
      type: String,
      default: 'cursor-not-allowed bg-slate-200 dark:bg-slate-500 border border-transparent',
    },
    backgroundClass: {
      type: String,
      default: 'bg-transparent border border-gray-400 dark:border-gray-100',
    },
    rows: {
      type: Number,
      default: 3,
    },
    cols: {
      type: Number,
      defualt: 255,
    },
  },
  computed: {
    inputStyles() {
      return `${this.$attrs.disabled ? this.disabledClasses : this.backgroundClass}`;
    },
    nameToKebab() {
      return this.toKebab(this.label);
    },
    darkText() {
      if (localStorage.getItem('theme') === 'dark') {
        return 'testo-safari-dark';
      }
      return 'testo-safari';
    },
  },
  methods: {
    toKebab(value) {
      return value.split(' ').join('-').toLowerCase();
    },
  },
};
</script>

<style>
.testo-safari {
  -webkit-text-fill-color: black;
  opacity: 1;
}

.testo-safari-dark {
  -webkit-text-fill-color: white;
  opacity: 1
}
</style>
