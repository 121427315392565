<template>
  <div class="rounded-b-lg font-thin text-gray-700 dark:text-white">
    <label for="import">
      {{ $t("importer.importLabel") }}
      <input
        type="file"
        name="import"
        @change="getFile"
        class="w-full text-sm border border-gray-300 rounded-lg cursor-pointer overflow-hidden mt-1 dark:border-gray-100
        file:w-fit file:py-3 file:px-6 file:border-none file:font-semibold file:text-gray-700 file:mr-2"
      />
    </label>
    <div v-show="fileLoaded" class="w-full flex justify-end mt-5">
      <button @click="uploadFile" class="px-4 py-1 rounded-md uppercase font-medium border" :class="buttonStyle" v-bind="$attrs">
        {{ $t("importer.importBtn") }}
      </button>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import axios from 'axios';

export default {

  props: {
    importer: {
      type: String,
      required: true,
    },
    buttonStyle: {
      type: String,
      default: 'button-default',
    },
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  data() {
    return {
      file: null,
      fileLoaded: false,
      uninterceptedAxiosInstance: axios.create(),
    };
  },

  methods: {
    getFile(e) {
      if (!e.target.files.length) return;
      this.file = e.target.files[0]; // eslint-disable-line prefer-destructuring
      this.fileLoaded = true;
    },

    uploadFile() {
      const payload = new FormData();

      payload.append('import_file', this.file);

      this.uninterceptedAxiosInstance
        .post(`/api/excel-importers?importer=${this.importer}`, payload)
        .then(() => {
          this.$emit(`${this.importer}HasBeenImported`);
        })
        .catch(({ response }) => {
          const { data: errors } = response;

          this.toast.error(this.$t('importer.errors.generic'));
          throw errors;
        });
    },
  },
};
</script>

<style>

.button-default {
  @apply border-gray-400 text-gray-400 hover:bg-gray-300 hover:text-white dark:hover:text-slate-600;
}

.button-customer {
  @apply border-blue-400 text-blue-400 hover:bg-blue-300 hover:text-white dark:hover:text-slate-600;
}

.button-goods {
  @apply border-violet-400 text-violet-400 hover:bg-violet-300 hover:text-white dark:hover:text-slate-600;
}
</style>
