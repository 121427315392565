<template>
  <div class="container w-11/12 2xl:w-3/4 mx-auto pb-10">
    <div class="flex flex-col px-12 rounded-2xl h-full items-center">
      <div class="flex w-full items-center z-10">
          <h1
            class="flex z-0 text-xl md:text-2xl lg:text-3xl justify-center
            items-center w-full uppercase text-gray-700 dark:text-gray-100"
          >
            <slot name="title"></slot>
          </h1>
        </div>
      <div v-if="buttonsSlot" class="flex w-full h-10 mb-16 justify-between">
        <div class="flex space-x-2 lg:space-x-4 w-full">
          <slot name="buttons"/>
        </div>
        <!-- <div class="flex h-10 space-x-1 lg:space-x-3 z-10 text-gray-500 dark:text-gray-100">
          <button class="hover:text-gray-800">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="filter"
              class="svg-inline--fa fa-filter w-3 lg:w-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"
              ></path>
            </svg>
          </button>
          <button class="hover:text-gray-800">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sort-amount-up"
              class="svg-inline--fa fa-sort-amount-up w-3 lg:w-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M304 416h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31l-80-96a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.77 160 16 160zm416 0H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64 128H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM496 32H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
              ></path>
            </svg>
          </button>
          <button class="hover:text-gray-800">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sort-amount-down"
              class="svg-inline--fa fa-sort-amount-down w-3 lg:w-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M304 416h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-128-64h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.37 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352zm256-192H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64 128H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM496 32H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
              ></path>
            </svg>
          </button>
        </div> -->
      </div>
      <div class="w-11/12 lg:w-5/6 text-sm 2xl:text-base">
        <slot name="body"/>
      </div>
      <!-- <div class="flex text-grey-rental text-xs justify-center">
        <pagination v-model="page" :records="3" :per-page="2" @paginate="myCallback"/>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonsSlot: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
