<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <div class="flex items-center">
    <input
      type="checkbox"
      name="checkBoxInput"
      :disabled="disabled"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
      class="flex items-center mr-2 appearance-none border rounded-[0.15rem] w-4 h-4
      checked:outline-none checked:after:content-['\2713']"
      :class="inputStyles"
    />
    <label for="checkBoxInput" :class="labelStyle">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    labelStyle: {
      type: String,
      default: 'text-grey-rental text-sm md:text-base dark:text-gray-100',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledClasses: {
      type: String,
      default: 'bg-slate-200 dark:bg-slate-500 border-transparent cursor-not-allowed',
    },
    extraClass: {
      type: String,
      default: 'text-black dark:text-gray-100 checked:border-black border-gray-400 dark:border-white',
    },
  },
  computed: {
    inputStyles() {
      return `${this.disabled ? this.disabledClasses : this.extraClass}`;
    },
  },
};
</script>
