import { createApp } from 'vue';
import Toast, { POSITION, useToast } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import InstantSearch from 'vue-instantsearch/vue3/es';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './css/app.css';

const toast = useToast();

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('locale') ? localStorage.getItem('locale') : process.env.VUE_APP_I18N_LOCALE || 'en';

const toastOptions = {
  icon: false,
  position: POSITION.BOTTOM_LEFT,
};

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(Toast, toastOptions)
  .use(InstantSearch)
  .mount('#app');

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status < 308) return; // ignore redirects
    if (error.response.status === 401
        || error.response.status === 403) { // not authorized or forbidden
      toast.error(app.$t('api-errors.not-authorized'));
      router.push({ path: '/' })
        .then(() => { router.go(0); });
    } else { // Notify customer and throw error
      if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }
      store.state.errors = error.response.data.errors;
    }
    throw error;
  },
);
