export default {
  "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonar è un prodotto de The Nonsense Factory 2023 - "])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
  "warn_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENZIONE!"])},
  "warn_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il record verrà eliminato?"])},
  "warn_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibili"])},
  "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Disponibili"])},
  "noMatchFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna corrispondenza"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento"])},
  "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venduti"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noleggi", "@", "salvimusic.it"])},
  "noDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna data disponibile"])},
  "auth": {
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le credenziali non sono corrette."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non è corretta."])},
    "throttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troppi tentativi di login. Riprova tra :seconds secondi."])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])}
  },
  "forgotPassword": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Dimenticata"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "resetPassword": {
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma Password"])}
  },
  "login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata?"])},
    "log-in-sonar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi a Sonar"])},
    "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci i tuoi noleggi\ncon facilità!"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia password"])},
    "update_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna password"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la nuova password"])}
  },
  "buttons": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MODIFICA"])},
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGGIUNGI NUOVO"])},
    "importExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPORTA DA EXCEL"])},
    "closeContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHIUDI CONTRATTO"])},
    "cancelContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNULLA CONTRATTO"])},
    "contractInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO CONTRATTO"])},
    "createContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREA CONTRATTO"])},
    "goodSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BENE VENDUTO"])}
  },
  "dashboard": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "waiting-to-be-signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di firma"])},
    "currently-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attualmente attivi"])},
    "waiting-for-return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in attesa di rientro"])},
    "due-to-expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in scadenza"])},
    "total-monthly-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totale rate mensili"])},
    "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spediti"])},
    "ready-to-be-shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pronto per la spedizione"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contratti firmati"])},
    "email-viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail visualizzata"])},
    "buy_good_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente vuole acquistare l'arpa"])},
    "stop_rent_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente vuole restituire l'arpa"])},
    "outstanding_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratti con pagamenti in sospeso"])},
    "active_by_18_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratti attivi da almeno 18 mesi"])}
  },
  "contract_templates": {
    "contract_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Contratti"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome identificativo"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del Contratto in mesi"])},
    "newTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un Contract Template"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica di un Contract Template"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template contratto creato con successo"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template contratto aggiornato"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rata"])},
    "fee_basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa base"])},
    "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trimestrale"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mensile"])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giornaliero"])},
    "variable_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E' una rata variabile?"])},
    "vary_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopo quanti cicli varia la rata?"])},
    "next_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossima rata"])},
    "shipping_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributo di spedizione"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchigia"])},
    "harps_related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arpe collegate"])},
    "rule_definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definizione delle regole contrattuali"])},
    "rule_good_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato del Bene"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione?"])},
    "good_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo del bene"])},
    "good_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato del bene"])},
    "select_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona arpa"])},
    "product_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona linea prodotto"])},
    "select_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la famiglia prodotto"])},
    "family_lever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celtiche"])},
    "family_pedal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedali"])},
    "good_group_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famiglia"])},
    "good_group_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea prodotto"])},
    "good_group_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello"])},
    "good_status_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
    "good_status_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
    "good_status_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usato"])},
    "save-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessori"])},
    "select_accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona accessori"])},
    "rental_item_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id Articolo Panthera"])},
    "stripe_product_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id Prodotto Stripe"])},
    "withdrawal_on_the_spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro in loco"])}
  },
  "contracts": {
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratto"])},
    "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratti"])},
    "contractList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Contratti"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati contratto"])},
    "contractNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero contratto"])},
    "workflowStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fase di lavorazione"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
    "pdfUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url PDF"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesi"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indietro"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avanti"])},
    "createPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea PDF"])},
    "uploadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica nuovo PDF"])},
    "noContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessun contratto registrato."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratto creato con successo"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica beni"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il file dei beni:"])},
    "uploadCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica beni da file"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Contratto"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Bene"])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File XLS Mancante"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni importati"])},
    "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Panthera"])},
    "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compilare questo campo"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bene"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
    "harp_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello Arpa"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di aggiornamento"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata"])},
    "month_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo mensile"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo totale"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
    "not_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non attivo"])},
    "select_good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il bene per il contratto"])},
    "select_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il cliente per il contratto"])},
    "another_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un altro cliente"])},
    "another_good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un altro bene"])},
    "another_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un altro template contratto"])},
    "another_shipping_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un altro indirizzo di spedizione"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricola"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo per noleggio"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "select_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il modello di arpa per il bene"])},
    "priceNoZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione! Il prezzo non può essere uguale a 0"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riepilogo"])},
    "orderFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine per"])},
    "was_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il contratto è stato chiuso"])},
    "was_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il contratto è stato annullato"])},
    "resendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riinvia Email"])},
    "emailSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "emailSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il tipo di mail"])},
    "emailRequestSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La richiesta di invio mail è stata eseguita"])},
    "sellActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bene Acquistato/Continua Noleggio"])},
    "selectAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona l'azione"])},
    "execute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esegui"])},
    "actionExecuted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione Eseguita"])},
    "good_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il bene è stato venduto"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti"])},
    "emailTypes": {
      "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettazione contratto"])},
      "collectPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
      "stopRent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicazioni per lo stop del noleggio"])},
      "goodShipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merce spedida"])},
      "dashboardInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sulla Dashboard"])}
    },
    "goodActionList": {
      "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bene Venduto"])},
      "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua Noleggio"])}
    },
    "workflowStates": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
      "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contratto in bozza"])},
      "agreement_created_waiting_to_be_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contratto inviato"])},
      "agreement_email_viewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email contratto ricevuta"])},
      "agreement_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contratto firmato"])},
      "ready_to_be_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arpa pronta per spedizione"])},
      "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arpa spedita"])},
      "buyGoodRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["richiesta d'acquisto"])},
      "stopRentRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["richiesta di restituzione"])},
      "waiting_for_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arpa in attesa di rientro"])},
      "good_returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arpa rientrata"])},
      "returned_good_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arpa in verifica"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contratto chiuso"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contratto annullato"])}
    },
    "landingPages": {
      "registred_payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodi di pagamento registrati"])},
      "confirm_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per aver scelto il nostro servizio di noleggio!"])},
      "rent_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta di noleggio"])},
      "contract_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratto firmato"])},
      "insert_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
      "save_new_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva Metodo di Pagamento"])},
      "next_email_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presto riceverà un'email da Adobe con il contratto, tramite il servizio Adobe Sign potrà facilmente firmare il documento online."])},
      "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buongiorno"])},
      "select_payment_method_please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per aver scelto il nostro servizio di noleggio, questo è l'ultimo passaggio necessario. La preghiamo di compilare la form sottostante con i dati del metodo di pagamento che predilige per procedere."])},
      "select_sepa_payment_method_please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se lo desidera può aggiungere un metodo di pagamento sepa facendo 'click' su 'Sì' e compilando i dati del form che le verrà presentato. Questo diventerà il suo metodo di pagamento preferito."])},
      "card_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare della carta"])},
      "payment_method_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare del metodo di pagamento"])},
      "process_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["procedere al pagamento"])},
      "payment_method_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento inserito con successo!"])},
      "next_email_harp_shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sua richiesta è stata completata! Riceverà un'email quando l'arpa verrà spedita"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaduta"])},
      "already_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie. Abbiamo già ricevuto la tua richiesta e la stiamo processando."])},
      "generate_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore processando la tua richiesta. Per favore contatta il nostro centro di assistenza."])},
      "add_sepa_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi aggiungere un metodo di pagamento sepa ? "])}
    },
    "showContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visualizza contratto"])},
    "reserveFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva i fondi"])},
    "reserveFundSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fondi sono stati correttamente riservati"])},
    "createContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea contratto per questo cliente"])}
  },
  "customers": {
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuale"])},
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entità legale"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
    "sdi_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice SDI"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e Cognome"])},
    "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C/O"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazione"])},
    "defaultAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferito"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "pec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEC"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "noContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessun contratto registrato."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica clienti"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il file dei clienti:"])},
    "uploadCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica clienti da file"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Cliente"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Cliente"])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File XLS Mancante"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti importati"])},
    "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Panthera"])},
    "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare la nazione"])},
    "selectState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare la provincia"])},
    "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compilare questo campo"])},
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di fatturazione"])},
    "shippingAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi di spedizione"])},
    "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di spedizione"])},
    "preferred_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua Preferita"])},
    "addShippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi indirizzo di spedizione"])},
    "editAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Indirizzo"])},
    "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo indirizzo"])},
    "customer_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente aggiunto correttamente"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente non valido"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente modificato correttamente"])},
    "nothingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna Corrispondenza"])},
    "tabs": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratti"])}
    },
    "taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice fiscale"])},
    "vatId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P. IVA"])},
    "validTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice fiscale sembra valido."])},
    "validVatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice IVA sembra corretto."])},
    "biodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anagrafica"])},
    "landing": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiacenti, il link Stripe non è più attivo oppure lei ha già un metodo di pagamento attivo su Stripe"])}
    },
    "panthera_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienti Panthera"])},
    "get_panthera_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cerca codice panthera"])},
    "new_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo cliente Panthera"])},
    "select_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione cliente Panthera"])},
    "customer_not_in_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente che stai cercando non è nella lista?"])},
    "customer_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente che stai cercando non ha trovato riscontro nei nostri registri"])},
    "add_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo cliente Panthera"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conferma"])},
    "toast_create_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cliente Panthera è stato aggiunto correttamente"])},
    "toast_panthera_customer_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente non trovato"])},
    "toast_customer_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente Panthera selezionato correttamente"])},
    "toast_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il servizio ha incontrato un errore"])},
    "duplicated_entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore durante l'inserimento del cliente. Per favore verifica che tu non stia inserendo un cliente già esistente."])},
    "want_to_add_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi creare un contratto per questo cliente ?"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email inserita non è valida"])}
  },
  "goods": {
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "noContracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancora nessun contratto registrato."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica beni"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il file dei beni:"])},
    "uploadCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica beni da file"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Bene"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Bene"])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File XLS Mancante"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni importati"])},
    "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Panthera"])},
    "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compilare questo campo"])},
    "harp_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello Arpa"])},
    "harp_model_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice modello arpa"])},
    "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricola"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione"])},
    "stringsLayoutSlug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome identificativo corde"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo per noleggio"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "select_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il modello di arpa per il bene"])},
    "priceNoZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione! Il prezzo non può essere uguale a 0"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import beni da Excel"])},
    "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni per pagina"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra per descrizione"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beni importati"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bene modificato correttamente"])},
    "inUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Bene è già usato in una altro Ordine di Servizio in Panthera"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Bene può essere usato in Panthera"])},
    "addGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un bene"])},
    "harpDecoder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decodifica i dati dell'arpa"])},
    "insertGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci i dati dell'arpa manualmente"])},
    "decode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decodifica"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usato"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bene aggiunto correttamente"])},
    "allLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le città"])}
  },
  "harp-colors": {
    "addHarpColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi i colori di un'arpa"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi colori di un'arpa"])},
    "harp-colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista dei colori dell'arpa"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colore"])},
    "codeDigit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci codice"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa da Excel"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa i colori dell'arpa da Excel"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colori arpa importati"])},
    "allColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i Colori"])}
  },
  "harps-family": {
    "addHarpsFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi la famiglia di arpe"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi famiglia di arpe"])},
    "harps-families": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista delle famiglie di arpe"])},
    "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famiglia"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome identificativo"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa da Excel"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa le famiglie di arpe da Excel"])},
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa file Excel"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa!"])}
  },
  "harps": {
    "harpsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco Arpe"])},
    "harps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arpe"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuova"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica arpe"])},
    "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il file delle arpe:"])},
    "uploadHarps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica arpe da file"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Arpa"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Arpa"])},
    "noFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File XLS Mancante"])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arpe importate"])},
    "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Panthera"])},
    "fillField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compilare questo campo"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello dell'arpa"])},
    "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famiglia"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "product_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea prodotto"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchio"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo per noleggio"])},
    "select_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la famiglia di arpe"])},
    "select_product_line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la linea prodotto"])},
    "select_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il marchio"])},
    "priceNoZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione! Il prezzo non può essere uguale a 0"])},
    "harps-families": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famiglie di arpe"])},
    "strings-layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione Corde"])},
    "harp-colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colori Arpa"])},
    "harp-accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessori Arpa"])},
    "configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazioni"])},
    "serials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeri di matricola"])},
    "product-lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee prodotto"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Arpe da Excel"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arpa modificata correttamente"])},
    "allModels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i Modelli"])}
  },
  "importer": {
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica il File Excel"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa"])},
    "errors": {
      "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante l'importazione dei dati excel. Riprova dopo aver verificato che i dati che intendi importare siano corretti."])}
    }
  },
  "pagination": {
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&laquo; Precedente"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo &raquo;"])}
  },
  "passwords": {
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password è stata resettata!"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti abbiamo inviato un link per reimpostare la tua password"])},
    "throttled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di attendere prima di riprovare."])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo tentativo di reimpostare la password non è valido."])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non riusciamo a trovare un utente con questo indirizzo email."])}
  },
  "product-lines": {
    "addProductLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi la linea prodotto"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi linea prodotto"])},
    "product-lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista linee prodotto"])},
    "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea prodotto"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome identificativo"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa da Excel"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Linee Prodotto da Excel"])},
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa File Excel"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa!"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea prodotto aggiunta"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee prodotto importate"])}
  },
  "roles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome:"])}
  },
  "strings-layouts": {
    "addStringsFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un Layout delle corde"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Layout delle corde"])},
    "strings-layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Layouts delle corde"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome identificativo"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Importa da Excel"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Layouts delle Corde da Excel"])},
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa File da Excel"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa!"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout corde importati"])},
    "strings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corde"])},
    "allStrings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le Corde"])}
  },
  "accessories": {
    "accessoriesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Accessori"])},
    "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Accessorio"])},
    "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Accessorio"])},
    "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessori"])},
    "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Accessori da Excel"])},
    "itemCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Articolo"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "accessory_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessorio aggiunto correttamente"])},
    "accessory_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accessiorio è stato aggiornato"])},
    "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli accessori sono stati importati"])}
  },
  "user": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salve"])},
    "create-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crea una nuova chiave"])},
    "new-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuova chiave"])},
    "your-api-keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue chiavi API"])},
    "keys-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui hai un elenco delle chiavi API che hai creato, tieni a mente che una volta generate non sono più visibili per motivi di sicurezza."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato il"])},
    "last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo utilizzo il"])},
    "never_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai utilizzato"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ok"])},
    "how-to-add-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per generare una nuova chiave inserisci un nome e poi clicca su \"Genera nuova chiave +\"."])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attenzione!"])},
    "copy-token-please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non dimenticare di copiare la chiave e conservarla in modo sicuro, perché dopo la chiusura di questa finestra non sarà più visualizzabile."])}
  },
  "validation": {
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere accettato."])},
    "accepted_if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere accettato quando :other è :value."])},
    "active_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non è un valido URL."])},
    "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere una data dopo :date."])},
    "after_or_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere una data dopo o uguale a :date."])},
    "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve contenere solo lettere."])},
    "alpha_dash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve contenere solo lettere, numeri, trattini e sottolineature."])},
    "alpha_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve contenere solo lettere e numeri."])},
    "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un array."])},
    "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere una data prima di :date."])},
    "before_or_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere una data prima o uguale a :date."])},
    "between": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere tra :min e :max."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere tra :min e :max kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere tra :min e :max caratteri."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve avere tra :min e :max articoli."])}
    },
    "boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute deve essere vero o falso."])},
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conferma :attribute non corrisponde."])},
    "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non è corretta."])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non è una data valida."])},
    "date_equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere una data uguale a :date."])},
    "date_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non corrisponde al formato :format."])},
    "different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute e :other deve essere diverso."])},
    "digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere :digits digits."])},
    "digits_between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere tra :min e :max digits."])},
    "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute ha le dimensione dell'immagine non valide."])},
    "distinct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute ha un valore duplicato."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un valido indirizzo email."])},
    "ends_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve terminare con uno dei seguenti: :values."])},
    "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il selezionato :attribute non è valido."])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve esserre un file."])},
    "filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute deve avere un valore."])},
    "gt": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere maggiore di :value."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere maggiore di :value kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere maggiore di :value caratteri."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve avere più di :value articoli."])}
    },
    "gte": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere maggiore o uguale a :value."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere maggiore o uguale a :value kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere maggiore o uguale a :value caratteri."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve avere :value articoli o più."])}
    },
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un'immagine."])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il selezionato :attribute non è valido."])},
    "in_array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribite non esiste in :other."])},
    "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un numero intero."])},
    "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un valido indirizzo IP."])},
    "ipv4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un valido indirizzo IPv4."])},
    "ipv6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un valido indirizzo IPv6."])},
    "json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere una valida stringa JSON."])},
    "lt": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere inferiore a :value."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere inferiore a :value kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere inferiore a :value caratteri."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve avere meno di :value articoli."])}
    },
    "lte": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere inferiore o uguale a :value."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere inferiore o uguale a :value kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere inferiore o uguale a :value caratteri."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non deve avere più di :value articoli."])}
    },
    "max": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non deve essere maggiore di :max."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non deve essere maggiore di :max kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non deve essere maggiore di :max caratteri."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non deve avere più di :max articoli."])}
    },
    "mimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un file di tipo :values."])},
    "mimetypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un file di tipo :values."])},
    "min": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere almeno di :min."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere almeno di :min kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere almeno di :min caratteri."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve avere almeno di :min articoli."])}
    },
    "multiple_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un multiplo di :value."])},
    "not_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il selezionato :attribute non è valido."])},
    "not_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il formato :attribute non è valido."])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un numero."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password non è corretta."])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute deve essere presente."])},
    "regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il formato :attribute non è valido."])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è necessario."])},
    "required_if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è necessario quando :other è :value."])},
    "required_unless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è obbligatorio a meno che :other sia in :values."])},
    "required_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è necessario quando :values è presente."])},
    "required_with_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è necessario quando :values sono presenti."])},
    "required_without": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è necessario quando :values non è presente."])},
    "required_without_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è necessario quando nessuno dei :values sono presenti."])},
    "prohibited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è vietato."])},
    "prohibited_if": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è vietato quando :other è :value."])},
    "prohibited_unless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il campo :attribute è vietato a meno che :other sia in :values."])},
    "same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute e :other devono corrispondere."])},
    "size": {
      "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere di :size."])},
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere di :size kilobytes."])},
      "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere di :size caratteri."])},
      "array": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve contenere di :size articoli."])}
    },
    "starts_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve iniziare con uno dei seguenti: :values."])},
    "string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere una corda."])},
    "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un valido fuso orario."])},
    "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute è già stato utilizzato."])},
    "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute non è riuscito a caricare."])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un valido URL."])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([":attribute deve essere un valido UUID."])},
    "codice_fiscale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice fiscale fornito sembra non essere valido. Potresti controllare?"])},
    "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di partita IVA sembra non essere corretto o l'IVA non è attiva. Potresti controllare?"])},
    "custom": {
      "attribute-name": {
        "rule-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom-message"])}
      }
    },
    "attributes": [
      
    ]
  },
  "stripe": {
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati di pagamento"])},
    "holder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare della carta"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva Metodo di Pagamento"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Nuova Carta"])},
    "enterPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il Metodo di Pagamento"])},
    "authRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticazione richiesta per il Metodo di Pagamento"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaduto:"])},
    "mine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei Metodi di Pagamento"])},
    "error_element": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore: compilare correttamente tutti i campi"])},
    "error_after_confirmSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore dopo confirmSetup"])},
    "error_trying_confirmSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore tentando confirmSetup"])}
  },
  "customerDashboard": {
    "payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodi di pagamento registrati"])},
    "no_payment_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono metodi di pagamento registrati"])},
    "add_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo metodo di pagamento"])},
    "buy_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquistare l'arpa"])},
    "attention_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione! In questo modo avvierai il procedimento di acquisto. Per ricevere un preventivo invia un'email a "])},
    "confirm_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma di voler acquistare l'arpa che sta attualmente noleggiando? Successivamente alla sua conferma il nostro team si metterà in contatto con lei per procedere con l'acquisto"])},
    "return_harp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrompere il noleggio"])},
    "confirm_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma di voler interrompere il noleggio? Successivamente alla sua conferma il nostro team darà inizio alla procedura di rientro dell'arpa"])},
    "your_rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I suoi noleggi"])},
    "your_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi pagamenti"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buongiorno"])},
    "your_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questa è la sua area riservata, una pagina che le permette di monitorare i propri noleggi con facilità.\n Tramite questa interfaccia ha la possibilità di aggiungere nuovi metodi di pagamento, richiedere il termine del noleggio e il ritiro presso il suo indirizzo, oppure, procedere all’acquisto dell’arpa."])},
    "save_new_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva Metodo di Pagamento"])},
    "wantToBuyOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ringraziamo per aver espresso la volontà di acquistare l'arpa. Verrà presto contattato dal nostro personale"])},
    "wantToBuyKO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si sono verificati dei problemi durante l'operazione. Si prega di riprovare più tardi"])},
    "stopRentalOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci spiace che abbia scelto di interrompere il noleggio dell'arpa. Verrà presto contattato dal nostro personale che le darà informazioni sulla procedura di restituzione dell'arpa"])},
    "stopRentalKO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si sono verificati dei problemi durante l'operazione. Si prega di riprovare più tardi"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratto:"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio noleggio:"])},
    "errorOnPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore sul server.Si prega di riprovare più tardi"])},
    "nextPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data prossimo pagamento"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagato"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da Pagare"])},
    "stripeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe Id"])},
    "invoiceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data pagamento"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "paymentState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato del Pagamento"])}
  },
  "api-errors": {
    "not-authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei autorizzato"])}
  }
}