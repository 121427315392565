<template>
  <div class="flex flex-col">
    <div class="flex items-center w-full justify-between">
      <slot name="title"/>
    </div>
    <div class="ml-4 flex flex-col">
      <slot name="content"/>
    </div>
  </div>
</template>
